import React from 'react';
import { Space } from 'style/spacing';
import { CardTitle, CardWrapper, CardWrapperSpaced } from "./Card.styled";


interface CardProps extends Space {
  children: JSX.Element | null | string | JSX.Element[] | React.ReactNode;
  cardTitle?: string | string[];
  style?: React.CSSProperties;
  className?: string;
  isImageCard?: boolean
}

export const Card: React.FC<CardProps> = ({ children, cardTitle, style, isImageCard = false, ...rest }) => {
  let titleToDisplay = <>{cardTitle}</>;

  if (Array.isArray(cardTitle)) {
    titleToDisplay = <MultipleLineTitle cardTitle={cardTitle} />;
  }

  const Wrapper = isImageCard ? CardWrapper : CardWrapperSpaced;

  return (
    <Wrapper style={style} {...rest}>
      {cardTitle !== undefined && <CardTitle>{titleToDisplay}</CardTitle>}
      {children}
    </Wrapper>
  );
};

interface MultipleLineTitleProps {
  cardTitle: string[];
}

const MultipleLineTitle: React.FC<MultipleLineTitleProps> = ({ cardTitle }) => {
  return (
    <span style={{ lineHeight: 1.5, whiteSpace: 'nowrap' }}>
      {cardTitle.map((phrase) => (
        <React.Fragment key={phrase}>
          {phrase} <br />
        </React.Fragment>
      ))}
    </span>
  );
};
