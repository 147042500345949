import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ResetText = styled.p`
  color: ${theme.colors.darkenWhite};
  margin: 0 0 20px 0;
  font-size: 1.4rem;
  line-height: 1.4;
  max-width: 300px;
`;

export const ButtonContainer = styled.span`
  margin-top: 20px;
`;