import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RippleContainer } from "./Ripple.styled";


const useDebouncedRippleCleanUp = (
  rippleCount: number,
  duration: number,
  cleanUpFunction: () => void
) => {
  useLayoutEffect(() => {
    let bounce = 0;
    if (rippleCount > 0) {
      clearTimeout(bounce);

      bounce = window.setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, duration * 4);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, duration, cleanUpFunction]);
};

interface RippleProps {
  duration?: number;
  color?: string;
  opacity?: number;
}

export const Ripple: React.FC<RippleProps> = ({
  duration = 1200,
  color = '#303030',
  opacity = 20,
}) => {
  const [rippleArray, setRippleArray] = useState<
    {
      x: number;
      y: number;
      size: number;
    }[]
  >([]);

  useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
    setRippleArray([]);
  });

  const node = useRef<HTMLDivElement | null>(null);
  const handleClick = (e: MouseEvent) => {
    if (!node?.current?.contains(e?.target as Node)) {
      setRippleArray([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addRipple = ({
    pageX,
    pageY,
    currentTarget,
  }: React.MouseEvent<HTMLDivElement>) => {
    const {
      height: rippleHeight,
      width: rippleWidth,
      x: rippleX,
      y: rippleY,
    } = currentTarget.getBoundingClientRect();

    const size = rippleWidth > rippleHeight ? rippleWidth : rippleHeight;
    const x = pageX - rippleX - rippleWidth / 2;
    const y = pageY - rippleY - rippleWidth / 2;
    const newRipple = { x, y, size };

    setRippleArray((prevState) => [...prevState, newRipple]);
  };

  return (
    <RippleContainer
      ref={node}
      duration={duration}
      color={color}
      onMouseDown={addRipple}
      opacity={opacity}
    >
      {rippleArray.length > 0 &&
        rippleArray.map(({ y, x, size }, index) => {
          return (
            <span
              key={'ripple_' + index}
              style={{
                top: y,
                left: x,
                width: size,
                height: size,
              }}
            />
          );
        })}
    </RippleContainer>
  );
};
