import styled from "styled-components";

export const LevelIndicatorContainer = styled.div`
  max-width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (min-width: 576px) {
    max-width: 30vw;
  }

  @media screen and (min-width: 768px) {
    max-width: 25vw;
  }

  svg {
    g {
      font-size: 1.2rem;
    }
  }

  text {
    cursor: pointer;
  }
`;
