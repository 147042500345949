import { useQuery } from 'react-query';
// import { PUBLIC_API } from 'services/config/api';
import { publicHTTPClient } from 'services/config/publicHTTPClient';
import { ERROR_MESSAGE } from "utils/constants";
// import { showErrorToast, showInfoToast } from 'utils/toast';
// api/account/password/<str:token>/</str:token>
export const useCheckTokenQuery = (token: string) => {
  const { isSuccess, data = { email: '' } } = useQuery(
    [],
    () =>
      publicHTTPClient
        .get<{ email: string }>(`account/password/${token}/`)
        .then((resp) => resp.data),
    {
      onError: () => {
        console.error(ERROR_MESSAGE);
      },
      enabled: !!token,
    }
  );

  return {
    email: data.email,
    tokenIsValid: isSuccess,
  };
};
