import React from "react";
import {
  DashboardIcon,
  PortfolioIcon,
  SocialIcon,
  TakeActionIcon,
  CuratedFindingsIcon,
  LegalIcon
} from 'assets/icons/NavIcons';
import {match} from 'react-router-dom';
import * as H from 'history';
import {getSocialClientsIds} from "utils/utils";
import {UserRoleSlug} from "types";

export interface INavItem {
  path: string;
  label: string;
  icon: React.ReactNode;
  withDivider?: boolean;
  isActive?: (match: match | null, location: H.Location) => boolean;
}

const APP_NAVIGATION_CONFIG: INavItem[] = [
  {
    path: '/dashboard',
    label: 'dashboard',
    icon: <DashboardIcon />,
  },
  {
    path: '/curated-findings',
    label: 'curated findings',
    icon: <CuratedFindingsIcon />,
    isActive: (_, location) => {
      return location.pathname.includes('/curated-findings');
    },
  },
  {
    path: '/under-review',
    label: 'Under review',
    icon: <TakeActionIcon />,
    isActive: (_, location) => {
      return location.pathname.includes('/under-review');
    },
  },
  {
    path: '/cases',
    label: 'cases',
    icon: <LegalIcon />,
    isActive: (_, location) => {
      return location.pathname.includes('/cases');
    },
  },
  {
    path: '/documents',
    label: 'Documents',
    icon: <TakeActionIcon />,
    isActive: (_, location) => {
      return location.pathname.includes('/documents');
    },
  },
  {
    path: '/portfolio',
    label: 'portfolio',
    icon: <PortfolioIcon />,
  },
  // {
  //   path: '/ledger',
  //   label: 'ledger',
  //   icon: <LedgerIcon />,
  // },
];

const MONITORING_APP_NAVIGATION_CONFIG: INavItem[] = [
  {
    path: '/dashboard',
    label: 'dashboard',
    icon: <DashboardIcon/>,
  },
  {
    path: '/curated-findings',
    label: 'curated findings',
    icon: <CuratedFindingsIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/curated-findings');
    },
  },
  {
    path: '/pre-cases',
    label: 'pre cases',
    icon: <CuratedFindingsIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/pre-cases');
    },
  },
  {
    path: '/under-review',
    label: 'Under review',
    icon: <TakeActionIcon />,
    isActive: (_, location) => {
      return location.pathname.includes('/under-review');
    },
  },
  {
    path: '/cases',
    label: 'cases',
    icon: <LegalIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/cases');
    },
  },
  {
    path: '/documents',
    label: 'Documents',
    icon: <TakeActionIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/documents');
    },
  },
  {
    path: '/portfolio',
    label: 'portfolio',
    icon: <PortfolioIcon/>,
  },
];

const COPYRIGHT_APP_NAVIGATION_CONFIG: INavItem[] = [
  {
    path: '/dashboard',
    label: 'dashboard',
    icon: <DashboardIcon/>,
  },
  {
    path: '/curated-findings',
    label: 'curated findings',
    icon: <CuratedFindingsIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/curated-findings');
    },
  },
  {
    path: '/under-review',
    label: 'Under review',
    icon: <TakeActionIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/under-review');
    },
  },
  {
    path: '/cases',
    label: 'cases',
    icon: <LegalIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/cases');
    },
  },
  {
    path: '/documents',
    label: 'Documents',
    icon: <TakeActionIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/documents');
    },
  },
  {
    path: '/portfolio',
    label: 'portfolio',
    icon: <PortfolioIcon/>,
  },
];

const MONITORING_COPYRIGHT_APP_NAVIGATION_CONFIG: INavItem[] = [
  {
    path: '/dashboard',
    label: 'dashboard',
    icon: <DashboardIcon/>,
  },
  {
    path: '/curated-findings',
    label: 'curated findings',
    icon: <CuratedFindingsIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/curated-findings');
    },
  },
  {
    path: '/pre-cases',
    label: 'pre cases',
    icon: <CuratedFindingsIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/pre-cases');
    },
  },
  {
    path: '/under-review',
    label: 'Under review',
    icon: <TakeActionIcon />,
    isActive: (_, location) => {
      return location.pathname.includes('/under-review');
    },
  },
  {
    path: '/cases',
    label: 'cases',
    icon: <LegalIcon/>,
    isActive: (_, location) => {
      return location.pathname.includes('/cases');
    },
  },
  {
    path: '/documents',
    label: 'Documents',
    icon: <TakeActionIcon />,
    isActive: (_, location) => {
      return location.pathname.includes('/documents');
    },
  },
  {
    path: '/portfolio',
    label: 'portfolio',
    icon: <PortfolioIcon />,
  },
];


const socialListeningNavigation: INavItem = {
  path: '/social-listening',
  label: 'social listening',
  icon: <SocialIcon />,
}

const getNavigationConfigByRole = (role: null | string | undefined, is_monitoring: boolean) => {
  if (is_monitoring) {
    switch (role) {
      case UserRoleSlug.COPYRIGHT:
        return [...MONITORING_COPYRIGHT_APP_NAVIGATION_CONFIG];
      case UserRoleSlug.PERSONALITY:
        return [...MONITORING_APP_NAVIGATION_CONFIG];
      default:
        return [...MONITORING_APP_NAVIGATION_CONFIG];
    }
  }

  switch (role) {
    case UserRoleSlug.COPYRIGHT:
      return [...COPYRIGHT_APP_NAVIGATION_CONFIG];
    case UserRoleSlug.PERSONALITY:
      return [...APP_NAVIGATION_CONFIG];
    default:
      return [...APP_NAVIGATION_CONFIG];
  }
}

export const getNavigationConfig = (currentCelebrityId: number, userRole: string | undefined | null, is_monitoring: boolean) => {
  const showSocialListening = getSocialClientsIds.indexOf(currentCelebrityId) > -1;
  const NAVIGATION_CONFIG: INavItem[] = getNavigationConfigByRole(userRole, is_monitoring)
  // adding social listening to second index
  if (showSocialListening) NAVIGATION_CONFIG.splice(1, 0, socialListeningNavigation)
  return NAVIGATION_CONFIG
}
