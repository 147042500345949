import { AxiosError } from 'axios';
import { showErrorToast } from './toast/toast';
import { ERROR_MESSAGE } from "./constants";
// import * as Sentry from '@sentry/react';

export const handleError = (error: AxiosError, callback?: () => void): void => {
  // default unexpected message
  let errorMsg = ERROR_MESSAGE;

  // 401 code related to refreshing token no need to display toast
  if (error.response?.status === 401) {
    return;
  }

  if (error?.response) {
    // wrong server response
    errorMsg = ERROR_MESSAGE;
    console.log(error?.response);
  } else if (error.request) {
    // no server response
    errorMsg = ERROR_MESSAGE;
    console.log(error?.request);
  } else {
    console.log(error?.message);
  }

  /**
   * for handling 4** and 5** you can specify conditions based on error object and run
   * this code: Sentry.captureException(error);
   */

  console.error({ error });
  showErrorToast(errorMsg);

  if (callback) {
    callback();
  }
};
