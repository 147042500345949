import React, {createContext, useContext, useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';

import {Celebrity, Role} from 'types';
import {publicHTTPClient} from 'services/config/publicHTTPClient';
import {PUBLIC_API} from 'services/config/api';
import {LOCAL_STORAGE_CELEBRITY_KEY} from "./CelebrityContext";

const LOCAL_STORAGE_ACCESS_KEY = 'access';
const LOCAL_STORAGE_REFRESH_KEY = 'refresh';
const LOCAL_STORAGE_USER_KEY = 'user';

interface AuthState {
  access: string | null;
  refresh: string | null;
}

export interface User {
  email: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  job_title: string | null;
  company_name: string | null;
  score: number | null;
  clients: Celebrity[];
  role: null | Role;
  is_monitoring: boolean;
}

interface IAuthContext {
  authState: AuthState;
  user: User;
  setUserInfo: (user: User) => void;
  isAuthenticated: () => boolean;
  logout: () => void;
  setAuthInfo: (authState: AuthState) => void;
  getNewToken: () => Promise<void | string>;
  isAccessTokenExpired: () => boolean;
}

const INITIAL_AUTH_STATE: AuthState = { access: null, refresh: null };

const INITIAL_USER_STATE: User = {
  company_name: null,
  email: null,
  first_name: null,
  job_title: null,
  last_name: null,
  middle_name: null,
  score: null,
  clients: [],
  role: null,
  is_monitoring: false
};

const INITIAL_CTX_STATE: IAuthContext = {
  authState: INITIAL_AUTH_STATE,
  user: INITIAL_USER_STATE,
  getNewToken: () => new Promise((res) => res()),
  isAuthenticated: () => false,
  logout: () => ({}),
  setAuthInfo: () => ({}),
  setUserInfo: () => ({}),
  isAccessTokenExpired: () => false,
};

const AuthContext = createContext<IAuthContext>(INITIAL_CTX_STATE);

const useAuth = () => {
  return useContext(AuthContext);
};

const getAuthState = (): AuthState => {
  const access = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
  const refresh = localStorage.getItem(LOCAL_STORAGE_REFRESH_KEY);

  return { access, refresh };
};

const getUser = (): User => {
  const user = localStorage.getItem(LOCAL_STORAGE_USER_KEY);

  if (!user) {
    return INITIAL_USER_STATE;
  }

  try {
    return JSON.parse(user) as User;
  } catch (error) {
    return INITIAL_USER_STATE;
  }
};

interface AuthProviderProps {
  children: JSX.Element;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState(() => getAuthState());
  const [user, setUser] = useState(() => getUser());

  useEffect(() => {
    window.addEventListener('storage', handleStorageListener);

    return () => window.removeEventListener('storage', handleStorageListener);
  }, [authState.refresh]);

  const handleStorageListener = ({ key, oldValue, newValue }: StorageEvent) => {
    if (key === LOCAL_STORAGE_REFRESH_KEY && oldValue !== null && newValue === null) {
      setAuthState(INITIAL_AUTH_STATE);
      setUser(INITIAL_USER_STATE);
    }
  };

  const setAuthInfo = ({ access, refresh }: AuthState) => {
    if (access && refresh) {
      localStorage.setItem(LOCAL_STORAGE_ACCESS_KEY, access);
      localStorage.setItem(LOCAL_STORAGE_REFRESH_KEY, refresh);
    }

    setAuthState({ access, refresh });
  };

  const setUserInfo = (user: User) => {
    setUser(user);
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
  };

  const getNewToken = async () => {
    try {
      const { data } = await publicHTTPClient.post<{ access: string }>(
        PUBLIC_API.AUTH_REFRESH_TOKEN,
        {
          refresh: authState.refresh,
        }
      );

      localStorage.setItem(LOCAL_STORAGE_ACCESS_KEY, data.access);
      setAuthState((prev) => ({ ...prev, access: data.access }));

      return data.access;
    } catch (error) {
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_KEY);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_KEY);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    localStorage.removeItem(LOCAL_STORAGE_CELEBRITY_KEY);

    setAuthState(INITIAL_AUTH_STATE);
    setUser(INITIAL_USER_STATE);
  };

  const isAuthenticated = () => {
    return Boolean(authState.access) && Boolean(authState.refresh);
  };

  const isAccessTokenExpired = () => {
    if (authState.access === null) {
      return false;
    }

    const decoded = jwt_decode<{ exp: number }>(authState.access as string);

    return decoded.exp * 1000 <= new Date().getTime();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        authState,
        getNewToken,
        isAuthenticated,
        logout,
        setAuthInfo,
        setUserInfo,
        isAccessTokenExpired
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext, useAuth, getUser };
