import { useMutation } from 'react-query';
import { PUBLIC_API } from 'services/config/api';
import { publicHTTPClient } from 'services/config/publicHTTPClient';
import { showErrorToast } from 'utils/toast/toast';
import { ERROR_MESSAGE } from "utils/constants";

interface LoginResponse {
  refresh: string;
  access: string;
}

export const useSignInMutation = () => {
  const signInRequest = async (credentials: { email: string; password: string }) => {
    const { data } = await publicHTTPClient.post<LoginResponse>(
      PUBLIC_API.AUTH_CREATE_TOKEN,
      credentials
    );

    return data;
  };

  const { mutateAsync: signIn, isLoading } = useMutation(signInRequest, {
    onError: () => showErrorToast(ERROR_MESSAGE),
  });

  return { signIn, isLoading };
};
