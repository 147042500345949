import { useMutation } from 'react-query';
import { PUBLIC_API } from 'services/config/api';
import { publicHTTPClient } from 'services/config/publicHTTPClient';
import { showErrorToast, showInfoToast } from 'utils/toast/toast';
import { ERROR_MESSAGE } from "utils/constants";

export const useForgotPasswordMutation = () => {
  const { mutateAsync: resetPassword } = useMutation(
    (email: string) =>
      publicHTTPClient
        .post(PUBLIC_API.FORGOT_PASSWORD, { email })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        showInfoToast('You have reset password successfully, check your email inbox!');
      },
      onError: () => {
        showErrorToast(ERROR_MESSAGE);
      },
    }
  );

  return {
    resetPassword,
  };
};
