import React, {createContext, useContext, useState} from 'react';
import {useFetchCtx} from 'contexts/FetchContext';
import {ERROR_MESSAGE} from "utils/constants";

interface ISocialListeningContext {
    setIsMentionsLoading: (val: boolean) => void;
    setMentionsData: (val: any) => void;
    mentionsData: any;
    isMentionsLoading: boolean;
    fetchMentionsData: any;

    setIsSocialCaseReviewedLoading: (val: boolean) => void;
    setSocialCaseReviewed: (val: any) => void;
    socialCaseReviewed: any;
    isSocialCaseReviewedLoading: boolean;
    fetchSocialCaseReviewed: any;

    setIsSocialCaseUnReviewedLoading: (val: boolean) => void;
    setSocialCaseUnReviewed: (val: any) => void;
    socialCaseUnReviewed: any;
    isSocialCaseUnReviewedLoading: boolean;
    fetchSocialCaseUnReviewed: any;

    setIsSocialCaseClosedLoading: (val: boolean) => void;
    setSocialCaseClosed: (val: any) => void;
    socialCaseClosed: any;
    isSocialCaseClosedLoading: boolean;
    fetchSocialCaseClosed: any;

    setIsSocialCaseOpenLoading: (val: boolean) => void;
    setSocialCaseOpen: (val: any) => void;
    socialCaseOpen: any;
    isSocialCaseOpenLoading: boolean;
    fetchSocialCaseOpen: any;
}

const INITIAL_CTX_STATE: ISocialListeningContext = {
    setIsMentionsLoading: () => ({}),
    setMentionsData: () => ({}),
    mentionsData: {},
    isMentionsLoading: false,
    fetchMentionsData: () => Promise.resolve([]),

    setIsSocialCaseReviewedLoading: () => ({}),
    setSocialCaseReviewed: () => ({}),
    socialCaseReviewed: {},
    isSocialCaseReviewedLoading: false,
    fetchSocialCaseReviewed: () => Promise.resolve([]),

    setIsSocialCaseUnReviewedLoading: () => ({}),
    setSocialCaseUnReviewed: () => ({}),
    socialCaseUnReviewed: {},
    isSocialCaseUnReviewedLoading: false,
    fetchSocialCaseUnReviewed: () => Promise.resolve([]),

    setIsSocialCaseClosedLoading: () => ({}),
    setSocialCaseClosed: () => ({}),
    socialCaseClosed: {},
    isSocialCaseClosedLoading: false,
    fetchSocialCaseClosed: () => Promise.resolve([]),

    setIsSocialCaseOpenLoading: () => ({}),
    setSocialCaseOpen: () => ({}),
    socialCaseOpen: {},
    isSocialCaseOpenLoading: false,
    fetchSocialCaseOpen: () => Promise.resolve([]),
};

const SocialListeningContext = createContext<ISocialListeningContext>(INITIAL_CTX_STATE);

export const useSocialListeningCtx = () => {
    const ctx = useContext(SocialListeningContext);

    if (!ctx) {
        throw new Error(ERROR_MESSAGE);
    }

    return ctx;
};

export const SocialListeningContextProvider: React.FC = ({children}) => {
    const {get} = useFetchCtx();

    const [isMentionsLoading, setIsMentionsLoading] = useState(false);
    const [mentionsData, setMentionsData] = useState({});
    const [isSocialCaseReviewedLoading, setIsSocialCaseReviewedLoading] = useState(false);
    const [socialCaseReviewed, setSocialCaseReviewed] = useState({});
    const [isSocialCaseUnReviewedLoading, setIsSocialCaseUnReviewedLoading] = useState(false);
    const [socialCaseUnReviewed, setSocialCaseUnReviewed] = useState({});
    const [isSocialCaseClosedLoading, setIsSocialCaseClosedLoading] = useState(false);
    const [socialCaseClosed, setSocialCaseClosed] = useState({});
    const [isSocialCaseOpenLoading, setIsSocialCaseOpenLoading] = useState(false);
    const [socialCaseOpen, setSocialCaseOpen] = useState({});


    const fetchMentionsData = async () => {
        setIsMentionsLoading(true)
        const {data: {data: {Items: mentions}}} = await get(
            'social_listening/',
        );
        setMentionsData(mentions)
        setIsMentionsLoading(false)
        return mentions;
    };

    const fetchSocialCaseReviewed = async () => {
        setIsSocialCaseReviewedLoading(true)
        const {data} = await get(
            'social_listening/case/?reviewed=1',
        );
        setSocialCaseReviewed(data)
        setIsSocialCaseReviewedLoading(false)
        return data;
    };

    const fetchSocialCaseUnReviewed = async () => {
        setIsSocialCaseUnReviewedLoading(true)
        const {data} = await get(
            'social_listening/case/?reviewed=0',
        );
        setSocialCaseUnReviewed(data)
        setIsSocialCaseUnReviewedLoading(false)
        return data;
    };

    const fetchSocialCaseClosed = async () => {
        setIsSocialCaseClosedLoading(true)
        const {data} = await get(
            'social_listening/case/case/?closed=1',
        );
        setSocialCaseClosed(data)
        setIsSocialCaseClosedLoading(false)
        return data;
    };

    const fetchSocialCaseOpen = async () => {
        setIsSocialCaseOpenLoading(true)
        const {data} = await get(
            'social_listening/case/case/?closed=0',
        );
        setSocialCaseOpen(data)
        setIsSocialCaseOpenLoading(false)
        return data;
    };

    const value = {
        setIsMentionsLoading,
        setMentionsData,
        mentionsData,
        isMentionsLoading,
        fetchMentionsData,

        setIsSocialCaseReviewedLoading,
        setSocialCaseReviewed,
        socialCaseReviewed,
        isSocialCaseReviewedLoading,
        fetchSocialCaseReviewed,

        setIsSocialCaseUnReviewedLoading,
        setSocialCaseUnReviewed,
        socialCaseUnReviewed,
        isSocialCaseUnReviewedLoading,
        fetchSocialCaseUnReviewed,

        setIsSocialCaseClosedLoading,
        setSocialCaseClosed,
        socialCaseClosed,
        isSocialCaseClosedLoading,
        fetchSocialCaseClosed,

        setIsSocialCaseOpenLoading,
        setSocialCaseOpen,
        socialCaseOpen,
        isSocialCaseOpenLoading,
        fetchSocialCaseOpen,
    };

    return (<SocialListeningContext.Provider value={value}>{children}</SocialListeningContext.Provider>
    );
};
