import styled from 'styled-components';
import {
  Container as StyledContainer,
  Row as StyledRow,
  Col as StyledCol,
} from 'styled-bootstrap-grid';
import { Space, space } from 'style/spacing';

const Container = styled(StyledContainer)<Space>`
  ${space};
`;

Container.displayName = 'Container';

const Row = styled(StyledRow)<Space>`
  ${space};
`;

Row.displayName = 'Row';

const Col = styled(StyledCol)<Space>`
  ${space};
`;

Col.displayName = 'Col';

export { Container, Row, Col };
