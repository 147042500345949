// import { useMutation } from 'react-query';
// import { PUBLIC_API } from 'services/config/api';
// import { publicFetch } from 'utils/fetch';
// import { useState } from 'react';
import { useMutation } from 'react-query';
import { publicHTTPClient } from 'services/config/publicHTTPClient';
import { showInfoToast } from 'utils/toast/toast';

export const useSendAgainMutation = () => {
  // const [reachedLimit, setReachedLimit] = useState(false);

  const { mutateAsync: sendAgain } = useMutation(
    (email: string) => publicHTTPClient.patch('account/activate/resend/', { email }),
    {
      onError: () => {
        console.error('We could not send another email');
      },
      onSuccess: () => {
        showInfoToast('We have sent you another link');
      },
    }
  );

  return {
    sendAgain,
  };
};
