import styled from "styled-components";

export const ButtonGroupContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    margin-left: 20px;
  }

  * + * {
    margin-top: 15px;

    @media screen and (min-width: 1200px) {
      margin-top: 0;
      margin-left: 5px;
    }

    @media screen and (min-width: 1300px) {
      margin-left: 15px;
    }
  }
`;
