import React from 'react';
import { Space } from 'style/spacing';
import {StyledLoader} from "./Loader.styled";

interface LoaderProps extends Space {
  style?: React.CSSProperties;
  height?: string;
  width?: string;
  visible?: boolean;
  color?: '#fff' | '#333';
}

export const Loader: React.FC<LoaderProps> = ({
  visible = true,
  style,
  color = '#fff',
  height,
  width,
  ...rest
}) => {
  return (
    <StyledLoader
      width={width}
      height={height}
      style={style}
      visible={visible}
      type="Grid"
      color={color}
      {...rest}
    />
  );
};
