import React, { createContext, useContext, useState } from "react";
import { MONITORING_VIEWS } from "utils/constants";

interface ContextProps {
  currentView: MONITORING_VIEWS;
  toggleView: (view: MONITORING_VIEWS) => void;
}

const INITIAL_CTX_STATE: ContextProps = {
  currentView: MONITORING_VIEWS.CURATED_FINDINGS,
  toggleView: () => ({}),
};
const DashboardContext = createContext<ContextProps | null>(INITIAL_CTX_STATE);

const useDashboardCtx = () => {
  const ctx = useContext(DashboardContext);
  if (!ctx) {
    throw new Error("");
  }
  return ctx;
};

const DashboardContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [currentView, setCurrentView] = useState<MONITORING_VIEWS>(
    MONITORING_VIEWS.CURATED_FINDINGS,
  );

  const value = {
    currentView,
    toggleView: (view: MONITORING_VIEWS) => setCurrentView(view),
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export { useDashboardCtx, DashboardContextProvider };
