import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const StyledTag = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: bolder;
  padding: 7px;
  border-radius: 6px;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-top: 5px;
  max-width: 200px;
  margin-right: 5px;

  & span.tag {
    max-width: 170px;
    display: inline-block;

    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const UnselectBtn = styled.button<{ color: string }>`
  margin-left: 3px;
  cursor: pointer;
  border: none;
  background: transparent;
  color: ${({ color }) => color};
  font-size: 2.2rem;
`;

export const EditBtn = styled.button`
  margin-left: 3px;
  cursor: pointer;
  border: none;
  background: transparent;
  color: ${theme.colors.lightgrey};
  font-size: 2.2rem;
`;
