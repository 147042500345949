import styled, {css} from "styled-components";
import {space, Space} from "style/spacing";
import {theme} from "assets/styles/theme";

export const TextFieldContainer = styled.div<Space & { width?: string }>`
  /* margin-top: 30px; */
  position: relative;
  width: 100%;
  max-width: ${({width}) => width || 'unset'};

  ${space};
`;

export const ErrorMsg = styled.p`
  color: ${theme.colors.error};
  font-size: 1.2rem;
  margin-top: 5px;
  margin-left: 6px;
`;

export const LabelTxt = styled.p`
  color: ${theme.colors.mediumGrey};
  font-weight: 700;
  margin-bottom: 5px;
  margin-left: 3px;
  font-size: 1.1rem;
  text-transform: uppercase;
`;

export const Input = styled.input<{ error: boolean; hasIcon: boolean; block: boolean; }>`
  box-sizing: border-box;
  height: 50px;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: ${theme.colors.backgroundBlack};
  border: 1px solid ${theme.colors.backgroundBlack};
  outline: none;
  color: ${theme.colors.darkenWhite};
  transition: all 0.15s;
  /* min-width: 330px; */
  width: ${({ block }) => (block ? '100%' : 'unset')};
  padding-left: ${({ hasIcon }) => (hasIcon ? '40px' : '16px')};

  &::placeholder {
    color: ${theme.colors.mediumGrey};
  }

  &:hover:enabled {
    border-color: ${theme.colors.mediumGrey};
  }

  &&:focus {
    border-color: ${theme.colors.darkenWhite};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${theme.colors.error}!important;
    `}

  &:disabled {
    background-color: ${theme.colors.grey};
  }

  &&:read-only {
    border-color: transparent;
  }

  &:disabled::placeholder {
    color: #2b2b2b;
  }
`;

export const TextAreaInput = styled.textarea<{ error: boolean; hasIcon: boolean; block: boolean; type?: 'password' | 'text' | 'textarea' }>`
  box-sizing: border-box;
  height: ${({ type }) => (type === 'textarea' ? '180px' : '50px')};
  padding: 12px 16px;
  border-radius: 12px;
  background-color: ${theme.colors.backgroundBlack};
  border: 1px solid ${theme.colors.backgroundBlack};
  outline: none;
  color: ${theme.colors.darkenWhite};
  transition: all 0.15s;
  /* min-width: 330px; */
  width: ${({ block }) => (block ? '100%' : 'unset')};
  padding-left: ${({ hasIcon }) => (hasIcon ? '40px' : '16px')};

  &::placeholder {
    color: ${theme.colors.mediumGrey};
  }

  &:hover:enabled {
    border-color: ${theme.colors.mediumGrey};
  }

  &&:focus {
    border-color: ${theme.colors.darkenWhite};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${theme.colors.error}!important;
    `}

  &:disabled {
    background-color: ${theme.colors.grey};
  }

  &&:read-only {
    border-color: transparent;
  }

  &:disabled::placeholder {
    color: #2b2b2b;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
`;

export const CopyContainer = styled.div`
  position: absolute;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  margin-top: 2px;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 54%;
  transform: translateY(-50%);
  left: 12px;
`;

export const ClearButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: ${theme.colors.darkenWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  
  &:hover {
    background-color: #D7D7D720;
    border-radius: 50%;
  }
`;
