import React from 'react';
import { Icon } from 'components';
import { Space } from 'style/spacing';
import { theme } from 'assets/styles/theme';
import { Container, LabelContainer, LevelTextIndicatorContainer } from "./LevelTextIndicator.styled";



interface LevelTextIndicatorProps extends Space {
  variant: 'commercial' | 'reputational';
  label?: string;
}

export const LevelTextIndicator: React.FC<LevelTextIndicatorProps> = ({
  variant,
  label = 'HIGH',
  ...rest
}) => {
  const colors = {
    reputational: {
      active: theme.colors.reputational,
      inActive: theme.colors.reputational + '99',
    },
    commercial: {
      active: theme.colors.commercial,
      inActive: theme.colors.commercial + '99',
    },
  };
  const color = colors[variant];

  return (
    <Container {...rest}>
      <LevelTextIndicatorContainer variant={color}>
        <Icon
          name="exclamation"
          color={color.active}
          style={{ transform: 'scale(0.75)' }}
        />
      </LevelTextIndicatorContainer>
      <LabelContainer style={{ color: color.active }}>
        {label}
      </LabelContainer>
    </Container>
  );
};
