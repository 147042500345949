import React, { useState } from 'react';

import { Button, TextField } from 'components';
import { Heading, StyledForm, WhiteBtn } from '../../Form/Form.styled';
import { useForgotPasswordMutation } from 'services/auth/useForgotPasswordMutation';
import { ButtonContainer, FormContainer, ResetText } from "./ForgotPassword.styled";
import { validateEmail } from "utils/utils";


export const ForgotPassword: React.FC<{ onGoBackClick: () => void }> = ({
  onGoBackClick,
}) => {
  const { resetPassword } = useForgotPasswordMutation();
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState<string>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      await resetPassword(email);
      setEmail('');
    } else {
      setEmailErr('It is not valid email');
    }
  };

  return (
    <div>
      <Heading>Password Reset</Heading>
      <ResetText>
        Please provide us your email address and we will send you a link where you can
        reset your password.
      </ResetText>
      <FormContainer>
        <StyledForm onSubmit={handleSubmit} autoComplete="off" noValidate>
          <TextField
            mt="30px"
            value={email}
            onChange={(e) => {
              if (emailErr) {
                setEmailErr(undefined);
              }
              setEmail(e.target.value);
            }}
            id="email"
            name="email"
            placeholder="Email"
            icon="user"
            error={emailErr}
          />
          <Button mt="20px" block variant="LEMON" type="submit">
            SUBMIT
          </Button>
        </StyledForm>
      </FormContainer>

      <ButtonContainer>
        <WhiteBtn onClick={onGoBackClick}>&lsaquo; Back</WhiteBtn>
      </ButtonContainer>
    </div>
  );
};
