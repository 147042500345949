import React, { useRef, useState, useEffect } from 'react';

import { theme } from 'assets/styles/theme';
import { Icon, IconName } from 'components/Icon/Icon';
import { ActionButton, ActionsContainer, Container, TriggerButton } from "./ActionDropdown.styled";


interface Action {
  label: string;
  disabled?: boolean;
  action: () => void;
  icon?: string;
}

interface ActionDropdownProps {
  actions: Array<Action>;
  hideOnOutsideClick?: boolean;
  iconName: IconName;
  actionsPosition?: Pick<React.CSSProperties, 'top' | 'left' | 'bottom' | 'right'>;
}

export const ActionDropdown: React.FC<ActionDropdownProps> = ({
  actions,
  actionsPosition,
  iconName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef<HTMLDivElement | null>(null);

  const toggle = () => setIsOpen((prev) => !prev);
  const handleClick = (e: MouseEvent) => {
    if (!node?.current?.contains(e?.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <Container ref={node}>
      <TriggerButton onClick={toggle}>
        <Icon
          name={iconName}
          color={theme.colors.darkenWhite}
          hoverColor={theme.colors.white}
        />
      </TriggerButton>
      <ActionsContainer isOpen={isOpen} style={{ ...actionsPosition }}>
        <ul style={{ listStyle: 'none' }}>
          {actions.map((action) => (
            <li key={action.label}>
              <ActionButton
                disabled={action.disabled}
                onClick={() => {
                  action.action();
                  toggle();
                }}
              >
                {action.icon && <Icon
                    name={action.icon}
                    width={24}
                    height={24}
                    style={{marginRight: 2}}
                    color={theme.colors.darkenWhite}
                    hoverColor={theme.colors.white}
                />}
                {action.label}
              </ActionButton>
            </li>
          ))}
        </ul>
      </ActionsContainer>
    </Container>
  );
};
