import React from 'react';
import { Space } from 'style/spacing';
import { Label, StyledSwitch, SwitchContainer } from "./SwitchButton.styled";


interface SwitchButtonProps extends Space {
  label?: string;
  name?: string;
  id?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

export const SwitchButton: React.FC<SwitchButtonProps> = ({
  label,
  name,
  id,
  value,
  checked,
  disabled = false,
  onChange,
  ...rest
}) => {
  return (
    <SwitchContainer {...rest}>
      <Label isChecked={checked} isDisabled={disabled} htmlFor={name}>
        {label}
      </Label>
      <StyledSwitch>
        <input
          disabled={disabled}
          type="checkbox"
          checked={checked}
          value={value}
          name={name}
          id={id}
          onChange={onChange}
        />
        <span className="slider" />
      </StyledSwitch>
    </SwitchContainer>
  );
};
