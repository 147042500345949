import { useQuery } from 'react-query';
import { useFetchCtx } from 'contexts/FetchContext';
import { HitType } from 'contexts/HitTypeContext';
import { Celebrity, MapResponse } from 'types';
import { handleError } from 'utils/errorHandler';
import { PRIVATE_API } from 'services/config/api';

export const useMapQuery = (
  currentCelebrity: Celebrity | null,
  currentHitType: HitType
) => {
  const { get } = useFetchCtx();

  const fetchMapData = async () => {
    const { data } = await get<MapResponse>(
      `${PRIVATE_API.MAP}?classification=${currentHitType}`
    );

    return data;
  };

  const { data: mapStateToDisplay, isLoading } = useQuery(
    ['mapState', currentCelebrity, currentHitType],
    fetchMapData,
    { onError: handleError }
  );

  return {
    mapStateToDisplay,
    isLoading,
  };
};
