import React from 'react';
import { Loader } from 'components';
import { Container } from "./LoadingPage.styled";


const LoadingPage: React.FC = () => {
  return (
    <Container>
      <div>
        <Loader
          style={{
            textAlign: 'center',
          }}
        />
      </div>
    </Container>
  );
};

export default LoadingPage;
