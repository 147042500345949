import {CSSProperties} from "react";
import {Palette} from "types";
import {theme} from "assets/styles/theme";
import styled from "styled-components";
import {space, Space} from "style/spacing";
import {ErrorMsg} from "../TextField/TextField.styled";

export type ButtonVariants = 'LEMON' | 'WHITE' | 'WHITE_OUTLINE' | 'MAGENTA' | 'DARK';

type ButtonStyles = Required<
    Pick<CSSProperties, 'color' | 'backgroundColor' | 'borderColor'> & {
    hoverBackgroundColor: CSSProperties['backgroundColor'];
    disabledBackgroundColor?: CSSProperties['backgroundColor'];
    hoverColor?: CSSProperties['color'];
    disabledColor?: CSSProperties['color'];
    hoverBorderColor?: CSSProperties['borderColor'];
    disabledBorderColor?: CSSProperties['borderColor'];
}
    >;

export const styles: Palette<ButtonVariants, ButtonStyles> = {
    LEMON: {
        backgroundColor: theme.colors.lemon,
        borderColor: 'transparent',
        color: theme.colors.black,

        hoverBackgroundColor: theme.colors.darkenLemon,
        hoverBorderColor: 'transparent',
        hoverColor: theme.colors.black,

        disabledBackgroundColor: theme.colors.lemon + '77',
        disabledBorderColor: 'transparent',
        disabledColor: theme.colors.black,
    },
    WHITE: {
        backgroundColor: theme.colors.white,
        borderColor: 'transparent',
        color: theme.colors.black,

        hoverBackgroundColor: theme.colors.darkenWhite,
        hoverBorderColor: 'transparent',
        hoverColor: theme.colors.black,

        disabledBackgroundColor: theme.colors.darkenWhite + '77',
        disabledBorderColor: 'transparent',
        disabledColor: theme.colors.black,
    },
    WHITE_OUTLINE: {
        backgroundColor: 'transparent',
        borderColor: theme.colors.darkenWhite,
        color: theme.colors.darkenWhite,

        hoverBackgroundColor: 'transparent',
        hoverBorderColor: theme.colors.white,
        hoverColor: theme.colors.white,

        disabledBackgroundColor: 'transparent',
        disabledBorderColor: theme.colors.darkenWhite + '55',
        disabledColor: theme.colors.darkenWhite + '55',
    },
    MAGENTA: {
        backgroundColor: theme.colors.magenta,
        borderColor: 'transparent',
        color: theme.colors.black,

        hoverBackgroundColor: theme.colors.darkenMagenta,
        hoverBorderColor: 'transparent',
        hoverColor: theme.colors.black,
        disabledBackgroundColor: theme.colors.darkenMagenta + '77',
        disabledBorderColor: 'transparent',
        disabledColor: theme.colors.black,
    },
    DARK: {
        backgroundColor: theme.colors.black,
        borderColor: 'transparent',
        color: theme.colors.darkenWhite,

        hoverBackgroundColor: theme.colors.black + '88',
        hoverBorderColor: 'transparent',
        hoverColor: theme.colors.white,
        disabledBackgroundColor: theme.colors.black + '55',
        disabledBorderColor: 'transparent',
        disabledColor: theme.colors.greySelected,
    },
};

const getColor = (key: keyof ButtonStyles) => ({
                                                   variant,
                                               }: {
    variant: ButtonVariants;
}) => {
    return styles[variant][key];
};

export const StyledCommonButton = styled.button`
  border-radius: 12px;
  text-transform: uppercase;
  border: none;
  position: relative;
  overflow: hidden;
  transition: all 0.15s;
  font-weight: 600;
  letter-spacing: 1px;
  white-space: nowrap;
  appearance: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  * + * {
    margin-left: 5px;
  }
`;

interface StyledCommonButtonProps extends Space {
    variant: ButtonVariants;
    isIconButton: boolean;
    block: boolean;
    size: 'sm' | 'md';
    isBorderHidden?: boolean;
}

export const StyledButton = styled(StyledCommonButton)<StyledCommonButtonProps>`
  padding: ${({ isIconButton, size, isBorderHidden }) => isBorderHidden? 0: (size === 'sm' ? '6px 12px' : isIconButton ? '10px' : '10px 47px')};
  height: ${({ size, isBorderHidden }) => ((size === 'sm' || isBorderHidden) ? 'fit-content' : '47px')};
  font-size: ${({ size }) => (size === 'sm' ? '1.1rem' : '1.3rem')};
  // small font-size to fit small to medium sized laptops in one line
  @media screen and (min-width: 1501px) and (max-width: 1749px) {
    font-size: ${({size}) => (size === 'sm' ? '0.9rem !important' : '1.3rem')};
  }
  @media screen and (min-width: 1750px) and (max-width: 2199px) {
    height: 47px;
  }
  width: ${({ block }) => (block ? '100%' : 'fit-content')};
  background-color: ${getColor('backgroundColor')};
  color: ${getColor('color')};
  border: ${({ variant, isBorderHidden }) =>
    styles[variant].borderColor === 'transparent' || isBorderHidden
        ? 'none'
        : `2px solid ${styles[variant].borderColor}`};

  &:enabled:hover {
    background-color: ${getColor('hoverBackgroundColor')};
    color: ${getColor('hoverColor')};
    border-color: ${getColor('hoverBorderColor')};
    svg {
      stroke: ${getColor('hoverColor')}!important;
      fill: ${getColor('hoverColor')}!important;
    }
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${getColor('disabledBackgroundColor')};
    color: ${getColor('disabledColor')};
    border-color: ${getColor('disabledBorderColor')};
    cursor: default;
  }

  && {
    ${space}
  }
`;

export const FileButton = styled.label`
  border-radius: 12px;
  text-transform: uppercase;
  border: none;
  position: relative;
  overflow: hidden;
  transition: all 0.15s;
  line-height: 20px;
  font-weight: 600;
  font-size: 1.4rem;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
  letter-spacing: 1px;
  white-space: nowrap;
  height: 47px;
  appearance: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 25px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${theme.colors.darkenWhite};
  &:hover {
    background-color: ${theme.colors.white};
  }
`;

export const FileErrorMsg = styled(ErrorMsg)`
  margin-left: 10px;
`;
