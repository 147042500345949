import { useQuery } from 'react-query';
import { useFetchCtx } from 'contexts/FetchContext';
import { HitType } from 'contexts/HitTypeContext';
import { handleError } from 'utils/errorHandler';
import { Celebrity, DashboardResponse } from 'types';
import { PRIVATE_API } from 'services/config/api';

export const useDashboardQuery = (
  currentCelebrity: Celebrity | null,
  currentHitType: HitType
) => {
  const { get } = useFetchCtx();

  const fetchDashboardData = async () => {
    const { data } = await get<DashboardResponse>(
      `/v2/${PRIVATE_API.DASHBOARD}${currentCelebrity?.id}?classification=${currentHitType}`
    );

    return data;
  };

  const { data: dashboardState, isLoading, refetch } = useQuery(
    ['dashboardState'],
    fetchDashboardData,
    { onError: handleError }
  );

  return { dashboardState, isLoading, refetch };
};
