import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const Container = styled.div`
  position: relative;
  margin-bottom: 10px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const ActionsContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 10;
  top: 50px;

  background-color: ${theme.colors.black};
  border-radius: 12px;
  padding: 10px;
  transition: all 0.15s;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  left: 50%;
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, 0)' : 'translate(-50%,10px)')};

  @media screen and (min-width: 1200px) {
    transform: ${({ isOpen }) => (isOpen ? 'translateY( 0)' : 'translateY(10px)')};
    right: 0;
    left: unset;
  }
`;

export const TriggerButton = styled.button`
  margin: 0 5px 0 10px;
  background: ${theme.colors.black};
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:hover svg {
    stroke: ${theme.colors.white}!important;
    fill: ${theme.colors.white}!important;
  }
`;

export const ActionButton = styled.button`
  border: none;
  background: transparent;
  height: 35px;
  padding: 7px 10px;
  margin: 3px 0;
  width: 100%;
  min-width: 120px;
  color: #ededed;
  text-align: left;
  white-space: nowrap;
  border-radius: 12px;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  transition: all 0.15s;

  &:disabled {
    color: ${theme.colors.mediumGrey};
  }

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: ${theme.colors.greySelected};
  }
`;