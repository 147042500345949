import { useState } from 'react';

export enum View {
  SIGN_IN,
  SING_UP,
  ALMOST_DONE,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
}

export const useCurrentView = () => {
  const [currentView, setCurrentView] = useState<View>(View.SIGN_IN);

  return {
    currentView,
    showAlmostDone: () => setCurrentView(View.ALMOST_DONE),
    showForgotPassword: () => setCurrentView(View.FORGOT_PASSWORD),
    showSignUp: () => setCurrentView(View.SING_UP),
    showSignIn: () => setCurrentView(View.SIGN_IN),
    showChangePassword: () => setCurrentView(View.CHANGE_PASSWORD),
  };
};
