/**
 * @description public http client for making requests without JWT token
 */

import axios from 'axios';
import { getApiUrl } from './baseURL';

export const publicHTTPClient = axios.create({
  baseURL: getApiUrl(),
});
