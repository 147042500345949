import React from 'react';
import {Ripple} from 'components/Ripple/Ripple';
import {INavItem} from '../navigation-config';
import {Divider, Label, StyledNavLink} from "../DesktopNavigation/DesktopNavigation.styled";


interface NavItemProps extends INavItem {
}

export const NavItem: React.FC<NavItemProps> = React.memo(
    ({path, label, icon, withDivider = false, isActive}) => (
        <li>
            <StyledNavLink to={path} exact isActive={isActive} data-testid="nav_item">
                <Ripple opacity={60}/>
                {icon}
                <Label>{label}</Label>
            </StyledNavLink>
            {withDivider && <Divider/>}
        </li>
    )
);

