import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorPage, AuthErrorPage } from 'pages';
import { useAuth } from 'services/contexts/AuthContext';

export const ErrorBoundary: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Sentry.ErrorBoundary
      fallback={(fallbackProps) =>
        isAuthenticated() ? (
          <AuthErrorPage fallbackProps={fallbackProps} />
        ) : (
          <ErrorPage fallbackProps={fallbackProps} />
        )
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
