import React, {createContext, useContext, useEffect, useState} from 'react';

import {Celebrity, Role} from 'types';
import {getUser} from "./AuthContext";

export const LOCAL_STORAGE_CELEBRITY_KEY = 'celebrity';

export interface User {
    email: string | null;
    first_name: string | null;
    middle_name: string | null;
    last_name: string | null;
    job_title: string | null;
    company_name: string | null;
    score: number | null;
    clients: Celebrity[];
    role: Role | null;
    is_monitoring: boolean;
}

interface ICelebrityContext {
    currentCelebrity: Celebrity | null;
    setCurrentCelebrity: (celebrity: Celebrity) => void;
}

const INITIAL_CTX_STATE: ICelebrityContext = {
    currentCelebrity: null,
    setCurrentCelebrity: (celebrity: Celebrity) => ({})
};

const CelebrityContext = createContext<ICelebrityContext>(INITIAL_CTX_STATE);

const useCelebrity = () => {
    const ctx = useContext(CelebrityContext);
    return ctx;
};


interface CelebrityProviderProps {
    children: JSX.Element;
}

const CelebrityProvider: React.FC<CelebrityProviderProps> = ({children}) => {
    const [user] = useState(() => getUser());
    const [currentCelebrity, setCurrentCelebrity] = useState<Celebrity | null>(() => {
        const persistetCelebrity = localStorage.getItem(LOCAL_STORAGE_CELEBRITY_KEY);
        let initialCelebrity = null;

        if (persistetCelebrity) {
            initialCelebrity = JSON.parse(persistetCelebrity);
        } else if (user.clients) {
            initialCelebrity = user.clients[0];
        }

        return initialCelebrity;
    });

    useEffect(() => {
        const persistetCelebrity = localStorage.getItem(LOCAL_STORAGE_CELEBRITY_KEY);

        if (persistetCelebrity) {
            const celebrityToSet = JSON.parse(persistetCelebrity);
            setCelebrity(celebrityToSet);
        } else if (user.clients) {
            setCelebrity(user.clients[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.clients]);


    const setCelebrity = (celebrity: Celebrity | undefined) => {
        if (celebrity) {
            setCurrentCelebrity(celebrity);
            localStorage.setItem(LOCAL_STORAGE_CELEBRITY_KEY, JSON.stringify(celebrity));
        }
    };

    return (
        <CelebrityContext.Provider
            value={{
                currentCelebrity,
                setCurrentCelebrity: setCelebrity
            }}
        >
            {children}
        </CelebrityContext.Provider>
    );
};

export {CelebrityProvider, CelebrityContext, useCelebrity};
