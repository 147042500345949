import React from 'react';
import { StateButton } from 'components/StateButton/StateButton';
import { HitType, useHitTypeCtx } from 'contexts/HitTypeContext';
import { ButtonGroupContainer } from "./HitTypeSwitch.styled";


export const HitTypeSwitch: React.FC = () => {
  const { currentHitType, allowedTypes, setCurrentHitType } = useHitTypeCtx();

  const handleHitTypeBtnClick = (hitType: HitType) => () => {
    setCurrentHitType(hitType);
  };

  return (
    <ButtonGroupContainer>
      <StateButton
        disabled={!allowedTypes.includes(HitType.ALL)}
        variant="DEFAULT"
        isActive={currentHitType === HitType.ALL}
        onClick={handleHitTypeBtnClick(HitType.ALL)}
      >
        ALL
      </StateButton>
      <StateButton
        disabled={!allowedTypes.includes(HitType.COMMERCIAL)}
        variant="COMMERCIAL"
        isActive={currentHitType === HitType.COMMERCIAL}
        onClick={handleHitTypeBtnClick(HitType.COMMERCIAL)}
      >
        COMMERCIAL
      </StateButton>
      <StateButton
        disabled={!allowedTypes.includes(HitType.VICE)}
        variant="REPUTATIONAL"
        isActive={currentHitType === HitType.VICE}
        onClick={handleHitTypeBtnClick(HitType.VICE)}
      >
        REPUTATIONAL
      </StateButton>
      <StateButton
        disabled={!allowedTypes.includes(HitType.EDITORIAL)}
        variant="EDITORIAL"
        isActive={currentHitType === HitType.EDITORIAL}
        onClick={handleHitTypeBtnClick(HitType.EDITORIAL)}
      >
        EDITORIAL
      </StateButton>
    </ButtonGroupContainer>
  );
};
