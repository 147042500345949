import React from 'react';
import { Ripple } from 'components/Ripple/Ripple';
import { Variant } from "utils/constants";
import { StyledToggleButton, StyledToggleButtonContainer } from "./ToggleButton.styled";


interface Tab {
  label: string;
  key: string | number;
}

export interface TabButton extends Tab {
  onClick: (tab: Tab) => void;
}

interface ToggleButtonProps {
  tabs: TabButton[];
  active: string | number;
  variant?: Variant;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ tabs, active, variant = Variant.BLACK,
}) => {
  return (
    <StyledToggleButtonContainer>
      {tabs.map(({ label, key, onClick }, index, arr) => (
        <StyledToggleButton
          variant={variant}
          key={key}
          isFirst={index === 0}
          isLast={index === arr.length - 1}
          isActive={key === active}
          onClick={() => onClick({ label, key })}
        >
          <Ripple opacity={30} />
          {label}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonContainer>
  );
};
