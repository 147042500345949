import React, { createContext, useContext, useState } from 'react';
import { useFetchCtx } from 'contexts/FetchContext';
import { PRIVATE_API } from 'services/config/api';
import { AxiosResponse } from 'axios';
import { CustomerHit } from 'types';
import { showErrorToast, showInfoToast } from 'utils/toast/toast';
import { ERROR_MESSAGE, getCookie } from "utils/constants";
import { PorfolioImage } from "../pages/Portfolio/Portfolio/Portfolio";
import {isEmpty} from "lodash";

interface MetaDataReportI {
  image: string,
  image_id: number,
  author: object,
  exif: object
}

interface SoOI {
  case_id: number
}


interface IImageUploadContext {
  progress: number;
  total: number;
  isUploading: boolean;
  setIsUploading: (val: boolean) => void;
  uploadImage: (files: File[], folder?: string) => Promise<AxiosResponse<Pick<CustomerHit, 'image'>>[]>;
  foldersLoading: boolean;
  fetchFolders: any;
  foldersData: any;
  isFolderDelLoading: boolean;
  deleteFolder: (foldId: number) => any;
  generateHits: (imgIds: number[]) => any;
  bulkDelete: (imgIds: number[]) => any;
  isImagesDelLoading: boolean;
  isReportLoading: boolean;
  createExifReport: any;
  isSoOLoading: boolean;
  signSoO: any;
  isFolderDeleted: boolean;
  setIsFolderDeleted: (val: boolean) => void;
}

const INITIAL_CTX_STATE: IImageUploadContext = {
  progress: 0,
  total: 0,
  isUploading: false,
  setIsUploading: () => ({}),
  uploadImage: () => Promise.resolve([]),
  foldersLoading: false,
  fetchFolders: () => Promise.resolve([]),
  foldersData: [],
  isFolderDelLoading: false,
  isImagesDelLoading: false,
  deleteFolder: () => Promise.resolve([]),
  bulkDelete: () => Promise.resolve([]),
  generateHits: () => Promise.resolve([]),
  isReportLoading: false,
  isSoOLoading: false,
  createExifReport: () => Promise.resolve([]),
  signSoO: () => Promise.resolve([]),
  isFolderDeleted: false,
  setIsFolderDeleted: () => Promise.resolve([]),
};

const ImageUploadContext = createContext<IImageUploadContext>(INITIAL_CTX_STATE);

export const useImageUploadCtx = () => {
  const ctx = useContext(ImageUploadContext);

  if (!ctx) {
    throw new Error(ERROR_MESSAGE);
  }

  return ctx;
};

export const ImageUploadContextProvider: React.FC = ({ children }) => {
  const { post, get, del, patch } = useFetchCtx();
  const [isFolderDeleted, setIsFolderDeleted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [foldersData, setFoldersData] = useState({});
  const [isFolderDelLoading, setIsFolderDelLoading] = useState(false);
  const [isImagesDelLoading, setIsImagesDelLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isSoOLoading, setIsSoOLoading] = useState(false);



  const bulkUpload = async (filesToUpload: File[], folder?: string) => {
    setTotal(filesToUpload.length);
    setProgress(0);

    const formDat = filesToUpload.map((file) => {
      const formData = new FormData();
      formData.append('file', file);
      return formData;
    });

    const dat = await recUpload(formDat, folder);
    return dat;
  };

  const uploadImage = (formData: FormData, folder?: string) => {
    if (!isEmpty(folder)) {
      formData.append('folder_name', `${folder}`)
    }
    return post<PorfolioImage>(`${PRIVATE_API.UPLOAD}?folder_name=${folder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const recUpload = async (files: FormData[], folder?: string) => {
    let res: AxiosResponse<Pick<CustomerHit, 'image'>>[] = [];

    for (const file of files) {
      try {
        const result = await uploadImage(file, folder);

        setProgress((prev) => prev + 1);
        res.push(result);
      } catch (error) {
        showErrorToast(`The error occured during uploading image`);
      }
    }

    return res;
  };

  const fetchFolders = async () => {
    setFoldersLoading(true)
    const {data = []} = await get(
        '/v2/client-images/folders',
    );
    setFoldersData(data)
    setFoldersLoading(false)
    return data;
  };

  const deleteFolder = async (foldId: number) => {
    setIsFolderDeleted(true)
    setIsFolderDelLoading(true)
    const { data } = await del(
        `portfolio/folders/`, { data: { folder_id: foldId }}, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': getCookie('csrftoken')
          },
        }
    );
    const {success} = data;
    if (success === 'True') {
      showInfoToast('Folder deleted successfully');
    } else {
      showErrorToast(`The error occurred during folder delete`);
    }
    setIsFolderDelLoading(false)
    setIsFolderDeleted(false)
    return data;
  };

  const bulkDelete = async (imgIds: number[]) => {
    setIsImagesDelLoading(true)
    const { data } = await del(
        `portfolio/bulk/`, { data: { image_ids: imgIds }}, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': getCookie('csrftoken')
          },
        }
    );
    const {success} = data;
    if (success === true) {
      showInfoToast('Selected images deleted successfully');
    } else {
      showErrorToast(`The error occurred during images delete`);
    }
    setIsImagesDelLoading(false)
    return data;
  };

  const generateHits = async (imgIds: number[]) => {
    const {data} = await post(
        `client-images/hits/generate`,  { image_ids: imgIds }, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken')
          },
        }
    );
    const {success} = data;
    if (success === true) {
      showInfoToast('Hits are being generated');
    } else {
      showErrorToast(`The error occurred during hits generation`);
    }
    return data;
  };

  const createExifReport = async (formData: MetaDataReportI) => {
    setIsReportLoading(true)
    const { data } = await post(
        `exif-report/`, formData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken')
          },
        }
    );
    setIsReportLoading(false)
    const {url} = data || {};
    if (url) {
      window.open(url, "_blank")
    } else {
      showErrorToast(ERROR_MESSAGE);
    }
    return data;
  };

  const signSoO = async (formData: SoOI, refId: number) => {
    setIsSoOLoading(true)
    const { data } = await patch(
        `v2/client-images/${refId}/request-signature`, formData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken')
          },
        }
    );
    setIsSoOLoading(false)
    const {message} = data;
    showInfoToast(message)
  };

  const value = {
    progress,
    total,
    isUploading,
    setIsUploading: (val: boolean) => {
      setIsUploading(val);
    },
    uploadImage: (filesToUpload: File[], folder?: string) => bulkUpload(filesToUpload, folder),
    foldersLoading,
    fetchFolders,
    foldersData,
    isFolderDelLoading,
    deleteFolder,
    isImagesDelLoading,
    bulkDelete,
    generateHits,
    isReportLoading,
    createExifReport,
    isSoOLoading,
    signSoO,
    isFolderDeleted,
    setIsFolderDeleted
  };

  return (
    <ImageUploadContext.Provider value={value}>{children}</ImageUploadContext.Provider>
  );
};
