import React from 'react';
import {StyledTooltip, WideStyledTooltip, StyledTooltipProps, TooltipContent} from "./Tooltip.styled";


interface TooltipProps extends StyledTooltipProps {
  content: React.ReactNode;
  fixedWidth?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, fixedWidth, right = false }) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const ContainerComponent = fixedWidth ? WideStyledTooltip: StyledTooltip;

  return (
    <ContainerComponent right={right} onClick={handleClick}>
      <TooltipContent>{content}</TooltipContent>
    </ContainerComponent>
  );
};
