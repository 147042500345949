import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Button } from 'components';
import { useForm } from '../hooks/useForm';
import { ForgotPassword } from '../components/ForgotPassword/ForgotPassword';
import { AlmostDone } from '../components/AlmostDone/AlmostDone';

import {
  LemonBtn,
  FormContainer,
  StyledForm,
  ForgotPasswordBtn,
  Heading,
} from './Form.styled';
import { useCurrentView, View } from '../hooks/useCurrentView';
import { useGetUserQuery, useSignUpMutation, useSignInMutation } from 'services/auth';
import { useAuth } from 'services/contexts/AuthContext';
import { ChangePassword } from '../components/ChangePassword/ChangePassword';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE_CELEBRITY_KEY, useCelebrity } from "services/contexts/CelebrityContext";

export const Form: React.FC = () => {
  const {
    currentView,
    showSignIn,
    showSignUp,
    showForgotPassword,
    showAlmostDone,
    showChangePassword,
  } = useCurrentView();
  const IS_SIGN_IN = currentView === View.SIGN_IN; // extracted because it's used in other places

  const { setAuthInfo, setUserInfo } = useAuth();
  const { signIn, isLoading } = useSignInMutation();
  const { signUp } = useSignUpMutation();
  const { getUser } = useGetUserQuery();
  const { setCurrentCelebrity } = useCelebrity();

  const { pathname } = useLocation();

  const isChangePassword = pathname.includes('password-change');

  const [resendEmail, setResendEmail] = useState('');

  useEffect(() => {
    if (isChangePassword) {
      showChangePassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangePassword]);

  const submitCallback = async (credentials: { password: string; email: string }) => {
    if (IS_SIGN_IN) {
      const tokens = await signIn(credentials);
      const user = await getUser(tokens.access);

      // In below block: on sign in, we set the current user again so they donot need to refresh
      const persistetCelebrity = localStorage.getItem(LOCAL_STORAGE_CELEBRITY_KEY);
      if (persistetCelebrity) {
        const celebrityToSet = JSON.parse(persistetCelebrity);
        setCurrentCelebrity(celebrityToSet);
      } else if (user.clients) {
        const is_enterprise = user.clients.length > 1;
        if (!is_enterprise) {
            setCurrentCelebrity(user.clients[0]);
        }
      }

      setUserInfo(user);
      setAuthInfo(tokens);
    } else {
      setResendEmail(credentials.email);
      await signUp(credentials);
      showAlmostDone();
    }
  };
  // TODO: setAscynError use it after connecting to BE
  const { errors, control, /*setAsyncError,*/ onSubmit } = useForm(submitCallback);

  const SHOW_SING_UP_IN = IS_SIGN_IN || currentView === View.SING_UP;

  const subHeader = IS_SIGN_IN
    ? "Don't have an account yet?"
    : 'Already have an account?';

  return (
    <div>
      {SHOW_SING_UP_IN && (
        <>
          <Heading>
            {subHeader}{' '}
            <LemonBtn onClick={IS_SIGN_IN ? showSignUp : showSignIn}>
              Sign {IS_SIGN_IN ? 'Up' : 'In'}
            </LemonBtn>
          </Heading>

          <FormContainer>
            <StyledForm onSubmit={onSubmit} autoComplete="off" noValidate>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                render={({ ...rest }) => (
                  <TextField
                    mt="30px"
                    id="email"
                    placeholder="Email"
                    icon="user"
                    {...rest}
                    error={errors.email?.message}
                  />
                )}
              />

              <Controller
                name="password"
                defaultValue=""
                control={control}
                render={({ ...rest }) => (
                  <TextField
                    mt="30px"
                    id="password"
                    placeholder="Password"
                    icon="password"
                    type="password"
                    error={errors.password?.message}
                    {...rest}
                  />
                )}
              />
              {IS_SIGN_IN && (
                <ForgotPasswordBtn type="button" onClick={showForgotPassword}>
                  FORGOT PASSWORD?
                </ForgotPasswordBtn>
              )}
              <Button disabled={isLoading} mt="20px" block variant="LEMON" type="submit">
                {isLoading ? 'Loading...' : IS_SIGN_IN ? 'SIGN IN' : 'SIGN UP'}
              </Button>
            </StyledForm>
          </FormContainer>
        </>
      )}

      {currentView === View.CHANGE_PASSWORD && (
        <ChangePassword onGoBackClick={showSignIn} />
      )}

      {currentView === View.FORGOT_PASSWORD && (
        <ForgotPassword onGoBackClick={showSignIn} />
      )}

      {currentView === View.ALMOST_DONE && (
        <AlmostDone resendEmail={resendEmail} onGoBackClick={showSignIn} />
      )}
    </div>
  );
};
