import styled from 'styled-components';

export const ModalIconWrapper = styled.div`
  margin: 20px auto;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
`;
