import styled from "styled-components";
import {space, Space} from "style/spacing";
import {theme} from "assets/styles/theme";

export const CardWrapper = styled.div<Space>`
  background: ${theme.colors.darkBoxes};
  mix-blend-mode: normal;
  border-radius: 6px;

  && {
    ${space}
  }
`;

export const CardWrapperSpaced = styled(CardWrapper)`
  padding: 13px;
  margin: 5px 5px 10px 5px;

  @media screen and (min-width: 576px) {
    margin: 10px 10px 20px 10px;
    padding: 20px;
  }
`;

export const CardTitle = styled.h3`
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: ${theme.colors.mediumGrey};
  text-transform: uppercase;
  margin-bottom: 20px;

  @media screen and (min-width: 576px) {
    font-size: 1.2rem;
  }
`;
