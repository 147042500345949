import {CSSProperties} from "react";
import {Palette} from "types";
import {StateButtonVariants} from "./StateButton";
import {theme} from "assets/styles/theme";

type TextLinkStyles = Required<
    Pick<CSSProperties, 'color'> & {
    activeBackgroundColor: CSSProperties['color'];
    hoverBackgroundColor: CSSProperties['color'];
    disabledBackgroundColor: CSSProperties['color'];
    activeColor: CSSProperties['color'];
    disabledColor: CSSProperties['color'];
    color: CSSProperties['color'];

    activeBorderColor: CSSProperties['color'];
    borderColor: CSSProperties['color'];
    disabledBorderColor: CSSProperties['color'];
}
    >;

export const styles: Palette<StateButtonVariants, TextLinkStyles> = {
    DEFAULT: {
        activeBackgroundColor: theme.colors.black,
        hoverBackgroundColor: theme.colors.white + '22',
        disabledBackgroundColor: 'transparent',
        activeColor: theme.colors.mediumGrey,
        color: theme.colors.mediumGrey,
        disabledColor: theme.colors.greySelected,
        borderColor: theme.colors.mediumGrey,
        disabledBorderColor: theme.colors.greySelected,
        activeBorderColor: theme.colors.black,
    },
    COMMERCIAL: {
        activeBackgroundColor: theme.colors.commercial + '66',
        hoverBackgroundColor: theme.colors.commercial + '22',
        disabledBackgroundColor: 'transparent',
        activeColor: theme.colors.commercial,
        disabledColor: theme.colors.commercial + '44',
        color: theme.colors.mediumGrey,
        borderColor: theme.colors.commercial,
        disabledBorderColor: theme.colors.commercial + '44',
        activeBorderColor: theme.colors.commercial,
    },
    REPUTATIONAL: {
        activeBackgroundColor: theme.colors.reputational + '66',
        hoverBackgroundColor: theme.colors.reputational + '22',
        disabledBackgroundColor: 'transparent',
        activeColor: theme.colors.reputational,
        disabledColor: theme.colors.reputational + '44',
        color: theme.colors.mediumGrey,
        borderColor: theme.colors.reputational,
        disabledBorderColor: theme.colors.reputational + '44',
        activeBorderColor: theme.colors.reputational,
    },
    EDITORIAL: {
        activeBackgroundColor: theme.colors.editorial + '66',
        hoverBackgroundColor: theme.colors.editorial + '22',
        disabledBackgroundColor: 'transparent',
        activeColor: theme.colors.editorial,
        disabledColor: theme.colors.editorial + '44',
        color: theme.colors.mediumGrey,
        borderColor: theme.colors.editorial,
        disabledBorderColor: theme.colors.editorial + '44',
        activeBorderColor: theme.colors.editorial,
    },
};