import React from 'react';

import { Button } from 'components';
import { Container } from "./ErrorPage.styled";
import vaaamLogo from 'assets/images/VAAAM.png';

interface ErrorPageProps {
  fallbackProps: {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
  };
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  fallbackProps: { resetError },
}) => {
  return (
    <div onClick={resetError}>
      <Container>
        <img src={vaaamLogo} alt="vaaam" />

        <h2>We apologize - we've run into an issue.</h2>
        <Button variant="WHITE" onClick={() => resetError()}>
          Try again
        </Button>
        <p>
          If the issue persists, contact us at{' '}
          <a href="mailto:support@vaaam.io">support@vaaam.io</a>
        </p>
      </Container>
    </div>
  );
};
