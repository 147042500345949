import styled, { css } from "styled-components";
import { theme } from "assets/styles/theme";
import { TDataProps } from './Datatable';

export const TableContainer = styled.div<{
  height?: string;
  maxHeight?: string;
}>`
  height: ${({ height = "" }) => `${height}`};
  max-height: ${({ maxHeight = "" }) => maxHeight};
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  table-layout: fixed;

  ::-webkit-scrollbar-track {
    border: 1px solid #000;
    background-color: rgb(0, 0, 0);
    background-clip: content-box;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #737272;
    border: 1px solid #000;
    height: 5px;
  }
`;

export const Table = styled.table<{ fixedLayout?: boolean }>`
  width: 100%;
  position: relative;
  z-index: 2;
  border-collapse: collapse;
  font-size: 1.3rem;
  letter-spacing: 0.4px;
  table-layout: ${({ fixedLayout }) => (fixedLayout ? "fixed" : "auto")};
`;

export const TBody = styled.tbody<{ showBackground: boolean }>`
  border-radius: 6px;

  background-color: ${({ showBackground }) =>
    showBackground ? theme.colors.darkBoxes : "transparent"};
`;

export const HoverLayer = styled.div<{
  customHoverHeight?: string;
  active?: boolean;
}>`
  display: none;
  position: absolute;
  height: 40px;
  left: 10px;
  right: 10px;
  // hover effect alignment
  transform: ${({ customHoverHeight }) =>
    customHoverHeight
      ? `translateY(${customHoverHeight})`
      : "translateY(-31%)"};
  border-radius: 12px;

  tr:hover & {
    background: ${theme.colors.greySelected};
  }

  ${({ active }) =>
    active &&
    css`
      background: #252525;
    `};

  @media screen and (min-width: 1200px) {
    display: inline-block;
  }
`;

export const TRow = styled.tr<{ hoverEffect?: boolean; isClickable?: boolean }>`
  height: 50px;
  vertical-align: middle;
  color: ${theme.colors.mediumGrey};
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 0.1px;
  transition: all 0.15s;
  position: relative;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "")};

  @media screen and (min-width: 1200px) {
    font-size: unset;
    letter-spacing: 0.5px;
  }

  & + & {
    border-top: 1px solid ${theme.colors.greySelected};
  }
`;

export const THead = styled.thead`
  height: 40px;
`;

export const ScrollTableWrapper = styled.div`
  height: 60vh;
  overflow: scroll;
  overflow-x: hidden;

  table {
    width: 100%;
  }

  thead {
    background-color: #202020;
    position: sticky;
    z-index: 1000;
    top: 0;
  }
`;

export const THRow = styled(TRow)``;

export const StyledCell = styled.td<TDataProps>`
  text-align: ${({ cellAlign = "" }) => cellAlign};
  color: ${({ color = theme.colors.mediumGrey }) => color};
  width: ${({ width = "" }) => width};
  padding: ${({ padding = "" }) => padding || "5px 10px"};
  height: 25px;

  &:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    padding-right: 20px;
  }
`;

export const InfoWrapper = styled.span`
  margin-left: 4px;
`;

export const StyledHeaderCell = styled(StyledCell)<{
  isSortable: boolean;
  maxWidth?: string;
}>`
  transition: all 0.15s;
  color: ${({ color }) => color};
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : "default")};
  width: ${({ width = "" }) => width};
  max-width: ${({ maxWidth }) => maxWidth};

  &:hover {
    color: ${({ isSortable, color }) =>
      isSortable ? theme.colors.white : color};
  }
`;

export const StyledHeaderCellContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledHeaderCellLabel = styled.span`
  width: max-content;
`;

export const IconWrapper = styled.span<{
  sortType: "asc" | "desc" | null | undefined;
}>`
  position: relative;
  top: 2px;
  cursor: pointer;

  .sortArrowUp {
    fill: ${({ sortType }) =>
      sortType === "asc" ? theme.colors.white : theme.colors.mediumGrey};
  }

  .sortArrowDown {
    fill: ${({ sortType }) =>
      sortType === "desc" ? theme.colors.white : theme.colors.mediumGrey};
  }
`;
