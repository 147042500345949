import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as LeaveLogo } from 'assets/svgs/LeavePageLogo.svg';
import { Button } from 'components';
import { InnerContainer, NoPageTitle, OuterContainer, WrongPath } from "./NotFound.styled";

const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <OuterContainer>
      <InnerContainer>
        <LeaveLogo />
        <NoPageTitle>There is no such page</NoPageTitle>
        <WrongPath>
          <strong>{history.location.pathname}</strong>
        </WrongPath>
        <Button variant="WHITE" onClick={() => history.goBack()}>
          Go Back
        </Button>
      </InnerContainer>
    </OuterContainer>
  );
};

export default NotFound;
