export const CLIENT_ROUTES = {
  SIGN_UP_IN_PAGE: '/',
  DASHBOARD_PAGE: '/dashboard',
  CASES_PAGE: '/cases',
  CASE_PAGE: '/case/:id',
  CURATED_FINDING_PAGE: '/case/:id/curated-findings',
  PRE_CASE_DETAIL_PAGE: '/pre-case/:id/pre-cases',
  UNDER_REVIEW_FINDING_PAGE: '/case/:id/under-review',
  CASES_FINDING_PAGE: '/case/:id/cases',
  CURATED_FINDINGS_PAGE: '/curated-findings',
  PRE_CASES_PAGE: '/pre-cases',
  MONITORING: '/monitoring',
  LEDGER_PAGE: '/ledger',
  ACCOUNT_ACTIVATION_PAGE: '/account-activation/:token',
  PASSWORD_CHANGE_PAGE: '/password-change/:token',
  SOCIAL_IN_REVIEW_PAGE: '/social-case/under-review/:id',
  SOCIAL_CASE_PAGE: '/social-case/cases/:id',
  TAKE_ACTION: '/under-review',
  ANALYTICS_PAGE: '/analytics',
  PORTFOLIO_PAGE: '/portfolio',
  PORTFOLIO_FOLDER_PAGE: '/portfolio/:name',
  DOCUMENTS_PAGE: '/documents',
  SOCIAL_LISTENING: '/social-listening',
  SETTINGS_PAGE: '/settings',
  USER_SETTINGS_PAGE: '/user-settings',
  LEAVE_PAGE: '/leave-page/:link',
  TEMPLATE_PAGE: '/template',
};
