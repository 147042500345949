import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { CLIENT_ROUTES } from 'config';
import { usePrevious } from 'hooks/usePrevious';

export enum HitType {
  COMMERCIAL,
  EDITORIAL,
  VICE,
  // Inspector only status, added on case producer's request. Not used on frontend atm
  INSPECTOR_STATUS,
  ALL,
}

export type ContextProps = {
  currentHitType: HitType;
  previousHitType: HitType;
  allowedTypes: HitType[];
  setCurrentHitType: (hitType: HitType) => void;
};

const INITIAL_ALLOWED_TYPES: HitType[] = [
  HitType.COMMERCIAL,
  HitType.EDITORIAL,
  HitType.VICE,
  HitType.ALL,
];

const INITIAL_CTX_STATE = {
  allowedTypes: INITIAL_ALLOWED_TYPES,
  currentHitType: HitType.ALL,
  previousHitType: HitType.ALL,
  setCurrentHitType: () => ({}),
};

const HitTypeContext = createContext<ContextProps>(INITIAL_CTX_STATE);

export const useHitTypeCtx = () => {
  const ctx = useContext(HitTypeContext);

  if (!ctx) {
    throw new Error('Error');
  }

  return ctx;
};

const HitTypeProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [currentHitType, setCurrentHitType] = useState<HitType>(HitType.ALL);
  const [allowedTypes, setAllowedTypes] = useState<HitType[]>(INITIAL_ALLOWED_TYPES);
  const { pathname } = useLocation();
  const previousHitType = usePrevious<HitType>(currentHitType);

  useEffect(() => {
    // todo: reconsider this approach ids may not be just number
    const casesWithId = new RegExp(`/case/[0-9]`);

    if (casesWithId.test(pathname)) {
      setAllowedTypes([currentHitType]);
    } else if (
      [CLIENT_ROUTES.CURATED_FINDINGS_PAGE].includes(
        pathname
      )
    ) {
      setAllowedTypes([HitType.COMMERCIAL, HitType.EDITORIAL, HitType.VICE]);
      resetTypeToCommercial();
    } else if (
      [
        CLIENT_ROUTES.PORTFOLIO_PAGE,
        CLIENT_ROUTES.DOCUMENTS_PAGE,
        CLIENT_ROUTES.CASES_PAGE,
        CLIENT_ROUTES.TAKE_ACTION,
        CLIENT_ROUTES.SOCIAL_LISTENING
      ].includes(pathname)
    ) {
      setAllowedTypes([HitType.ALL]);
      resetTypeToAll();
    } else {
      setAllowedTypes(INITIAL_ALLOWED_TYPES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentHitType]);

  const resetTypeToCommercial = () => {
    if (currentHitType === HitType.ALL) {
      setCurrentHitType(HitType.COMMERCIAL);
    }
  };

  const resetTypeToAll = () => {
    if (currentHitType !== HitType.ALL) {
      setCurrentHitType(HitType.ALL);
    }
  };

  const changeCurrentHitType = useCallback(
    (hitType: HitType) => setCurrentHitType(hitType),
    []
  );

  return (
    <HitTypeContext.Provider
      value={{
        allowedTypes,
        currentHitType,
        previousHitType,
        setCurrentHitType: changeCurrentHitType,
      }}
    >
      {children}
    </HitTypeContext.Provider>
  );
};

export { HitTypeContext, HitTypeProvider };
