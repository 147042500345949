import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const Toolbar = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: calc(73px);
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.3s;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  background-color: ${theme.colors.darkBoxes};
  z-index: 1001;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-110%)')};
`;

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  transition: all 0.2s;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  background-color: #00000088;
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(-20%, -20%);
  z-index: 1002;
  background-color: ${theme.colors.greySelected};
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px 1px rgba(165, 165, 165, 0.12);
`;