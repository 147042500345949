import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;

  * + * {
    margin-top: 50px;
  }

  color: ${theme.colors.darkenWhite};

  a {
    color: ${theme.colors.darkenWhite};
    font-weight: 600;
    letter-spacing: 0.5px;
  }
`;
