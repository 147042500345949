import React, { KeyboardEvent, ReactNode } from 'react';
import { Icon, IconName, IconTooltip } from 'components';
import { theme } from 'assets/styles/theme';
import { Space } from 'style/spacing';
import {
  CopyContainer,
  ErrorMsg,
  IconContainer,
  Input,
  LabelTxt,
  TextAreaInput,
  TextFieldContainer,
  TooltipContainer,
  ClearButtonWrapper,
} from "./TextField.styled";
import copy from 'copy-to-clipboard';
import { showInfoToast } from "utils/toast/toast";

interface TextFieldProps extends Space {
  value: string;
  id: string;
  name: string;
  block?: boolean;
  icon?: IconName;
  isCopy?: boolean;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  type?: 'text' | 'password' | 'textarea';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
  label?: string;
  tooltip?: ReactNode;
  withClear?: boolean;
  width?: string;
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      icon,
      error,
      disabled,
      type = 'text',
      readonly = false,
      isCopy = false,
      block = false,
      maxLength,
      mt,
      ml,
      mr,
      mb,
      m,
      label,
      tooltip,
      withClear,
      width,
      ...rest
    },
    ref
  ) => {
    const Component = type === 'textarea' ? TextAreaInput : Input;

    return (
      <TextFieldContainer mt={mt} ml={ml} mr={mr} mb={mb} m={m} width={width}>
        {label && <LabelTxt>{label}</LabelTxt>}
        {!!icon && (
          <IconContainer style={{opacity: disabled ? 0.4 : 1}}>
            <Icon name={icon} color={theme.colors.darkenWhite}/>
          </IconContainer>
        )}
        {tooltip && (
          <TooltipContainer>
            <IconTooltip
              content={tooltip}
              fixedWidth
            />
          </TooltipContainer>
        )}
        {
          // @ts-ignore
          <Component
            ref={ref}
            maxLength={maxLength}
            readOnly={readonly}
            block={block}
            error={!!error}
            hasIcon={!!icon || !!tooltip}
            disabled={disabled}
            type={type}
            onKeyPress={(e: KeyboardEvent) => { e.key === 'Enter' && e.preventDefault(); }}
            {...rest}
          />
        }
        {isCopy && (
          <CopyContainer style={{opacity: disabled ? 0.4 : 1}} title={'copy'}>
            <Icon
              name={'copy'}
              color={theme.colors.darkenWhite}
              onClick={() => {
                copy(rest.value)
                showInfoToast('Value copied successfully');
              }}
            />
          </CopyContainer>
        )}
        {withClear && (
          <ClearButtonWrapper
            title="Clear"
            onClick={() => rest.onChange?.({ target: { value: '', name: rest.name } } as any)}
          >
            &#x2715;
          </ClearButtonWrapper>
        )}
        {!!error && <ErrorMsg>{error}</ErrorMsg>}
      </TextFieldContainer>
    );
  }
);
