import styled, {css} from "styled-components";
import {theme} from "assets/styles/theme";
import {Space, space} from "style/spacing";

interface DropZoneProps extends Space {
    isUploaded: boolean;
    isDisabled: boolean;
}

export const DropZone = styled.label<DropZoneProps>`
  display: inline-block;
  height: ${({ isUploaded }) => (isUploaded ? 'unset' : '250px')};
  width: 250px;
  max-height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: dashed 2px ${theme.colors.darkenWhite};
  position: relative;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  transition: all 0.15s;

  &:hover {
    border: dashed 2px ${theme.colors.white};
  }

  ${({ isUploaded }) =>
    isUploaded &&
    css`
      border: dashed 2px transparent !important;
    `}

  svg {
    stroke: ${theme.colors.darkenWhite} !important;
  }

  &:hover svg {
    stroke: ${theme.colors.white} !important;
  }

  ${space};
`;

export const HoverLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.black}44;
  opacity: 0;
  z-index: 5;
  transition: all 0.15s;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(4);
  }

  &:hover {
    opacity: 1;
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  width: 100%;
  top: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.p`
  white-space: nowrap;
  color: ${theme.colors.darkenWhite};
  margin-bottom: 20px;

  label:hover & {
    color: ${theme.colors.white};
  }
`;

export const UploadedFile = styled.img`
  object-fit: contain;
  width: 100%;
`;