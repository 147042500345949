/**
 * @description dynamic imports to simplify icons usage;
 * in any case/issue @see https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline
 * For those who are getting undefined for ReactComponent when the SVG is dynamically imported, it is due to
 * a bug where the Webpack plugin that adds the ReactComponent to each SVG that is imported somehow does
 * not trigger on dynamic imports. Based on this solution, we can temporary resolve it by enforcing the
 * same loader on your dynamic SVG import. The only difference is that the ReactComponent is now the default output.
 *
 * @example <Icon name="action" />
 */
import React, { useEffect, useRef, useState } from 'react';
import { StyledIconWrapper } from "./Icon.styled";
import { ERROR_MESSAGE } from "utils/constants";



type SVGType = React.FC<React.SVGProps<SVGSVGElement>>;

export const useDynamicSVGImport = (name: string) => {
  const ImportedIconRef = useRef<SVGType>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!./../../assets/icons/${name}.svg`
          )
        ).default;
      } catch (err) {
        /**
         * once there is error context dispatch info about error
         */
        console.error(err);
        setError(new Error(ERROR_MESSAGE));
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
};

/**
 * @description after add new icon to use it with Icon component update type IconName as eg.
 * newIcon.svg  =>  type IconName = "newIcon"  (type === file name)
 */

//  export type IconName =
//    | "takeAction"
//    | "noAction"
//    | "onHold"
//    | "check"
//    | "arrowDown"
//    | "filter"
//    | "infoIcon"
//    | "sortArrows" /* sortArrows icon has spcific classes for each direction to control its styles */
//    | "chevronDownWhite"
//    | "dashboard"
//    | "reports"
//    | "searches"
//    | "cases"
//    | "findings"
//    | "user";

export type IconName =
  | 'dashboard'
  | 'curatedFindings'
  | 'findings'
  | 'takeAction'
  | 'cases'
  | 'portfolio'
  | 'user'
  | 'arrowDown'
  | 'sortArrows' /* sortArrows icon has specific classes for each direction to control its styles */
  | 'noAction'
  | 'onHold'
  | 'download'
  | 'password'
  | 'tool'
  | 'chevronDownWhite'
  | 'check'
  | 'tag'
  | 'monitoring'
  | 'arrowRight'
  | 'csv'
  | 'folder'
  | 'folder-open'
  | 'jpg'
  | 'pdf'
  | 'png'
  | 'xls'
  | 'edit'
  | 'trash'
  | 'legalAssessment'
  | 'requestTakeDown'
  | 'takeDownSelected'
  | 'claimDamages'
  | 'legalWarning'
  | 'postLicense'
  | 'requestInformation'
  | 'sm_requestTakeDown'
  | 'sm_takeAction'
  | 'sm_takeDownSelected'
  | 'sm_legalAssessment'
  | 'sm_noAction'
  | 'sm_onHold'
  | 'sm_claimDamages'
  | 'sm_legalWarning'
  | 'sm_postLicense'
  | 'sm_requestInformation'
  | 'happy'
  | 'neutral'
  | 'sad'
  | 'chart'
  | 'empty'
  | 'times'
  | 'info'
  | 'upload'
  | 'action'
  | 'exclamation'
  | 'print'
  | 'weight'
  | 'web'
  | 'news'
  | 'twitter'
  | 'blog'
  | 'forums'
  | 'facebook'
  | 'open_extend'
  | 'expand'
  | 'stripe'
  | 'template'
  | 'plusCircle'
  | 'copy'
  | 'settingsDots';


interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconName | string;
  color: React.CSSProperties['color'];
  hoverColor?: React.CSSProperties['color'];
  disabledColor?: React.CSSProperties['color'];
  changeColorOnParentHover?: boolean;
}

export const Icon: React.FC<IconProps> = React.memo(
  ({
    name,
    color,
    changeColorOnParentHover = false,
    hoverColor,
    disabledColor,
    ...rest
  }) => {
    const { error, loading, SvgIcon } = useDynamicSVGImport(name);

    if (error || loading) {
      /**
       * once there is error context dispatch info about error
       */
      return <span />;
    }
    if (SvgIcon) {
      return (
        <StyledIconWrapper
          changeColorOnParentHover={changeColorOnParentHover}
          color={color}
          hoverColor={hoverColor}
          disabledColor={disabledColor}
        >
          <SvgIcon {...rest} style={{ fill: 'inherit', stroke: 'inherit' }} />
        </StyledIconWrapper>
      );
    }
    return null;
  }
);
