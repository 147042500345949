import React from 'react';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { theme } from 'assets/styles/theme';
import { Heading, LemonBtn, WhiteBtn } from '../../Form/Form.styled';

import { useSendAgainMutation } from 'services/auth';
import { EmailAgain, EmailSent } from "./AlmostDone.styled";

export const AlmostDone: React.FC<{ onGoBackClick: () => void; resendEmail: string }> = ({
  onGoBackClick,
  resendEmail,
}) => {
  const { sendAgain } = useSendAgainMutation();
  return (
    <div
      style={{
        color: theme.colors.darkenWhite,
      }}
    >
      <Heading>Almost done</Heading>

      <SendIcon style={{ marginTop: 20 }} stroke={theme.colors.darkenWhite} />

      <EmailSent>
        We have sent you an email to your address. <br />
        Please click the link within your registration.
      </EmailSent>
      <EmailAgain>
        Haven't got an email?{' '}
        <LemonBtn onClick={() => sendAgain(resendEmail)}>Send again</LemonBtn>
      </EmailAgain>

      <span>
        <WhiteBtn onClick={onGoBackClick}>&lsaquo; Back</WhiteBtn>
      </span>
    </div>
  );
};
