import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const RightColumn = styled.div`
  display: none;

  @media screen and (min-width: 980px) {
    display: flex;
    background: ${theme.colors.backgroundBlack};
    width: 50vw;
    height: 100vh;
    position: relative;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }
`;

export const Quote = styled.h1`
  color: ${theme.colors.darkenWhite};
  font-size: 4.5rem;
  font-weight: 500;
  transform: translateX(50px);
  opacity: 0;

  @keyframes mapAnimation {
    from {
      transform: translateX(50px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  animation: mapAnimation 0.4s ease-in 1 forwards;
`;

export const MapContainer = styled.div`
  @keyframes mapAnimation1 {
    from {
      transform: translateX(100px) scale(0.9);
      opacity: 0;
    }
    to {
      transform: translateX(0) scale(1);
      opacity: 1;
    }
  }
  transform: translateX(100px) scale(0.8);
  opacity: 0;

  animation: mapAnimation1 0.4s ease-in 1 forwards;
  animation-delay: 0.3s;
`;