import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Entered email has incorrect format')
    .required('You need to enter email'),
  password: yup.string().required('You need to enter password'),
});

type FormData = {
  email: string;
  password: string;
};

export const useForm = (submitCallback: (credentials: FormData) => void) => {
  const {
    control,
    errors,
    handleSubmit,
    setError: setAsyncError,
  } = useHookForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(submitCallback);

  return {
    errors,
    control,
    setAsyncError,
    onSubmit,
  } as const;
};
