import styled, {css} from "styled-components";
import {StateButtonVariants} from "./StateButton";
import {styles} from "./const";


export const StyledStateButton = styled.button<{
    isActive: boolean;
    variant: StateButtonVariants;
}>`
  position: relative;
  overflow: hidden;
  height: 45px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 30px;
  transition: all 0.15s;
  border: 2px solid ${({ variant }) => styles[variant].borderColor};
  color: ${({ variant }) => styles[variant].color};
  background-color: transparent;
  padding: 5px 13px;
  min-width: 180px;

  @media screen and (min-width: 1200px) {
    padding: 11px 23px;
    min-width: unset;
  }

  &:disabled {
    border-color: ${({ variant }) => styles[variant].disabledBorderColor};
    background-color: ${({ variant }) => styles[variant].disabledBackgroundColor};
    color: ${({ variant }) => styles[variant].disabledColor};
  }

  &:enabled:hover {
    cursor: pointer;
    background-color: ${({ variant }) => styles[variant].hoverBackgroundColor};
  }

  &&:enabled {
    ${({ isActive, variant }) =>
    isActive &&
    css`
        background-color: ${styles[variant].activeBackgroundColor};
        color: ${styles[variant].activeColor};
        border-color: ${styles[variant].activeBorderColor};
      `}
  }
`;

export const Label = styled.span<{
    isActive: boolean;
    variant: StateButtonVariants;
}>``;