import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import './style/flag.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { App } from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

process.env.NODE_ENV === 'production' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0, // in production set 0.2 ????
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
