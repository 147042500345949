import React from 'react';
import { Dropdown } from 'components';
import { useAuth } from 'services/contexts/AuthContext';
import { useLocation } from 'react-router';
import { useCelebrity } from "services/contexts/CelebrityContext";

export const CelebrityDropdown: React.FC = () => {
  const {
    user: { clients },
  } = useAuth();

  const {
    currentCelebrity,
    setCurrentCelebrity,
  } = useCelebrity();

  const { pathname } = useLocation();

  const is_enterprise = clients.length > 1;

  const celebrietiesDropDownOptions = clients.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const isAccountSwitchDisabled = () => {
    const casesWithId = new RegExp(`/case/[0-9]`) || new RegExp(`/social-case/cases/[0-9]`) || new RegExp(`/social-case/under-review/[0-9]`);
    return casesWithId.test(pathname) || isSocialAccountSwitchDisabled() || isFolderDisabled();
  };

  const isSocialAccountSwitchIRDisabled = () => {
    const isSocialCaseView = new RegExp(`/social-case/cases/[0-9]`);
    return isSocialCaseView.test(pathname);
  };

  const isSocialAccountSwitchCasesDisabled = () => {
    const isSocialCase = new RegExp(`/social-case/under-review/[0-9]`);
    return isSocialCase.test(pathname);
  };

  const isFolderDisabled = () => {
    const isFolderView = new RegExp(`/portfolio/[\\S\\s]+[\\S]+`);
    // return isFolderView.test(pathname) || new RegExp(`/portfolio+`).test(pathname);
    return isFolderView.test(pathname);
  };

  const isSocialAccountSwitchDisabled = () => {
    return isSocialAccountSwitchIRDisabled() || isSocialAccountSwitchCasesDisabled();
  };
  const handleCelebrityChange = (celebrityId: number | string) => {
    const newCelebrity = clients.find((celebrity) => celebrity.id === celebrityId);
    setCurrentCelebrity(newCelebrity!);
  };

  return (
    <>
      {is_enterprise && (
        <Dropdown
          onChange={handleCelebrityChange}
          value={currentCelebrity?.id}
          options={celebrietiesDropDownOptions}
          disabled={isAccountSwitchDisabled()}
        />
      )}
    </>
  );
};
