import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const DropdownContainer = styled.div`
  width: 180px;
  position: relative;

  @media screen and (min-width: 1440px) {
    width: 280px;
  }
`;

export const DropdownButton = styled.button<{ isOpen: boolean }>`
  overflow: hidden;
  position: relative;
  height: 45px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 12px;
  border: none;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  transition: all 0.15s;
  background-color: ${theme.colors.black};
  color: ${theme.colors.darkenWhite};

  &:enabled:hover {
    background-color: #121212;
    color: ${theme.colors.white};

    cursor: pointer;
  }

  &:disabled {
    background-color: ${theme.colors.black}+ '44';
    color: ${theme.colors.mediumGrey};
  }

  svg {
    transition: all 0.15s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  @media screen and (min-width: 1440px) {
    width: 280px;
  }
`;

export const Label = styled.span`
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
`;

export const OptionsContainer = styled.div<{
    isOpen: boolean;
    optionsPosition: 'top' | 'bottom';
}>`
  position: absolute;
  z-index: 5;
  left: 0;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  background-color: ${theme.colors.black};
  transition: all 0.15s;
  overflow-y: auto;
  max-height: 150px;

  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(10px)')};
  color: ${theme.colors.darkenWhite};

  ${({ optionsPosition }) => (optionsPosition === 'top' ? 'bottom: 50px;' : 'top: 50px;')}

  @media screen and (min-width: 1200px) {
    max-height: 300px;
  }

  header,
  footer {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 3px 0;
    padding: 7px 10px;
  }
`;

export const Option = styled.div.attrs({ tabindex: 1 })<{ isDisabled: boolean }>`
  border-radius: 12px;
  margin: 3px 0;
  padding: 7px 10px;
  height: 35px;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: all 0.15s;
  background-color: transparent;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  color: ${({ isDisabled }) =>
    isDisabled ? theme.colors.mediumGrey : theme.colors.darkenWhite};

  &:hover {
    background-color: ${({ isDisabled }) =>
    isDisabled ? 'transparent' : theme.colors.greySelected};
    color: ${({ isDisabled }) =>
    isDisabled ? theme.colors.mediumGrey : theme.colors.white};
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }

  &::after {
    display: ${({ isDisabled }) => (isDisabled ? 'inline' : 'none')};
    content: '•';
    position: relative;
    top: -7px;
    color: ${theme.colors.lemon};
  }
`;