import React from 'react';

import { Icon } from 'components';
import { Tooltip } from 'components';
import { Space } from 'style/spacing';
import { IconWrapper } from "./IconTooltip.styled";

interface IconTooltipProps extends Space {
  content: React.ReactNode;
  tooltipRight?: boolean;
  fixedWidth?: boolean;
  iconSize?: number;
}

export const IconTooltip: React.FC<IconTooltipProps> = ({
  content,
  tooltipRight = false,
  fixedWidth = false,
  iconSize=20,
  ...rest
}) => {
  const [isHover, setIsHover] = React.useState(false);

  const handleHover = (hoverState: boolean) => () => {
    setIsHover(hoverState);
  };

  return (
    <IconWrapper
      {...rest}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
    >
      <Icon style={{ cursor: 'pointer' }} width={iconSize} color={isHover ? '#fff' : '#999'} name="info" />
      {isHover && <Tooltip fixedWidth={fixedWidth} content={content} right={tooltipRight} />}
    </IconWrapper>
  );
};
