import styled from "styled-components";

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;
  text-align: center;
`;

export const MetricQuantity = styled.span`
  color: #fff;
  font-weight: 900;
  font-size: 3rem;
  margin-bottom: 3px;
  height: 40px;

  @media screen and (min-width: 576px) {
    font-size: 3.3rem;
    height: 50px;
    margin-bottom: 5px;
  }

  @media screen and (min-width: 3000px) {
    font-size: 4rem;
    height: 70px;
  }
`;

export const MetricLabel = styled.span`
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 17px;

  @media screen and (min-width: 576px) {
    margin-bottom: 27px;
  }

  @media screen and (min-width: 3000px) {
    font-size: 1.8rem;
  }
`;