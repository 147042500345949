import React, { useContext, createContext, useState } from 'react';

const RoutesBlockContext = createContext<IRoutesBlockContext | null>(null);

type IRoutesBlockContext = ReturnType<typeof useRoutesBlock>;

const useRoutesBlock = () => {
  const [isBlocking, setIsBlocking] = useState(false);

  return { isBlocking, setIsBlocking } as const;
};

const RoutesBlockContextProvider: React.FC = ({ children }) => {
  const value = useRoutesBlock();
  return (
    <RoutesBlockContext.Provider value={value}>{children}</RoutesBlockContext.Provider>
  );
};

const useRoutesBlockCtx = () => {
  const ctx = useContext(RoutesBlockContext);

  if (!ctx) {
    throw new Error('Use inside proper provider');
  }

  return ctx;
};

export { RoutesBlockContextProvider, useRoutesBlockCtx };
