import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HitTypeSwitch } from './HitTypeSwitch/HitTypeSwitch';
import { SwitchButton } from 'components/SwitchButton/SwitchButton';
import { useImageFilterCtx } from 'contexts/BlurImagesContext';
import { HamburgerButton } from './HamburgerButton';
import { MobileNavigation } from '../AppNavigation/MobileNavigation/MobileNavigation';
import { useMedia } from 'hooks/useMedia';
import { MobileToolbox } from 'components/MobileToolbox/MobileToolbox';
import { StateButton, StateButtonVariants } from 'components/StateButton/StateButton';
import { useHitTypeCtx } from 'contexts/HitTypeContext';
import { AppActions } from './AppActions';
import { CelebrityDropdown } from './CelebrityDropdown';
import { ButtonGroup, CelebrityDropdownContainer, Header, LogoContainer, StateBtnContainer } from "./AppHeader.styled";
import vaaamLogo from 'assets/images/VAAAM.png';
import { useCelebrity } from "services/contexts/CelebrityContext";


const AppHeader: React.FC = () => {
  const { currentCelebrity } = useCelebrity();
  const { currentHitType } = useHitTypeCtx();

  const isDesktop = !!useMedia(['(min-width: 1200px)'], [1], 0);

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const [isMobileToolboxOpen, setIsMobileToolboxOpen] = useState(false);

  const { blurImages, toggle } = useImageFilterCtx();

  const handleLinkClick = () => {
    setIsMobileNavOpen(false);
  };

  const variant: StateButtonVariants[] = [
    'COMMERCIAL',
    'EDITORIAL',
    'REPUTATIONAL',
    'DEFAULT',
  ];

  return (
    <>
      <Header>
        <LogoContainer>
          <Link to="/dashboard">
            <img src={vaaamLogo} alt="vaaam" />
          </Link>
        </LogoContainer>
        {!isDesktop && !isMobileNavOpen && (
          <StateBtnContainer>
            <StateButton
              variant={variant[currentHitType]}
              onClick={() => setIsMobileToolboxOpen((prev) => !prev)}
              isActive={false}
            >
              {currentCelebrity?.name}
            </StateButton>
          </StateBtnContainer>
        )}

        {isDesktop && (
          <>
          <HitTypeSwitch />

            <ButtonGroup>
              <SwitchButton
                checked={blurImages}
                label="Blur images"
                name="blurImage"
                id="blurImage"
                onChange={toggle}
                mr="20px"
              />

              <CelebrityDropdown />

              <AppActions />
            </ButtonGroup>
          </>
        )}
        {!isDesktop && !isMobileToolboxOpen && (
          <HamburgerButton
            ml="auto"
            isOpen={isMobileNavOpen}
            handleClick={() => setIsMobileNavOpen((prev) => !prev)}
          />
        )}
      </Header>
      <MobileNavigation isOpen={isMobileNavOpen} onLinkClick={handleLinkClick} />
      {!isDesktop && (
        <MobileToolbox
          isOpen={isMobileToolboxOpen}
          onCircleBtnClick={() => setIsMobileToolboxOpen((prev) => !prev)}
        >
          <AppActions />

          <HitTypeSwitch />

          <CelebrityDropdownContainer>
            <CelebrityDropdown />
            <SwitchButton
              checked={blurImages}
              label="Blur images"
              name="blurImage"
              id="blurImage"
              onChange={toggle}
              mr="20px"
              mb="10px"
            />
          </CelebrityDropdownContainer>
        </MobileToolbox>
      )}
    </>
  );
};

export default AppHeader;
