import styled from "styled-components";
import {theme} from "assets/styles/theme";
import {NavLink} from "react-router-dom";

export const Label = styled.span``;

export const Divider = styled.div`
  width: 70%;
  border-top: 1px solid ${theme.colors.mediumGrey};
  margin: 0 auto;
`;

export const StyledNavLink = styled(NavLink).attrs({ activeClassName: 'active-link' })`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  height: 80px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${theme.colors.mediumGrey};
  transition: all 0.15s;

  &:hover {
    background: ${theme.colors.black};
    color: ${theme.colors.white};

    svg {
      stroke: ${theme.colors.white}!important;
      fill: ${theme.colors.white}!important;
    }
  }

  ${Label} {
    margin-top: 15px;
  }

  &&.active-link {
    background: ${theme.colors.greySelected};
    color: ${theme.colors.white};
    svg {
      fill: ${theme.colors.white}!important;
      stroke: ${theme.colors.white}!important;
    }
  }

  @media screen and (min-width: 1440px) {
    height: 100px;
    font-size: 1.1rem;
  }

  @media screen and (min-width: 3000px) {
    height: 150px;
    font-size: 1.5rem;
  }
`;