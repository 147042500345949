import { PRIVATE_API } from 'services/config/api';
import { User } from 'services/contexts/AuthContext';
import { useMutation } from 'react-query';
import { publicHTTPClient } from 'services/config/publicHTTPClient';

export const useGetUserQuery = () => {
  const { mutateAsync } = useMutation((accessToken: string) => {
    return publicHTTPClient
      .get<User>(PRIVATE_API.USER_INFO, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((resp) => resp.data);
  });

  return { getUser: mutateAsync };
};
