import styled from "styled-components";

export const TextContainer = styled.p`
  align-items: center;
  display: flex;
`;

export const Text = styled.span`
  margin-left: 5px;
`;

export const InfoText = styled.p`
  margin-bottom: 4px;
`;

export const DarkTextContainer = styled.p`
  display: flex;
  padding-left: 5px;
`;

export const DarkText = styled.div`
  margin-left: 10px;
`;
