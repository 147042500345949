import React from 'react';
import {LevelIndicatorContainer} from "./LevelIndicator.styled";
import {colors, levelLabels, rotationDegrees} from "./const";


interface ArcProps {
  index: 0 | 1 | 2;
  level: 0 | 1 | 2 | 3;
  color: {
    active: string;
    inActive: string;
  };
}

const Arc: React.FC<ArcProps> = ({ index, level, color }) => {
  return (
    <circle
      cx="50"
      cy="50"
      r="45"
      stroke={level >= index + 1 ? color.active : color.inActive}
      strokeWidth="6"
      strokeLinecap="round"
      strokeDasharray="220"
      strokeDashoffset="173"
      fill="transparent"
      transform={`rotate(${rotationDegrees[index]} 50 50)`}
    />
  );
};

interface LevelIndicatorProps {
  variant: 'commercial' | 'reputational'; // todo in future should be enum
  level: 0 | 1 | 2 | 3;
  label: React.ReactElement;
  style?: React.CSSProperties;
}

export const LevelIndicator: React.FC<LevelIndicatorProps> = ({
  variant,
  level,
  label,
  style,
}) => {


  const currentColor = colors[variant];

  return (
    <LevelIndicatorContainer style={style}>
      <svg width="80%" height="80%" viewBox="0 0 100 100">
        <Arc color={currentColor} index={0} level={level} />
        <Arc color={currentColor} index={1} level={level} />
        <Arc color={currentColor} index={2} level={level} />
        <g fontSize="14">
          {label}
          <text
            fill={currentColor.active}
            fontWeight="bolder"
            y="50%"
            transform="translate(0, 45)"
            fontSize="10"
          >
            <tspan x="50%" textAnchor="middle">
              {levelLabels[level]}
            </tspan>
          </text>
        </g>
      </svg>
    </LevelIndicatorContainer>
  );
};

interface MetricLabelProps {
  label: string[] | string;
}

export const MetricLabel: React.FC<MetricLabelProps> = ({ label }) => {
  const commonProps = {
    fill: '#fff',
    fontSize: 7,
    y: '50%',
  };

  if (Array.isArray(label)) {
    return (
      <>
        {label.map((phrase, index) => {
          return (
            <text
              key={`text-${index}`}
              transform={`translate(0, ${-6 + index * 9})`}
              {...commonProps}
            >
              <tspan x="50%" textAnchor="middle">
                {phrase}
              </tspan>
            </text>
          );
        })}
      </>
    );
  }

  return (
    <>
      <text {...commonProps} transform={`translate(0, -6)`}>
        <tspan x="50%" textAnchor="middle">
          {label}
        </tspan>
      </text>
    </>
  );
};
