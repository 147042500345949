import React from 'react';
import ReactModal from 'react-modal';

import { theme } from 'assets/styles/theme';
import { CloseButton, ModalHeader } from "./Modal.styled";
import { useMedia } from "hooks/useMedia";

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const getReactModalStyles = (isOpen: boolean, isMobile: boolean, minHeight?: number): ReactModal.Styles => ({
  content: {
    top: isMobile ? '10vh' : '50%',
    left: '50%',
    transform: isMobile ? 'translate(-50%, 0)' : 'translate(-50%, -50%)',
    borderRadius: '6px',
    maxWidth: isMobile ? '90vw' : '60vw',
    minWidth: isMobile ? '60vw' : undefined,
    minHeight: minHeight? `${minHeight}vh`: '40vh',
    maxHeight: '95vh',
    width: 'fit-content',
    height: 'fit-content',
    padding: isMobile ? '20px' : '40px',
    mixBlendMode: 'normal',
    background: theme.colors.darkBoxes,
    border: 'none',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  overlay: {
    transition: 'all 0.3s',
    opacity: isOpen ? 1 : 0,
    zIndex: 20000,
    backgroundColor: '#1e1d1d99',
  },
});

interface ModalProps {
  children: JSX.Element | null | React.ReactNode;
  isOpen: boolean;
  headerTitle?: React.ReactNode;
  onCloseClick: () => void;
  minHeight?: number;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  headerTitle,
  onCloseClick,
  minHeight
}) => {
  const isMobile = !!useMedia(['(max-width: 992px)'], [1], 0);

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      onRequestClose={onCloseClick}
      style={getReactModalStyles(isOpen, isMobile, minHeight)}
    >
      <>
        <ModalHeader>
          {headerTitle}
          <CloseButton onClick={onCloseClick}>&times;</CloseButton>
        </ModalHeader>
        {children}
      </>
    </ReactModal>
  );
};
