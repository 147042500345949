import styled from "styled-components";
import {Variant} from "utils/constants";
import {theme} from "assets/styles/theme";

export const StyledToggleButtonContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
`;

interface StyledToggleButtonProps {
    isFirst: boolean;
    isLast: boolean;
    isActive: boolean;
    variant: Variant;
}

export const StyledToggleButton = styled.button<StyledToggleButtonProps>`
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: ${({ variant }) => (variant === Variant.WHITE ? `${theme.colors.white} 2px solid` : `${theme.colors.black} 2px solid`)};
  color: ${({variant}) => `${(variant === Variant.WHITE ? theme.colors.white : theme.colors.black)} 2px solid`};
  border-radius: ${({ isFirst, isLast }) =>
    isFirst ? '25px 0 0 25px' : isLast ? '0 25px 25px 0' : '0 0 0 0'};
  cursor: pointer;
  min-width: 110px;
  padding: 8px 17px;
  transition: all 0.15s;
  background-color: ${({ isActive, variant }) => (isActive ? (variant === Variant.WHITE ? theme.colors.white: theme.colors.black) : 'transparent')};
  color: ${({ isActive, variant }) => variant === Variant.WHITE ? (isActive ? theme.colors.black : theme.colors.mediumGrey) :
          (isActive ? theme.colors.darkenWhite : theme.colors.mediumGrey)};

  &:hover {
    color: ${({ isActive, variant }) => variant === Variant.WHITE ? (isActive ? theme.colors.black : theme.colors.white) :
            (isActive ? theme.colors.darkenWhite : theme.colors.white)};
    background-color: ${({ isActive, variant }) => variant === Variant.WHITE ? (isActive ? theme.colors.white : theme.colors.black) :
            (isActive ? theme.colors.black : theme.colors.darkBoxes)};
  }

  & + & {
    border-left: none;
  }

  @media screen and (min-width: 768px) {
    font-weight: 600;
    letter-spacing: 2px;
    height: 45px;
    min-width: 150px;
    padding: 11px 23px;
  }
`;