import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; // 20
  text-transform: uppercase;
  font-size: 1.6rem;
  color: white;
  font-weight: 600;
  letter-spacing: 0.8px;
`;

export const CloseButton = styled.button`
  font-size: 2.4rem;
  font-weight: bold;
  background-color: transparent;
  border: none;
  color: ${theme.colors.darkenWhite};
  cursor: pointer;
  margin-bottom: auto;

  &:hover {
    color: ${theme.colors.white};
  }
`;