import styled from "styled-components";
import { theme } from "assets/styles/theme";
import { NavLink } from "react-router-dom";

export interface MobileNavigationContainerProps {
    isOpen: boolean;
}

export const MobileNavigationContainer = styled.nav<MobileNavigationContainerProps>`
  position: fixed;
  left: 0;
  right: 0;
  top: 73px; // height of app header
  min-height: calc(100vh - 73px);
  background-color: ${theme.colors.darkBoxes};
  transition: all 0.2s ease-in-out;
  z-index: 1003;
  // allow scroll in mobile to view all menu items
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0.6)};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
`;

export const Backdrop = styled.div<MobileNavigationContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  background-color: #00000088;
  transition: all 0.2s;

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

export const StyledNavLink = styled(NavLink).attrs({ activeClassName: 'active-link' })`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  text-decoration: none;
  color: ${theme.colors.darkenWhite};
  height: 11vh;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 7px;

  @media screen and (min-width: 321px) {
    margin-bottom: 10px;
    font-size: 1.3rem;
  }

  &.active-link {
    color: ${theme.colors.white};
    background: ${theme.colors.greySelected};

    svg {
      fill: ${theme.colors.white};
      stroke: ${theme.colors.white};
    }
  }

  span {
    margin-top: 7px;

    @media screen and (min-width: 321px) {
      margin-top: 10px;
    }
  }
`;