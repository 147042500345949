import React, { createContext, useContext } from 'react';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useAuth } from '../services/contexts/AuthContext';
import { getApiUrl } from 'services/config/baseURL';
import { useCelebrity } from "services/contexts/CelebrityContext";

type AxiosMutation = <T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => Promise<R>;

type AxiosQuery = <T = any, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig
) => Promise<R>;

export interface ContextProps {
  get: AxiosQuery;
  post: AxiosMutation;
  patch: AxiosMutation;
  del: AxiosMutation;
  put: AxiosMutation;
}

export const FetchContext = createContext<ContextProps | null>(null);

const createAuthAxios = (access: string) => {
  const baseURL = getApiUrl();
  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const useFetch = () => {
  const { authState, isAccessTokenExpired, getNewToken } = useAuth();
  const { currentCelebrity } = useCelebrity();

  const authAxios = createAuthAxios(authState.access as string);

  const getNewAuthorizationHeader = async () => {
    const newAccessToken = await getNewToken();
    return `Bearer ${newAccessToken as string}`;
  };

  const getConfigWithNewToken = async (config: AxiosRequestConfig) => {
    if (isAccessTokenExpired()) {
      const newHeader = await getNewAuthorizationHeader();
      config.headers.Authorization = newHeader;
    }

    return config;
  };

  const getConfigWithClientId = (
    config: AxiosRequestConfig,
    clientId?: string | number
  ) => {
    config.params = { ...config.params, client_id: clientId };

    return config;
  };

  authAxios.interceptors.request.use(
    async (config) => {
      const configWithToken = await getConfigWithNewToken(config);
      const configWithQueryParam = getConfigWithClientId(
        configWithToken,
        currentCelebrity?.id
      );

      return configWithQueryParam;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  const interceptor = authAxios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      const { response } = error;

      if (response && response.status === 401) {
        axios.interceptors.response.eject(interceptor);

        const newHeader = await getNewAuthorizationHeader();

        response.config.headers['Authorization'] = newHeader;
        authAxios.defaults.headers['Authorization'] = newHeader;

        return axios(response.config);
      }

      return Promise.reject(error);
    }
  );

  const { get, post, patch, delete: del, put } = authAxios;

  return { get, post, patch, del, put };
};

const FetchProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const value = useFetch();

  return <FetchContext.Provider value={value}>{children}</FetchContext.Provider>;
};

const useFetchCtx = () => {
  const ctx = useContext(FetchContext);

  if (!ctx) {
    throw new Error('Use fetch ctx inside fetch provider');
  }

  return ctx;
};

export { FetchProvider, useFetchCtx };
