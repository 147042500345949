import React from 'react';
import { Icon, IconName } from 'components';
import { Space } from 'style/spacing';
import { Ripple } from '../Ripple/Ripple';
import { ButtonVariants, StyledButton, styles } from "./Button.styled";


interface ButtonProps extends Space {
  disabled?: boolean;
  variant?: ButtonVariants;
  leftIcon?: IconName;
  rightIcon?: IconName;
  isBorderHidden?: boolean;
  type?: 'submit' | 'button' | 'reset';
  size?: 'sm' | 'md';
  block?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button: React.FC<ButtonProps> = ({
  disabled = false,
  block = false,
  isBorderHidden = false,
  variant = 'LEMON',
  type = 'button',
  size = 'md',
  children,
  leftIcon,
  rightIcon,
  onClick,
  ...rest
}) => {
  const isIconButton = !children;

  return (
    <StyledButton
      block={block}
      isIconButton={isIconButton}
      size={size}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      type={type}
      isBorderHidden={isBorderHidden}
      {...rest}
    >
      {!!leftIcon && (
        <Icon
          name={size === 'sm' ? `sm_${leftIcon}`: leftIcon}
          changeColorOnParentHover
          hoverColor={styles[variant].hoverColor}
          color={styles[variant].color}
          disabledColor={styles[variant].disabledColor}
        />
      )}
      {!!children && <span>{children}</span>}
      {!!rightIcon && (
        <Icon
          name={rightIcon}
          changeColorOnParentHover
          hoverColor={styles[variant].hoverColor}
          color={styles[variant].color}
          disabledColor={styles[variant].disabledColor}
        />
      )}
      {/* TODO: for the future it could be a part of config */}
      {!disabled && <Ripple opacity={variant === 'DARK' ? 50 : 20} />}
    </StyledButton>
  );
};
