import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Btn = styled.button`
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 1.4rem;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const LemonBtn = styled(Btn)`
  color: ${theme.colors.lemon};

  &:hover {
    color: ${theme.colors.darkenLemon};
  }
`;

const WhiteBtn = styled(Btn)`
  color: ${theme.colors.white};

  &:hover {
    color: ${theme.colors.darkenWhite};
  }
`;

const ForgotPasswordBtn = styled(LemonBtn)`
  margin: 15px 0 0 auto;
  display: block;
`;

const Heading = styled.h2`
  width: 370px;
  color: ${theme.colors.darkenWhite};
  margin: 40px 0 10px 0;
`;

export { FormContainer, StyledForm, Btn, LemonBtn, WhiteBtn, ForgotPasswordBtn, Heading };
