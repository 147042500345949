import { getNavigationConfig } from '../navigation-config';
import { useCelebrity } from "services/contexts/CelebrityContext";
import { NavItem } from "../NavItem/NavItem";
import { useDashboard } from "../../../pages/Dashboard/hooks/useDashboard";
import { useAuth } from "services/contexts/AuthContext";

export const DesktopNavigation = () => {
  const { currentCelebrity } = useCelebrity();
  const { user } = useAuth();
  const { role = null, is_monitoring = false } = user;
  const { slug } = role || {};

  const celebrityId = Number(currentCelebrity?.id) || 0;

  const { isLoading } = useDashboard();

  if (isLoading) {
    return null;
  }

  return (
    <nav>
      <ul>
        {getNavigationConfig(celebrityId, slug, is_monitoring).map(({ icon, label, path, isActive }) => (
          <NavItem
            path={path}
            label={label}
            icon={icon}
            key={label + icon}
            isActive={isActive}
          />
        ))}
      </ul>
    </nav>
  );
};
