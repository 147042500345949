import styled from "styled-components";

export const InnerContainer = styled.div`
  color: #9A9A9A;
  font-size: 30px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OuterContainer = styled.div`
  background: #1C1C1C;
  box-sizing: border-box;
  height: 100vh;
  margin: 0;
  width: 100%;
`;

export const NoPageTitle = styled.p`
  margin: 4vh 0 2vh 0;
`

export const WrongPath = styled.p`
  margin: 2vh 0 4vh 0;
`