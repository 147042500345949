import { useHitTypeCtx } from 'contexts/HitTypeContext';

import { DashboardResponse } from 'types';
import { useDashboardQuery } from 'pages/Dashboard/services/useDashboardQuery';
import { useMapQuery } from 'pages/Dashboard/services/useMapQuery';
import { useCelebrity } from "services/contexts/CelebrityContext";

const getInitialState = (): DashboardResponse => {
  return {
    score: 0,
    sources: [],
    top_hits: [],
    threats: {
      commercial_findings: 0,
      commercial_findings_total: 0,
      commercial_level: 0,
      reputational_findings: 0,
      reputational_findings_total: 0,
      reputational_level: 0,
    },
    last_visited: null,
    stats: {
      images: 0,
      total_hits: 0,
      total_hits_monitoring: 0,
      total_domains: 0,
      total_domains_monitoring: 0,
      total_under_review: 0,
      total_cases: 0,
      legal_action_pending: 0,
      legal_action_negotiating: 0,
      legal_action_proceedings: 0,
      legal_action_success: 0,
      legal_action_closed: 0,
      social_findings: 0
    },
    damage_value: {
      dmg_average: 0,
      dmg_median: 0,
      lower_end: 0,
      high_end: 0
    }
  };
};

export const useDashboard = () => {
  const { currentCelebrity } = useCelebrity();
  const { currentHitType } = useHitTypeCtx();

  const { dashboardState, isLoading, refetch } = useDashboardQuery(
    currentCelebrity,
    currentHitType
  );

  const { mapStateToDisplay = [] } = useMapQuery(currentCelebrity, currentHitType);

  const { sources, monitoring_sources, threats, top_hits, monitoring_top_hits, last_visited, stats, damage_value } =
    dashboardState || getInitialState();
  // We want these stats to be in a certain order instead of whatever order we get from backend,
  // so we order them up here
  const {
    images, total_hits, total_hits_monitoring, total_domains, total_domains_monitoring, total_under_review, total_cases, legal_action_pending,
    legal_action_negotiating, legal_action_proceedings, legal_action_success, social_findings, legal_action_closed,
  } = stats;
  const orderStats = {
    images, total_hits, total_hits_monitoring, total_domains, total_domains_monitoring, total_under_review, total_cases, legal_action_pending,
    legal_action_negotiating, legal_action_proceedings, legal_action_success, social_findings, legal_action_closed,
  };

  return {
    isLoading,
    sources,
    monitoring_sources,
    threats,
    refetch,
    topHitsToDisplay: top_hits,
    monitoring_top_hits,
    mapStateToDisplay,
    currentHitType,
    last_visited,
    stats: orderStats,
    damage_value
  } as const;
};
