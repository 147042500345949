/**
 * @description context provider used for portfolio pages (somewhat in curated findings)
 * Tags, selection, pagination, cards data
 */
import React, {createContext, useContext, useState} from 'react';
import {ERROR_MESSAGE} from "utils/constants";

interface IPortfolioContext {
    isSelectAll: boolean;
    setIsSelectAll: (val: boolean) => void;
    selectedHits: number[]
    setSelectedHits: (val: any) => void;
    isTagChanged: boolean;
    setIsTagChanged: (val: boolean) => void;
}

const INITIAL_CTX_STATE: IPortfolioContext = {
    isSelectAll: false,
    setIsSelectAll: () => ({}),
    selectedHits: [],
    setSelectedHits: () => ({}),
    isTagChanged: false,
    setIsTagChanged: () => ({}),
};

const PortfolioContext = createContext<IPortfolioContext>(INITIAL_CTX_STATE);

export const usePortfolioCtx = () => {
    const ctx = useContext(PortfolioContext);

    if (!ctx) {
        throw new Error(ERROR_MESSAGE);
    }

    return ctx;
};

export const PortfolioContextProvider: React.FC = ({children}) => {
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [selectedHits, setSelectedHits] = useState<number[]>([]);
    const [isTagChanged, setIsTagChanged] = useState(false);

    const value = {
        selectedHits,
        setSelectedHits,
        isSelectAll,
        setIsSelectAll,
        isTagChanged,
        setIsTagChanged,
    };

    return (<PortfolioContext.Provider value={value}>{children}</PortfolioContext.Provider>);
};

