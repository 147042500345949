import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

const PageContainer = styled.div`
  background: ${theme.colors.darkBoxes};
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  margin-top: 10vh;
  align-items: center;
  flex-direction: column;
  transform: scale(0.8);

  @media screen and (min-width: 321px) {
    transform: scale(1); // TODO: REMOVE THAT AND IMPROVE. TEMPORARY.
  }

  @media screen and (min-width: 980px) {
    justify-content: center;
    margin-top: 0;

    width: 50vw;
  }

  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export { PageContainer, FormContainer };
