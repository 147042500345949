import { theme } from 'assets/styles/theme';
import React, { useCallback, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import { Icon } from 'components';
import { Space } from 'style/spacing';
import { DropZone, HoverLayer, Label, Placeholder, UploadedFile } from "./ImageUploadDropZone.styled";


interface ImageUploadDropZoneProps extends Space {
  label?: string;
  initialSrc?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ImageUploadDropZone = React.forwardRef<
  HTMLInputElement,
  ImageUploadDropZoneProps
>(({ initialSrc, label, disabled = false, onChange, ...rest }, ref) => {
  const [previewSrc, setPreviewSrc] = React.useState<string>('');

  useEffect(() => {
    if (!!initialSrc) {
      setPreviewSrc(initialSrc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = useCallback(([acceptedFile]) => {
    if (!!acceptedFile) {
      const temp = URL.createObjectURL(acceptedFile);
      setPreviewSrc(temp);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: 'image/*',
  });

  return (
    <DropZone
      isDisabled={disabled}
      {...getRootProps({ isUploaded: !!previewSrc, ...rest })}
    >
      <input
        {...getInputProps({
          onChange,
          ref,
          disabled,
        })}
      />
      {!!label && !previewSrc && (
        <Placeholder>
          <Label>{label}</Label>
          <Icon name="user" color="inherit" />
        </Placeholder>
      )}

      {!!previewSrc && (
        <UploadedFile
          src={previewSrc}
          alt="YOUR UPLOADED FILE"
        />
      )}

      {!!previewSrc && (
        <HoverLayer>
          <Icon name="edit" color={theme.colors.white} />
        </HoverLayer>
      )}
    </DropZone>
  );
});
