import { useMutation } from 'react-query';
import { PUBLIC_API } from 'services/config/api';
import { publicHTTPClient } from 'services/config/publicHTTPClient';
import { showErrorToast, showInfoToast } from 'utils/toast/toast';
import { ERROR_MESSAGE } from "utils/constants";

export const useChangePasswordMutation = () => {
  const { mutateAsync: changePassword } = useMutation(
    (payload: { email: string; password: string; token: string }) =>
      publicHTTPClient
        .patch(PUBLIC_API.CHANGE_PASSWORD, payload)
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        showInfoToast('You have reset password successfully, check your email inbox!');
      },
      onError: () => {
        showErrorToast(ERROR_MESSAGE);
      },
    }
  );

  return {
    changePassword,
  };
};
