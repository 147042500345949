import { useMutation } from 'react-query';
import { PUBLIC_API } from 'services/config/api';
import { publicHTTPClient } from 'services/config/publicHTTPClient';
import { showErrorToast } from 'utils/toast/toast';
import {ERROR_MESSAGE, getCookie} from "utils/constants";

export const useSignUpMutation = () => {
  /**
   * CSRF token they are going to be included in future version
   */

  // function getCookie(name: string) {
  //   let cookieValue = null;
  //   if (document.cookie && document.cookie !== '') {
  //     const cookies = document.cookie.split(';');
  //     for (let i = 0; i < cookies.length; i++) {
  //       const cookie = cookies[i].trim();
  //       // Does this cookie string begin with the name we want?
  //       if (cookie.substring(0, name.length + 1) === name + '=') {
  //         cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
  //         break;
  //       }
  //     }
  //   }
  //   return cookieValue;
  // }
  // const csrftoken = getCookie('csrftoken');

  // console.log(csrftoken);

  const signUpRequest = (credentials: { email: string; password: string }) =>
    publicHTTPClient.post(PUBLIC_API.AUTH_SIGN_UP, credentials, {
      headers: {
        'X-CSRFToken': getCookie('csrftoken')
      },
    }).then(({ data }) => {
      return data;
    });

  const { mutateAsync: signUp, isLoading } = useMutation(signUpRequest, {
    onError: () => showErrorToast(ERROR_MESSAGE),
  });

  return {
    signUp,
    isLoading,
  };
};
