import React from 'react';
import { DEFAULT_ERROR_MSG } from '../../consts';
import { toast, ToastOptions } from 'react-toastify';
import { Icon } from 'components';
import { DarkText, DarkTextContainer, InfoText, Text, TextContainer } from "./toast.styled";
import { errorConfig, infoConfig } from "./const";

export const showErrorToast = (msg: string = DEFAULT_ERROR_MSG): void => {
  toast.dark(
    <TextContainer>
      <Icon name="times" color="red" />
      <Text>{msg}</Text>
    </TextContainer>,
    errorConfig
  );
};

export const showInfoToast = (
  msg: string | string[] = DEFAULT_ERROR_MSG,
  config?: ToastOptions | undefined
): void => {
  let content: React.ReactNode = msg;

  if (msg && Array.isArray(msg)) {
    content = msg.map((msg, idx) => (
      <InfoText key={`toast-${idx}`}>
        {msg}
      </InfoText>
    ));
  }

  toast.dark(
    <DarkTextContainer>
      <Icon name="info" color="green" />
      <DarkText>{content}</DarkText>
    </DarkTextContainer>,
    { ...infoConfig, ...config }
  );
};