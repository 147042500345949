import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const EmailSent = styled.p`
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  margin: 30px 0 10px 0;
  line-height: 1.4;
`;

export const EmailAgain = styled.p`
  font-size: 1.4rem;
  color: ${theme.colors.mediumGrey};
  margin: 10px 0 30px 0;
`;