import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const LogoContainer = styled.div`
  height: 80px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    width: 100px;
  }
`;

export const StateBtnContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  
`;

export const Header = styled.header`
  position: fixed;
  z-index: 10000; // so high because elements of the map have higher
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${theme.colors.backgroundBlack};
  display: flex;
  align-items: center;
  height: 73px;
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid ${theme.colors.mediumGrey + '77'};

  @media screen and (min-width: 768px) {
    border-bottom: none;
  }

  @media screen and (min-width: 3000px) {
    height: 100px;
  }
`;

export const ButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  div + button {
    margin-left: 15px;
  }
`;

export const CelebrityDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  margin-top: 40px;
`;