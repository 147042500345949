import { theme } from 'assets/styles/theme';
import { useHitTypeCtx } from 'contexts/HitTypeContext';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import AppHeader from './components/AppHeader/AppHeader';
import Sidebar from './components/AppSidebar/AppSidebar';

const Main = styled.main<{ bgColor: string }>`
  min-height: calc(100vh - 73px); // WARN: could be a problem with mobile device
  background: ${({ bgColor }) => bgColor};
  margin-top: 73px;
  padding: 7px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #88888844;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffffff55;
    height: 10px;
  }

  @media screen and (min-width: 1200px) {
    padding: 15px;
    margin-left: 100px;
  }

  @media screen and (min-width: 3000px) {
    padding: 20px;
    margin-left: 200px;
    min-height: calc(100vh - 100px);
    margin-top: 100px;
  }
`;

const BG_COLORS = [
  `linear-gradient(250.82deg, #43C19B -51%, ${theme.colors.backgroundBlack}, #43C19B 210%)`,
  `linear-gradient(250.98deg, #2c77d3 -55%, ${theme.colors.backgroundBlack}, #2c77d3 210%)`,
  `linear-gradient(220.05deg, #8E59FF -54%, ${theme.colors.backgroundBlack}, #8E59FF 168%)`,
  `${theme.colors.backgroundBlack}`,
  `${theme.colors.backgroundBlack}`,
];

const AppMain: React.FC = ({ children }) => {
  const { currentHitType } = useHitTypeCtx();

  return <Main bgColor={BG_COLORS[currentHitType]}>{children}</Main>;
};

interface AppShellProps {}

const AppShell: React.FC<PropsWithChildren<AppShellProps>> = ({ children }) => {
  return (
    <>
      <AppHeader />
      <Sidebar />
      <AppMain>{children}</AppMain>
    </>
  );
};

export default React.memo(AppShell);
