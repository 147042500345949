import { DropDownConfig } from '../components/Dropdown/Dropdown';

/**
 * UTILS
 */
export type Palette<TVariants extends string, TStyles> = {
  [variant in TVariants]: TStyles;
}; // or TS Record

/**
 * COMMON
 */

export enum TagType {
  TYPE_1,
  TYPE_2,
  TYPE_3,
}

export enum CaseStatus {
  OPEN,
  NO_ACTION,
  ON_HOLD,
  TAKE_ACTION,
}

export enum IconCaseStatus {
  NO_ACTION,
  ON_HOLD,
  TAKE_ACTION,
}

export enum HitStatus {
  ON_HOLD,
  NO_ACTION,
  REQUEST_LEGAL_ASSESSMENT,
  REQUEST_TAKEDOWN,
  CLAIM_DAMAGES,
  REQUEST_INFORMATION,
  LEGAL_WARNING,
  POST_LICENSE,
  TAKE_ACTION,
  APPROVED,
  REJECTED,
  REQUEST_CURATION,
}

export type TCaseCurrentActions =
  | 'FIRST_NOTICE_SENT'
  | 'SECOND_REMINDER_SENT'
  | 'FIRST_REMINDER_SENT'
  | 'ENTERED_NEGOTIATION'
  | 'ENTERED_LEGAL_PROCEEDINGS'
  | 'CASE_WON'
  | 'TAKEDOWN_SUCCESSFUL'
  | 'CLOSED';

export enum UserRole {
  // DEFAULT = null,
  PERSONALITY = 1,
  COPYRIGHT = 2,
  FREEMIUM = 3,
}

export enum UserRoleSlug {
  // DEFAULT = null,
  PERSONALITY = 'personality',
  COPYRIGHT = 'copyright',
}

export enum Classification {
  COMMERCIAL,
  EDITORIAL,
  VICE,
}

export enum Action {
  DEAL,
  LEGAL_TAKEDOWN_WITH_A_DAMAGE_CLAIM,
  LEGAL_TAKEDOWN,
  REQUEST_INFORMATION,
  LEGAL_WARNING,
  POST_LICENSE,
}

export const Action_CurationRequested = 11;

export enum caseStatuses {
  OPEN,
  IN_PROGRESS,
  CLOSED,
  FIRST_NOTICE_SENT,
  FIRST_REMINDER_SENT,
  SECOND_REMINDER_SENT,
  CONTENT_REMOVED,
  INFORMATION_REQUESTED,
  CASE_WON,
  ENTERED_NEGOTIATIONS,
  TRANSFERRED_TO_LAWYER,
  ENTERED_LEGAL_PROCEEDINGS,
  TAKE_DOWN_SUCCESSFUL,
}

export interface IUserSettings {
  name: string;
  email: string;
  phone: string;
  street: string;
  streetNumber: string;
  city: string;
  zipCode: string;
  avatar: string;
  linkedSocials: SocialAccount[];
}

export interface ITemplateSettings {
  name: string;
  email: string;
  companyName: string;
  attentionOf: string;
  phone: string;
  street: string;
  streetNumber: string;
  city: string;
  zipCode: number;
  country: string;
  subject: string;
  description: string;
  legal_action_type: DropDownConfig;
}

export interface ITag {
  id: number;
  name: string;
  tag_type: TagType;
}

export interface HitSource {
  id: number;
  domain: string;
  hits: number;
  country: string;
  is_high_commercial: boolean;
  is_high_reputational: boolean;
}

export type TActionType = 'archive' | 'dismiss' | 'under-review' | 'confirm';

export enum EActionTypes {
  ARCHIVE = 'archive',
  DISMISS = 'dismiss',
  UNDER_REVIEW = 'under-review',
  CONFIRM = 'confirm',
}

export enum EActionNotify {
  TRUE = '1',
  FALSE = '0',
}

export interface Hit {
  id: number;
  hits: number;
  image: string;
  image_thumbnail: string;
  is_high_commercial: boolean;
  is_high_reputational: boolean;
  sources: HitSource[];
  tags?: ITag[];
  metadata?: object;
}

export interface Celebrity {
  id: number | string;
  name: string;
}

export interface Role {
  id: number | string;
  name: string;
  slug: string;
}

export interface MonitoringHit {
  id: number;
  classification: Classification;
  clientId: number;
  country: string | null;
  country_code: string | null;
  domain: string;
  url: string;
  timestamp: string;
  image_page_url: string;
  image_source_url: string;
  image_title: string;
}

export interface SocialAccount {
  id: string;
  src: string;
}

/**
 * DASHBOARD
 */

type ThreatLvel = 0 | 1 | 2 | 3;

export interface Threats {
  reputational_level: ThreatLvel;
  reputational_findings: number;
  reputational_findings_total: number;
  commercial_level: ThreatLvel;
  commercial_findings: number;
  commercial_findings_total: number;
}

export interface DashboardStatsResponse {
  images: number;
  total_hits: number;
  total_hits_monitoring: number;
  total_domains: number;
  total_domains_monitoring: number;
  total_under_review: number;
  total_cases: number;
  legal_action_pending: number;
  legal_action_negotiating: number;
  legal_action_proceedings: number;
  legal_action_closed: number;
  legal_action_success: number;
  social_findings: number;
}

export interface DashboardStatsResponseNonMonitoring {
  images: number;
  total_hits: number;
  total_domains: number;
  total_under_review: number;
  total_cases: number;
  legal_action_pending: number;
  legal_action_negotiating: number;
  legal_action_proceedings: number;
  legal_action_closed: number;
  legal_action_success: number;
  social_findings: number;
}

export interface DashboardStatsResponseMonitoring {
  images: number;
  total_hits_monitoring: number;
  total_domains_monitoring: number;
  total_under_review: number;
  total_cases: number;
  legal_action_pending: number;
  legal_action_negotiating: number;
  legal_action_proceedings: number;
  legal_action_closed: number;
  legal_action_success: number;
  social_findings: number;
}

export interface DamageValue {
  dmg_average: number;
  dmg_median: number;
  lower_end: number;
  high_end: number;
}

export interface FindingLocation {
  lat: number;
  lon: number;
  classification: number;
  size: number;
  is_monitoring: boolean;
}

export interface DashboardResponse {
  sources: HitSource[];
  monitoring_sources?: HitSource[];
  top_hits: Hit[];
  monitoring_top_hits?: Hit[];
  score: number;
  threats: Threats;
  last_visited: CuratedFindingResponse | null;
  stats: DashboardStatsResponse;
  damage_value: DamageValue;
}

export type MapResponse = FindingLocation[];

/**
 * CURATED FINDINGS
 */

export interface CuratedFindingsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Hit[];
  score: number;
}

/**
 * CASES
 */

export interface Case {
  id: number;
  country: string;
  domain: string;
  classification: Classification;
  action: Action;
  current_status: TCaseCurrentActions;
  image_amount: number;
  creation_date: string;
  last_modified_date: string;
  count: number;
  is_high_commercial: boolean;
  is_high_reputational: boolean;
  request_legal_assessment: boolean | null;
  signable_docs: CaseSignAbleDocs[];
}

export interface CaseSignAbleDocs {
  case_id: number | null;
  document_type: string;
  document_name: string | null;
  document_url: string | null;
  public_link: string | null;
  is_signed: boolean;
}

export interface CaseResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Case[];
  score: number | null;
}

export interface CaseHit {
  id: number;
  status: null;
  vendor_id: null;
  ryde_id: null;
  url: string;
  image_thumbnail: string;
  file_name: string;
  created: string;
  soo_agency_pdf: string | null;
  soo_photographer_pdf: null;
  metadata: Record<string, string>;
  is_high_commercial: false;
  is_high_reputational: false;
  hit_page_urls: string[];
  default_license_fee: number;
  target_license_fee: number | null;
}

export interface CaseItem {
  action: Action | null;
  classification: Classification;
  client_name: string;
  country: Country | null;
  creation_date: string;
  domain: string;
  export: boolean | null;
  id: number;
  infringer: { name: string; state: string | null };
  is_confirmed: boolean;
  last_modified_date: string;
  quality: number;
  request_legal_assessment: boolean | null;
  send_to_lawyer: boolean | null;
  state: number;
  user_email: string;
  export_pdf: string | null;
  export_pdf_pending: boolean | null;
}

export interface HitV2 {
  id: number;
  image: string;
  folder: number;
  image_thumbnail: string;
  created: string;
  vendor_id: string | null;
  ryde_id: string | null;
  filename: string | null;
  is_high_commercial: boolean;
  is_high_reputational: boolean;
  sources: HitV2Sources[];
  tags: HitV2Tags[];
}

export interface HitV2Tags {
  id: number;
  name: string;
  tag_type: number;
}

export interface HitV2Sources {
  id: number;
  domain: string;
  state: number;
  country: string;
  infringer_name: string;
  hits: number;
  is_high_commercial: boolean;
  is_high_reputational: boolean;
}

export interface CasesSearchParams {
  search?: string;
  current_status?: string;
  state?: string;
  action?: string;
  classification?: string;
  created__gte?: string;
  created__lte?: string;
  updated__gte?: string;
  updated__lte?: string;
}

/**
 * PORTFOLIO
 */

export interface CustomerHit {
  hits: number;
  image: string;
  image_thumbnail: string;
  id: number;
}

export interface IPortfolioImage {
  created: string;
  default_license_fee: number;
  filename: string;
  folder: number;
  folder_name: string;
  id: number;
  image: string;
  image_thumbnail: string;
  metadata: Record<string, unknown> | null;
  ryde_id: string | null;
  tags: ImageTag[];
  target_license_fee: number | null;
  vendor_id: string | null;
}

export interface ImageTag {
  id: number;
  name: string;
}

export interface PortfolioFolder {
  id: number;
  name: string;
  client: number;
}

/**
 * MONITORING
 */

export interface MonitoringResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: MonitoringHit[];
}

/**
 * CASE OVERVIEW
 */

export interface PreCaseHit {
  id: number;
  screenshot: string;
  status: number | null;
  url: string;
  is_high_commercial?: boolean;
  is_high_reputational?: boolean;
  reference_image: ReferenceImage;
}

export interface Host {
  ip_address: string | null;
  name: string | null;
}

export interface Infringer {
  address: string | null;
  company_registration_number: string | null;
  email: string | null;
  fax: string | null;
  name: string;
  phone: string | null;
  zip_code: string | null;
  state: string | null;
}

interface Country {
  code: string;
  name: string;
}

export interface IFinding {
  id: number;
  created: string;
  folder: number;
  image: string;
  image_thumbnail: string;
  is_high_commercial: boolean;
  is_high_reputational: boolean;
  metadata?: object;
  sources: HitSource[];
}

export interface IHitImage {
  id: number;
  url: string;
  created: string;
  client_image: number;
}

export interface IReferenceImage {
  id: number;
  created: string;
  folder: number;
  image: string;
  image_thumbnail: string;
  metadata?: object;
  sources: HitSource[];
  filename: string;
  ryde_id: string;
  vendor_id: string;
}

export interface CuratedFindingResponse {
  id: number;
  classification: Classification | null;
  country: Country;
  creation_date: string;
  domain: string;
  hits: PreCaseHit[];
  host: Host | null;
  image_amount: number;
  infringer: Infringer | null;
  score: number;
  status: CaseStatus | null;
  action: Action | null;
  export: boolean;
  export_pdf_pending: boolean;
  export_pdf: string | null;
  send_to_lawyer: boolean | null;
  request_legal_assessment: boolean | null;
  is_confirmed: boolean;
  longest_duration: {
    years: number;
    months: number;
    days: number;
  };
  is_high_commercial: boolean;
  is_high_reputational: boolean;
  findings?: IFinding[];
  hit_image?: IHitImage;
  reference_image?: IReferenceImage;
}

export interface ReferenceImage {
  id: number;
  url: string;
  file_name: string;
  ryde_id: string;
  vendor_id: string;
  image: string;
  image_thumbnail: string;
  metadata?: object;
  soo_agency_pdf: string;
  soo_photographer_pdf: string;
}

/**
 * Documents
 */

export interface DocumentInfo {
  id: number;
  document_name: string;
  created: string;
  document: string;
  case_id?: number;
}

export interface DocumentCase {
  case_id: number;
  action: Action;
  domain_url: string;
  country_name: string;
  country_code: string;
  docs: DocumentInfo[];
}

export interface DocumentsFolder {
  id: number;
  name: string;
  slug: string;
  client: number;
}

export interface CuratedFindingsResponseV2 {
  results: Hit[];
  page: number;
  size: number;
  total_pages: number;
  total_count: number;
}

export interface ResponseWithPagination<T> {
  results: T[];
  page: number;
  size: number;
  total_pages: number;
  total_count: number;
}

export interface OldResponseWithPagination<T> {
  data: T[];
  success: true;
  page: number;
  size: number;
  total_pages: number;
  total_count: number;
}

export interface IDocumentData {
  total_count: number;
  total_pages: number;
  page: number;
  size: number;
  success: boolean;
  data: Document[];
}

export interface IDocument {
  id: number;
  client_id: number;
  document: string;
  document_name: string;
  created: string;
  folder: null | string;
}

export interface DamageValueObj {
  dmg_average: number;
  dmg_median: number;
  lower_end: number;
  high_end: number;
}

export type TCaseType = 'under-review' | 'cases' | 'pre-case' | 'curated-findings';
