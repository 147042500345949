import styled from "styled-components";

export interface StyledTooltipProps {
    right?: boolean;
}

export const StyledTooltip = styled.div<StyledTooltipProps>`
  background: #2b2b2b;
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  transform: ${({ right }) =>
    right ? 'translate(-280px, 13px)' : 'translate(-41px, 13px)'};
  z-index: 1000;
  color: #d7d7d7;
  cursor: default;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: ${({ right }) => (right ? '290px' : '50px')};
    transform: translateY(-100%);
    margin-left: -14px;
    border-width: 14px;
    border-style: solid;
    border-color: transparent transparent #2b2b2b transparent;
  }
`;

export const WideStyledTooltip = styled(StyledTooltip)<StyledTooltipProps>`
  width: 340px;
`;


export const TooltipContent = styled.p`
  text-align: left;
`;
