import styled from "styled-components";
import {space, Space} from "style/spacing";

interface LevelTextIndicatorContainerProps extends Space {
    variant: {
        active: string;
        inActive: string;
    };
}

export const Container = styled.span<Space>`
  display: inline-flex;
  align-items: center;

  ${space};
`;

export const LevelTextIndicatorContainer = styled.span<LevelTextIndicatorContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 50%;
  background: ${({ variant }) => variant.inActive};
  ${space}
  margin-right: 5px;
`;

export const LabelContainer = styled.span<Space>`
  font-size: 14px;
  white-space: nowrap;
`;