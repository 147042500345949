import React, {createContext, useContext, useEffect, useState} from 'react';
import {HitType, useHitTypeCtx} from './HitTypeContext';

interface ContextProps {
  blurImages: boolean;
  toggle: () => void;
}

const ImageFilterContext = createContext<ContextProps | null>(null);

const useImageFilterCtx = () => {
  const ctx = useContext(ImageFilterContext);
  if (!ctx) {
    throw new Error('');
  }
  return ctx;
};

const ImageFilterProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { currentHitType, previousHitType } = useHitTypeCtx();
  const [isBlur, setIsBlur] = useState(true);

  useEffect(() => {
    // Vice / Reputational needs blur. All needs it too as it includes Vice
    if (
      [HitType.ALL, HitType.VICE].includes(currentHitType) &&
      !isBlur &&
      [HitType.COMMERCIAL, HitType.EDITORIAL].includes(previousHitType)
    ) {
      setIsBlur(true);
    }

    if (currentHitType === HitType.COMMERCIAL || currentHitType === HitType.EDITORIAL) {
      setIsBlur(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHitType]);

  const value = {
    blurImages: isBlur,
    toggle: () => setIsBlur((prev) => !prev),
  };
  return (
    <ImageFilterContext.Provider value={value}>{children}</ImageFilterContext.Provider>
  );
};

export { useImageFilterCtx, ImageFilterProvider };
