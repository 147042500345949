import {DashboardStatsResponseMonitoring, DashboardStatsResponseNonMonitoring} from 'types';
import { ERROR_MESSAGE } from "utils/constants";

const HIT_SOURCES = 'Hits sources';
const INFRINGEMENT_OVERVIEW = 'Infringement Overview';
const TOP_HITS = 'Hits';
const COMMERCIAL_DAMAGE_VALUE = 'Indicative Commercial Value';
const PRECASE_INFO = 'Note: Only applicable for Pre-Cased or Curated Findings';
const DASHBOARD = 'Dashboard';
const CURATED_FINDINGS = 'Curated Findings';
const FINDINGS = 'Findings';
const CASES = 'Cases';
const ANALYTICS = 'Analytics';
const PORTFOLIO = 'Portfolio';
const SETTINGS = 'Settings';
const TAKE_ACTION = 'Take Action';

export const BUTTON_LABEL_LOGOUT = 'Log out';

export const DASHBOARD_HEADERS = {
  HIT_SOURCES,
  INFRINGEMENT_OVERVIEW,
  TOP_HITS,
  COMMERCIAL_DAMAGE_VALUE,
  PRECASE_INFO
};

export const NAV_ITEMS_LABELS = {
  ANALYTICS,
  CASES,
  DASHBOARD,
  CURATED_FINDINGS,
  FINDINGS,
  PORTFOLIO,
  SETTINGS,
  TAKE_ACTION,
};

export const DEFAULT_ERROR_MSG = ERROR_MESSAGE;

// curated findings
export const DASHBOARD_STATS_LABELS: Record<
  keyof DashboardStatsResponseNonMonitoring,
  { label: string; path: string }
> = {
  images: { label: 'Images in search', path: '/portfolio' },
  total_hits: { label: 'Total Hits', path: '/' },
  total_domains: { label: 'Total Domains', path: '/' },
  total_under_review: { label: 'Under Review', path: '/under-review' },
  total_cases: { label: 'Total Cases', path: '/cases' },
  legal_action_pending: { label: 'Cases in Phase 1', path: '/cases' },
  legal_action_negotiating: { label: 'Cases in Negotiation', path: '/cases' },
  legal_action_proceedings: { label: 'Cases in Legal Proceedings', path: '/cases' },
  legal_action_success: { label: 'Cases Won', path: '/cases' },
  social_findings: { label: 'Social Findings', path: '/social-listening' },
  legal_action_closed: { label: 'Closed cases', path: '/cases' },
};

// curated findings
export const COPYRIGHT_DASHBOARD_STATS_LABELS: Record<
    keyof DashboardStatsResponseNonMonitoring,
    { label: string; path: string }
    > = {
  images: { label: 'Images in search', path: '/portfolio' },
  total_hits: { label: 'Total Hits', path: '/' },
  total_domains: { label: 'Total Domains', path: '/' },
  total_under_review: { label: 'Under Review', path: '/under-review' },
  total_cases: { label: 'Total Cases', path: '/cases' },
  legal_action_pending: { label: 'Cases in Phase 1', path: '/cases' },
  legal_action_negotiating: { label: 'Cases in Negotiation', path: '/cases' },
  legal_action_proceedings: { label: 'Cases in Legal Proceedings', path: '/cases' },
  legal_action_success: { label: 'Cases Won', path: '/cases' },
  social_findings: { label: 'Social Findings', path: '/social-listening' },
  legal_action_closed: { label: 'Closed cases', path: '/cases' },
};

// pre-cases
export const MONITORING_DASHBOARD_STATS_LABELS: Record<
    keyof DashboardStatsResponseMonitoring,
    { label: string; path: string }
    > = {
  images: { label: 'Images in search', path: '/portfolio' },
  total_hits_monitoring: { label: 'Total Hits', path: '/' },
  total_domains_monitoring: { label: 'Total Domains', path: '/' },
  total_under_review: { label: 'Under Review', path: '/under-review' },
  total_cases: { label: 'Total Cases', path: '/cases' },
  legal_action_pending: { label: 'Cases in Phase 1', path: '/cases' },
  legal_action_negotiating: { label: 'Cases in Negotiation', path: '/cases' },
  legal_action_proceedings: { label: 'Cases in Legal Proceedings', path: '/cases' },
  legal_action_success: { label: 'Cases Won', path: '/cases' },
  social_findings: { label: 'Social Findings', path: '/social-listening' },
  legal_action_closed: { label: 'Closed cases', path: '/cases' },
};

// pre-case
export const MONITORING_COPYRIGHT_DASHBOARD_STATS_LABELS: Record<
    keyof DashboardStatsResponseMonitoring,
    { label: string; path: string }
    > = {
  images: { label: 'Images in search', path: '/portfolio' },
  total_hits_monitoring: { label: 'Total Hits', path: '/' },
  total_domains_monitoring: { label: 'Total Domains', path: '/' },
  total_under_review: { label: 'Under Review', path: '/under-review' },
  total_cases: { label: 'Total Cases', path: '/cases' },
  legal_action_pending: { label: 'Cases in Phase 1', path: '/cases' },
  legal_action_negotiating: { label: 'Cases in Negotiation', path: '/cases' },
  legal_action_proceedings: { label: 'Cases in Legal Proceedings', path: '/cases' },
  legal_action_success: { label: 'Cases Won', path: '/cases' },
  social_findings: { label: 'Social Findings', path: '/social-listening' },
  legal_action_closed: { label: 'Closed cases', path: '/cases' },
};
