import styled from "styled-components";
import React from "react";

interface StyledIconWrapperProps {
    color: React.CSSProperties['color'];
    hoverColor?: React.CSSProperties['color'];
    disabledColor?: React.CSSProperties['color'];
    changeColorOnParentHover?: boolean;
}

export const StyledIconWrapper = styled.span<StyledIconWrapperProps>`
  stroke: ${({ color }) => color};
  fill: ${({ color }) => color};
  display: inline-flex;
  align-items: center;

  transition: all 0.15s;

  &:hover {
    stroke: ${({ hoverColor, color }) => (!!hoverColor ? hoverColor : color)};
    fill: ${({ hoverColor, color }) => (!!hoverColor ? hoverColor : color)};
  }

  *:disabled & {
    stroke: ${({ disabledColor }) => disabledColor};
    fill: ${({ disabledColor }) => disabledColor};
  }
`;
