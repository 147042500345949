import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

export const Toast: React.FC = () => {
  return (
    <ToastContainer
      style={{ zIndex: 10000000 }}
      transition={Slide}
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover={false}
    />
  );
};
