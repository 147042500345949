import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { BaseCSS } from 'styled-bootstrap-grid';

import { QueryClient, QueryClientProvider } from 'react-query';

import AppShell from './AppShell';
import { AuthProvider, useAuth } from 'services/contexts/AuthContext';
import { CelebrityProvider } from 'services/contexts/CelebrityContext';
import { FetchProvider } from 'contexts/FetchContext';
import { HitTypeProvider } from 'contexts/HitTypeContext';
import { ImageUploadContextProvider } from 'contexts/ImageUploadContext';
import { PortfolioContextProvider } from "./pages/Portfolio/services/PortfolioContext";
import { CLIENT_ROUTES } from 'config';
import { Card, Toast } from 'components';
import { ImageFilterProvider } from 'contexts/BlurImagesContext';
import { DashboardContextProvider } from "./pages/Dashboard/hooks/DashboardContext";
import { DocumentUploadContextProvider } from "./pages/Documents/services/DocumentsContext";
import { CuratedFindingContextDataProvider } from './pages/CuratedFinding/hooks/CuratedFindingDataContext';
import { SocialListeningContextProvider } from './pages/SocialListening/services/SocialListeningContext';
import { SignUpIn, NotFound, LoadingPage, ErrorBoundary } from 'pages';
import { RoutesBlockContextProvider } from 'contexts/RoutesBlockContext';
import ScrollToTop from "./components/ScrollToTop";

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const CuratedFindings = lazy(() => import('./pages/CuratedFindings/CuratedFindings'));
const PreCases = lazy(() => import('./pages/PreCases'));
const UnderReview = lazy(() => import('./pages/UnderReview/UnderReview'));
const Portfolio = lazy(() => import('./pages/Portfolio/Portfolio/Portfolio'));
const FolderPortfolio = lazy(() => import('./pages/Portfolio/FolderPortfolio/FolderPortfolio'));
const Documents = lazy(() => import('./pages/Documents/Documents'));
const SocialListening = lazy(() => import('./pages/SocialListening/SocialListening'));
const SocialDetail = lazy(() => import('./pages/SocialListening/SocialDetail/SocialDetail'));
const CuratedFinding = lazy(() => import('./pages/CuratedFinding/CuratedFinding'));
const PreCaseDetail = lazy(() => import('./pages/CuratedFinding/PreCaseDetail'));
const Cases = lazy(() => import('./pages/Cases/CaseContainer'));
const Monitoring = lazy(() => import('./pages/Monitoring/Monitoring'));
const UserSettings = lazy(() => import('./pages/UserSettings/UserSettings/UserSettings'));
const LeavePage = lazy(() => import('./pages/LeavePage/LeavePage'));
const Ledger = lazy(() => import('./pages/Ledger/Ledger/Ledger'));
const Template = lazy(() => import('./pages/Template/template'));


export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

interface AuthenticatedRouteProps {
  path?: string | string[];
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children, path }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      path={path}
      render={() =>
        isAuthenticated() ? <AppShell>{children}</AppShell> : <Redirect to="/" />
      }
    ></Route>
  );
};

const AppRoutes: React.FC = () => {
  const { isAuthenticated: getIsAuthenticated } = useAuth();
  const isAuthenticated = getIsAuthenticated();
  return (
    <>
      <ErrorBoundary>
        <Suspense
          fallback={
            <AppShell>
              <Card>
                <LoadingPage />
              </Card>
            </AppShell>
          }
        >
          <Switch>
            <Route exact path={CLIENT_ROUTES.SIGN_UP_IN_PAGE}>
              {isAuthenticated ? (
                <Redirect to={CLIENT_ROUTES.DASHBOARD_PAGE} />
              ) : (
                <SignUpIn />
              )}
            </Route>
            <Route
              path={[
                CLIENT_ROUTES.ACCOUNT_ACTIVATION_PAGE,
                CLIENT_ROUTES.PASSWORD_CHANGE_PAGE,
              ]}
            >
              <SignUpIn />
            </Route>
            <AuthenticatedRoute path={CLIENT_ROUTES.DASHBOARD_PAGE}>
              <Dashboard />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.CURATED_FINDINGS_PAGE}>
              <CuratedFindings />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.PRE_CASES_PAGE}>
              <PreCases />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.PRE_CASE_DETAIL_PAGE}>
              <PreCaseDetail />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path={[
                CLIENT_ROUTES.CASE_PAGE,
                CLIENT_ROUTES.CURATED_FINDING_PAGE,
                CLIENT_ROUTES.UNDER_REVIEW_FINDING_PAGE,
                CLIENT_ROUTES.CASES_FINDING_PAGE,
              ]}
            >
              <CuratedFinding />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.TAKE_ACTION}>
              <UnderReview />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path={[
                CLIENT_ROUTES.SOCIAL_CASE_PAGE,
                CLIENT_ROUTES.SOCIAL_IN_REVIEW_PAGE,
              ]}
            >
              <SocialDetail />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.CASES_PAGE}>
              <Cases />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.MONITORING}>
              <Monitoring />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.LEDGER_PAGE}>
              <Ledger />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.PORTFOLIO_FOLDER_PAGE}>
              <FolderPortfolio />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.PORTFOLIO_PAGE}>
              <Portfolio />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.DOCUMENTS_PAGE}>
              <Documents />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.SOCIAL_LISTENING}>
              <SocialListening />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.USER_SETTINGS_PAGE}>
              <UserSettings />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={CLIENT_ROUTES.TEMPLATE_PAGE}>
              <Template />
            </AuthenticatedRoute>
            <Route path={CLIENT_ROUTES.LEAVE_PAGE}>
              {isAuthenticated ? <LeavePage /> : <Redirect to="/" />}
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export const App: React.FC = () => {
  return (
    <Router>
      <Toast />
      <BaseCSS />
      <ScrollToTop />
      <AuthProvider>
        <CelebrityProvider>
          <FetchProvider>
            <HitTypeProvider>
              <ImageUploadContextProvider>
                <PortfolioContextProvider>
                  <ImageFilterProvider>
                    <DashboardContextProvider>
                      <DocumentUploadContextProvider>
                        <CuratedFindingContextDataProvider>
                          <SocialListeningContextProvider>
                            <QueryClientProvider client={queryClient}>
                              <RoutesBlockContextProvider>
                                <AppRoutes />
                              </RoutesBlockContextProvider>
                            </QueryClientProvider>
                          </SocialListeningContextProvider>
                        </CuratedFindingContextDataProvider>
                      </DocumentUploadContextProvider>
                    </DashboardContextProvider>
                  </ImageFilterProvider>
                </PortfolioContextProvider>
              </ImageUploadContextProvider>
            </HitTypeProvider>
          </FetchProvider>
        </CelebrityProvider>
      </AuthProvider>
    </Router>
  );
};
