import { useQuery } from 'react-query';
import { PUBLIC_API } from 'services/config/api';
import { publicHTTPClient } from 'services/config/publicHTTPClient';
import { showErrorToast } from 'utils/toast/toast';
import { ERROR_MESSAGE } from "utils/constants";

export const useActivateAccountQuery = (token: string | undefined, enable: boolean) => {
  const activateRequest = (token: string) => {
    return publicHTTPClient
      .get(PUBLIC_API.ACTIVATE_ACCOUNT + token + '/')
      .then((resp) => resp.data);
  };

  const { isError, isSuccess } = useQuery(
    ['activation', token],
    () => activateRequest(token as string),
    {
      onError: () => showErrorToast(ERROR_MESSAGE),
      enabled: !!token && enable,
    }
  );

  return {
    isError,
    isSuccess,
  };
};
