export const theme = {
  colors: {
    white: '#FFFFFF',
    darkenWhite: '#D7D7D7', // ALMOST WHITE IN FIGMA
    black: '#000000',
    success: '#00F380',
    error: '#D2392E',
    lemon: '#D6FF00',
    editorial: '#2c77d3',
    darkenLemon: '#B5DD00',
    magenta: '#E400FF',
    darkenMagenta: '#B300DD',
    commercial: '#43C19B',
    reputational: '#8E59FF',
    backgroundBlack: '#202020',
    mediumGrey: '#9A9A9A',
    darkBoxes: '#121212',
    greySelected: '#2B2B2B',
    lightGreen: '#a9d18e',
    tomato: 'tomato',
    cyan: 'cyan',
    grey: '#4c4c4c',
    lightgrey: 'lightgray'
  },
  fontSize: {},
};
