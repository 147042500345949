/**
 * @description context provider used for fetched data in CuratedFinding page
 * For everything else, we still use CuratedFindingContext
 */
import React, {createContext, useContext, useState} from 'react';
import {useFetchCtx} from 'contexts/FetchContext';
import {CuratedFindingsResponseV2, ITag} from "types";
import {ERROR_MESSAGE, getCookie} from "utils/constants";
import {PRIVATE_API} from "services/config/api";
import {HitType} from "contexts/HitTypeContext";
import queryString from "query-string";
import {showErrorToast, showInfoToast} from "utils/toast/toast";

interface ICuratedFindingDataContext {
    setIsCuratedFindingDataLoading: (val: boolean) => void;
    setCuratedFindingsData: (val: CuratedFindingsResponseV2) => void;
    curatedFindingsData: any;
    isCuratedFindingDataLoading: boolean;
    fetchCuratedFindingData: any;
    requestPreCaseCuration: any;
    page: number;
    setPage: (val: number) => void;
}

const INITIAL_CTX_STATE: ICuratedFindingDataContext = {
    setIsCuratedFindingDataLoading: () => ({}),
    setCuratedFindingsData: () => ({}),
    curatedFindingsData: {},
    isCuratedFindingDataLoading: false,
    fetchCuratedFindingData: () => Promise.resolve([]),
    requestPreCaseCuration: () => Promise.resolve([]),
    page: 1,
    setPage: () => ({}),
};

const CuratedFindingDataContext = createContext<ICuratedFindingDataContext>(INITIAL_CTX_STATE);

export const useCuratedFindingCtx = () => {
    const ctx = useContext(CuratedFindingDataContext);

    if (!ctx) {
        throw new Error(ERROR_MESSAGE);
    }

    return ctx;
};

export const CuratedFindingContextDataProvider: React.FC = ({children}) => {
    const {get, post} = useFetchCtx();
    const [isCuratedFindingDataLoading, setIsCuratedFindingDataLoading] = useState(false);
    const [curatedFindingsData, setCuratedFindingsData] = useState({});
    const [page, setPage] = useState<number>(1);

    const fetchCuratedFindingData = async (currentHitType: HitType, page: number, size: number, selectedTags: ITag[]) => {
        let commonURLPart = `v2/${PRIVATE_API.FINDINGS}?classification=${currentHitType}&`;
        const idsOfSelectedTags = selectedTags.map((tag) => tag.id);
        setIsCuratedFindingDataLoading(true)
        const {data} = await get<CuratedFindingsResponseV2>(
            `${commonURLPart}page=${page}&size=${size}`, {
                params: { tag: idsOfSelectedTags },
                paramsSerializer: queryString.stringify,
            }
        );
        const {page: pageNo} = data || {};
        setPage(pageNo)
        setCuratedFindingsData(data)
        setIsCuratedFindingDataLoading(false)
        return data;
    };

    const requestPreCaseCuration = async (id: number, case_status: number) => {
        const {data} = await post(
            `client-images/${id}/global-precase-action/`,  { case_status: case_status }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                },
            }
        );
        const {success} = data;
        if (success === true) {
            showInfoToast('Action has been requested successfully');
        } else {
            showErrorToast(`The error occurred during action request`);
        }
        return data;
    };
    const value = {
        setIsCuratedFindingDataLoading,
        setCuratedFindingsData,
        curatedFindingsData,
        isCuratedFindingDataLoading,
        fetchCuratedFindingData,
        requestPreCaseCuration,
        page,
        setPage
    };

    return (<CuratedFindingDataContext.Provider value={value}>{children}</CuratedFindingDataContext.Provider>
    );
};

