import { caseStatuses, TCaseType } from "types";
import styled from 'styled-components';

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const QUERY_STRING_LIMIT = /(?:limit=)([0-9]+)/;
const QUERY_STRING_OFFSET = /(?:offset=)([0-9]+)/;

export const validateEmail = (email: string) => {
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 *  eg. next: "/api/portfolio/?client_id=29&limit=30&offset=60"
 */
export const getNextURLforInifnityScroll = (
  next: string | null,
  options?: { prefix?: string; suffix?: string }
) => {
  if (next === null) {
    return null;
  }

  const finalPrefix = options?.prefix || '';
  const finalSuffix = options?.suffix || '';

  return (
    finalPrefix +
    next
      .split('&')
      .filter((queryString) => {
        const matchingLimit = queryString.match(QUERY_STRING_LIMIT);
        if (matchingLimit) return matchingLimit[1];

        const matchingOffset = queryString.match(QUERY_STRING_OFFSET);
        if (matchingOffset) return matchingOffset[1];

        return false;
      })
      .join('&') +
    finalSuffix
  );
};

export const allowOnlyNumbers = (e: { currentTarget: { value: string } }) => {
  e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, '');
};

export const abbreviationFormatter = (value: number) => {
  return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)
}


export const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
`;

export const renderCaseStatus = (case_status: number) => {
  return <Center style={{ marginRight: 2 }}>
    {caseStatuses[case_status]}
  </Center>
}

// Only Paris Jackson is a TEST client, the rest are PROD clients
/*  James Harden: 121
    DMX: 120
    Paris Jackson: 57
    Marshawn Lynch: 125
    Danny Green: 132
    Ryan Hoover: 131
    Nino Niederreiter: 138
    Emmy Taylor: 97
     */
export const getSocialClientsIds = [121, 120, 57, 125, 132, 131, 138, 97];

// [TEST] Chris Hemsworth = 72
// This is a clientId as this client has specific requests for his own view
export const marshawnsChonchle = 125;

export const getLegalWarningText = (clientId: number) => {
  return clientId === marshawnsChonchle ? 'CLAIM ROYALTIES' : 'LEGAL WARNING'
}

export const timeout = (delay: number) => {
  return new Promise(res => setTimeout(res, delay));
}

export const getColoredDomains = (clientId: number, domain: string, show?: boolean) => {
  const colorDomains = [
    '7seasproshopthai.com',
    'alibaba.com',
    'amazon.com',
    'amazon.co.uk',
    'amazon.in',
    'angola.desertcart.com',
    'artistshot.com',
    'askulaska.com',
    'backlash.co.jp',
    'beastmodeon.co.uk',
    'bondiband.com',
    'cheapandcheerfulclothing.co.uk',
    'coolisa.at',
    'customon.com',
    'de.aliexpress.com',
    'eandqdesigns.com',
    'ebay.com',
    'ebay.co.uk',
    'etsy.com',
    'explicitshirtstore.com',
    'fanatics.com',
    'fishdeal.co.uk',
    'fiskedeal.dk',
    'fitbloomer.com',
    'flipkart.com',
    'hatsline.com',
    'hatstore.co.in',
    'hayateclothing.com',
    'hikebeaststore.com',
    'hyperxclothing.com',
    'idyllwind.com',
    'joom.com',
    'lids.com',
    'modesens.com',
    'mojo-usa.com',
    'mothertruckerco.com',
    'myhonesteez.com',
    'naaviclothing.com',
    'noexcusessport.com',
    'printshirts.co.uk',
    'raiderimage.com',
    'redbubble.com',
    'ruffleswithlove.com',
    'samoa.desertcart.com',
    'seworksga.com',
    'sheplers.com',
    'shopee.sg',
    'shop.score.my',
    'sinceeighteen.com',
    'spikeme.in',
    'sportsblue.com.au',
    'spreadshirt.ie',
    'stepevoli.com',
    'streamtackle.com',
    'streetsidesurgeons.co.uk',
    'sweetfit.co.za',
    'tasseltoppers.com',
    'teechallaclothing.com',
    'thaipick.com',
    'thehustleclothing.co.nz',
    'tshirt-center.com',
    'tshirtprochip.com',
    'ubuy.com.my',
    'volaresports.com',
    'wanelo.com',
    'wish.com',
    'worthpoint.com',
    'yosicollective.com',
    'yourbasicbits.com',
    'zexpaapparel.com',
    'beastmodejku.com',
    'impeyanlife.com',
    'www.impeyanlife.com',
    'aliexpress.com',
    'www.aliexpress.com',
    'neoninterior.com',
    'www.neoninterior.com',
    'argogate.com',
    'www.argogate.com',
    'thisiswhatiwant.com',
    'www.thisiswhatiwant.com',
    'etsy.com',
    'www.etsy.com',
    'teepublic.com',
    'www.teepublic.com',
    'spreadshirt.co.uk',
    'www.spreadshirt.co.uk',
    'customneon.com.au',
    'www.customneon.com.au',
    'lightinthebox.com',
    'www.lightinthebox.com',
    'dhgate.com',
    'www.dhgate.com',
    'allegro.pl',
    'www.allegro.pl'
  ]
  if (colorDomains.includes(domain) && clientId === marshawnsChonchle && show) {
    return '#097969'
  }
  return '#ffffff'
};

export const getCaseTypeByPathName = (pathname: string): TCaseType => {
  const indexOfLastElementStarts = pathname.lastIndexOf('/');
  const caseType = pathname.slice(indexOfLastElementStarts).replace('/', '');
  return caseType as TCaseType;
}
