import React, {createContext, useContext, useState} from 'react';
import {useFetchCtx} from 'contexts/FetchContext';
import {ERROR_MESSAGE, getCookie} from "utils/constants";
import {showErrorToast, showInfoToast} from "utils/toast/toast";
import { DocumentCase, OldResponseWithPagination } from "../../../types";
import { queryClient } from "../../../App";
import {
    DOCUMENTS_QUERY_KEY,
    GENERAL_DOCUMENTS_QUERY_KEY
} from "../../../services/documents/query-keys";

interface IDocUploadContext {
    setIsSocialDocsLoading: (val: boolean) => void;
    setSocialDocumentsData: (val: OldResponseWithPagination<DocumentCase>) => void;
    socialDocumentsData: any;
    isSocialDocsLoading: boolean;
    fetchSocialDocumentsData: any;
    docCases: any;
    isDocCasesLoading: boolean;
    docSocialCases: any;
    isSocialDocCasesLoading: boolean;
    fetchCaseByClient: () => void;
    fetchSocialCaseByClient: () => void;
    caseDocs: any;
    isCaseDocsLoading: boolean;
    fetchDocumentsByCaseId: (caseId: number) => void;
    socialCaseDocs: any;
    isSocialCaseDocsLoading: boolean;
    fetchSocialDocumentsByCaseId: (caseId: number) => void;
    isDocDelLoading: boolean;
    deleteDocument: (docId: number, case_id: number) => Promise<any>;
    isGeneralDocDelLoading: boolean;
    generalDeleteDocument: (docId: number) => void;
    isSocialDocDelLoading: boolean;
    deleteSocialDocument: (docId: number, case_id: number) => Promise<any>;
    isDocUploadLoading: boolean;
    postDocument: (formData: FormData, caseId: number) => void;
    isSocialDocUploadLoading: boolean;
    postSocialDocument: (formData: FormData, caseId: number) => void;
    isGeneralDocUploadLoading: boolean;
    postGeneralDocument: (formData: FormData) => void;
}

const INITIAL_CTX_STATE: IDocUploadContext = {
    setIsSocialDocsLoading: () => ({}),
    setSocialDocumentsData: () => ({}),
    socialDocumentsData: {},
    isSocialDocsLoading: false,
    fetchSocialDocumentsData: () => Promise.resolve([]),
    docCases: [],
    isDocCasesLoading: false,
    docSocialCases: [],
    isSocialDocCasesLoading: false,
    fetchCaseByClient: () => Promise.resolve([]),
    fetchSocialCaseByClient: () => Promise.resolve([]),
    caseDocs: [],
    isCaseDocsLoading: false,
    fetchDocumentsByCaseId: () => Promise.resolve([]),
    socialCaseDocs: [],
    isSocialCaseDocsLoading: false,
    fetchSocialDocumentsByCaseId: () => Promise.resolve([]),
    isDocDelLoading: false,
    deleteDocument: () => Promise.resolve([]),
    isGeneralDocDelLoading: false,
    generalDeleteDocument: () => Promise.resolve([]),
    isSocialDocDelLoading: false,
    deleteSocialDocument: () => Promise.resolve([]),
    isDocUploadLoading: false,
    postDocument: () => Promise.resolve([]),
    isSocialDocUploadLoading: false,
    postSocialDocument: () => Promise.resolve([]),
    isGeneralDocUploadLoading: false,
    postGeneralDocument: () => Promise.resolve([]),
};

const DocumentUploadContext = createContext<IDocUploadContext>(INITIAL_CTX_STATE);

export const useDocumentCtx = () => {
    const ctx = useContext(DocumentUploadContext);

    if (!ctx) {
        throw new Error(ERROR_MESSAGE);
    }

    return ctx;
};

export const DocumentUploadContextProvider: React.FC = ({children}) => {
    const {get, del, post} = useFetchCtx();

    const [isSocialDocsLoading, setIsSocialDocsLoading] = useState(false);
    const [socialDocumentsData, setSocialDocumentsData] = useState({});
    const [isDocCasesLoading, setIsDocCasesLoading] = useState(false);
    const [docCases, setDocCases] = useState({});
    const [isSocialDocCasesLoading, setIsSocialDocCasesLoading] = useState(false);
    const [docSocialCases, setDocSocialCases] = useState({});
    const [caseDocs, setCaseDocs] = useState({});
    const [isCaseDocsLoading, setIsCaseDocsLoading] = useState(false);
    const [socialCaseDocs, setSocialCaseDocs] = useState({});
    const [isSocialCaseDocsLoading, setIsSocialCaseDocsLoading] = useState(false);


    const [isDocDelLoading, setIsDocDelLoading] = useState(false);
    const [isSocialDocDelLoading, setIsSocialDocDelLoading] = useState(false);
    const [isGeneralDocDelLoading, setIsGeneralDocDelLoading] = useState(false);
    const [isDocUploadLoading, setIsDocUploadLoading] = useState(false);
    const [isSocialDocUploadLoading, setIsSocialDocUploadLoading] = useState(false);
    const [isGeneralDocUploadLoading, setIsGeneralDocUploadLoading] = useState(false);


    const fetchSocialDocumentsData = async () => {
        setIsSocialDocsLoading(true)
        const {data: {data}} = await get<OldResponseWithPagination<DocumentCase>>(
            'social_listening/document_list/',
        );
        setSocialDocumentsData(data)
        setIsSocialDocsLoading(false)
        return data;
    };

    const fetchCaseByClient = async () => {
        setIsDocCasesLoading(true)
        const {data} = await get(
            'case_list/',
        );
        setDocCases(data)
        setIsDocCasesLoading(false)
        return data;
    };

    const fetchSocialCaseByClient = async () => {
        setIsSocialDocCasesLoading(true)
        const {data} = await get(
            'social_listening/case_list/',
        );
        setDocSocialCases(data)
        setIsSocialDocCasesLoading(false)
        return data;
    };

    const fetchDocumentsByCaseId = async (caseId: number) => {
        setIsCaseDocsLoading(true)
        const {data: {data}} = await get(`document_list/case/${caseId}/`);
        setIsCaseDocsLoading(false)
        setCaseDocs(data)
        return data;
    };

    const fetchSocialDocumentsByCaseId = async (caseId: number) => {
        setIsSocialCaseDocsLoading(true)
        const {data: {data}} = await get(`social_listening/document_list/case_list/${caseId}/`);
        setIsSocialCaseDocsLoading(false)
        setSocialCaseDocs(data)
        return data;
    };

    const deleteDocument = async (docId: number, case_id: number) => {
        setIsDocDelLoading(true)
        const { data } = await del(
            `document_list/case/${case_id}/`, { data: { document_id: docId }}, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': getCookie('csrftoken')
                },
            }
        );
        if (data.success) {
            showInfoToast('File deleted successfully')
        }
        await queryClient.invalidateQueries(DOCUMENTS_QUERY_KEY)
        await fetchDocumentsByCaseId(case_id)
        setIsDocDelLoading(false)
        return data;
    };

    const generalDeleteDocument = async (docId: number) => {
        setIsGeneralDocDelLoading(true)
        const {data} = await del(
            `general_documents/${docId}/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': getCookie('csrftoken')
                },
            }
        )
        if (data.success) {
            showInfoToast('File deleted successfully')
        }
        await queryClient.invalidateQueries(GENERAL_DOCUMENTS_QUERY_KEY)
        setIsGeneralDocDelLoading(false)
        return data;
    };

    const deleteSocialDocument = async (docId: number, case_id: number) => {
        setIsSocialDocDelLoading(true)
        const { data } = await del(
            `social_listening/document_list/case_list/${case_id}/`, { data: { document_id: docId }}, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': getCookie('csrftoken')
                },
            }
        );
        if (data.success) {
            showInfoToast('File deleted successfully')
        }
        await fetchSocialDocumentsData()
        await fetchSocialDocumentsByCaseId(case_id)
        setIsSocialDocDelLoading(false)
        return data;
    };

    const postDocument = async (formData: FormData, caseId: number) => {
        setIsDocUploadLoading(true)
        const { data } = await post(
            `document_list/case/${caseId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': getCookie('csrftoken')
                },
            }
        );
        await queryClient.invalidateQueries(DOCUMENTS_QUERY_KEY)
        await fetchDocumentsByCaseId(caseId) // will be useful for upload within document
        setIsDocUploadLoading(false)
        const {pk} = data || {};
        if (pk) {
            showInfoToast('Document uploaded successfully')
        } else {
            showErrorToast(ERROR_MESSAGE);
        }
        return data;
    };

    const postSocialDocument = async (formData: FormData, caseId: number) => {
        setIsSocialDocUploadLoading(true)
        const { data } = await post(
            `social_listening/document_list/case_list/${caseId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': getCookie('csrftoken')
                },
            }
        );
        await fetchSocialDocumentsData()
        await fetchSocialDocumentsByCaseId(caseId) // will be useful for upload within document
        setIsSocialDocUploadLoading(false)
        const {pk} = data || {};
        if (pk) {
            showInfoToast('Document uploaded successfully')
        } else {
            showErrorToast(ERROR_MESSAGE);
        }
        return data;
    };

    const postGeneralDocument = async (formData: FormData) => {
        setIsGeneralDocUploadLoading(true)
        const { data } = await post(
            `general_documents/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': getCookie('csrftoken')
                },
            }
        );
        await queryClient.invalidateQueries(GENERAL_DOCUMENTS_QUERY_KEY)
        setIsGeneralDocUploadLoading(false)
        const {pk} = data || {};
        if (pk) {
            showInfoToast('Document uploaded successfully')
        } else {
            showErrorToast(ERROR_MESSAGE);
        }
        return data;
    };


    const value = {
        setIsSocialDocsLoading,
        setSocialDocumentsData,
        socialDocumentsData,
        isSocialDocsLoading,
        fetchSocialDocumentsData,
        docCases,
        isDocCasesLoading,
        fetchCaseByClient,
        docSocialCases,
        isSocialDocCasesLoading,
        fetchSocialCaseByClient,
        caseDocs,
        isCaseDocsLoading,
        fetchDocumentsByCaseId,
        socialCaseDocs,
        isSocialCaseDocsLoading,
        fetchSocialDocumentsByCaseId,
        isDocDelLoading,
        deleteDocument,
        isGeneralDocDelLoading,
        generalDeleteDocument,
        isSocialDocDelLoading,
        deleteSocialDocument,
        isDocUploadLoading,
        postDocument,
        isSocialDocUploadLoading,
        postSocialDocument,
        isGeneralDocUploadLoading,
        postGeneralDocument
    };

    return (<DocumentUploadContext.Provider value={value}>{children}</DocumentUploadContext.Provider>
    );
};
