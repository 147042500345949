import React, { ReactNode, useState } from "react";
import { theme } from "assets/styles/theme";
import { Icon } from "components";
import { isEmpty } from "lodash";
import {
  StyledHeaderCell,
  StyledHeaderCellContent,
  StyledHeaderCellLabel,
  InfoWrapper,
  IconWrapper,
  THead,
  THRow,
  TRow,
  StyledCell,
  HoverLayer,
  TableContainer,
  ScrollTableWrapper,
  Table,
  TBody,
} from "./Datatable.styles";

type CellAlign = "start" | "center" | "end" | "justify";

interface DatatableBaseConfig {
  cellAlign?: CellAlign;
  color?: string;
  width?: string;
  colSpan?: number;
  padding?: string;
  position?: "absolute" | "relative" | "inherit";
}

export interface DatatableRowConfig<T> extends DatatableBaseConfig {
  key: keyof T;
  customCell?: (props: T) => ReactNode;
}

export interface DatatableHeaderConfig extends DatatableBaseConfig {
  label: string;
  key?: string;
  onSortClick?: (payload: { key: string; type: "asc" | "desc" | null }) => void;
  labelInfo?: string;
  maxWidth?: string;
}

export interface TDataProps {
  cellAlign?: CellAlign;
  color?: string;
  width?: string;
  padding?: string;
}

interface HeadCellProps {
  config: DatatableHeaderConfig;
  sortState: SortStateType | null;
  onSortClick?: (newSortState: SortStateType) => void;
}

const HeadCell: React.FC<HeadCellProps> = ({
  config,
  sortState,
  onSortClick,
}) => {
  const {
    cellAlign,
    colSpan,
    width,
    label,
    onSortClick: sortClick,
    key,
    labelInfo,
    maxWidth,
  } = config;
  const isSortable = !!sortClick;

  const handleClick = () => {
    if (isSortable) {
      let type: "asc" | "desc" = "asc";
      if (
        sortState === null ||
        (sortState !== null && sortState.type === null) ||
        sortState.key !== key
      ) {
        type = "asc";
      } else if (sortState.type === "asc") {
        type = "desc";
      }

      onSortClick!({ key: key as string, type });
      sortClick?.({ key: key as string, type });
    }
  };

  const showInfoIcon = !isEmpty(labelInfo);

  const sortType = sortState?.key === key ? sortState?.type : null;
  const color = !!sortType ? theme.colors.white : theme.colors.mediumGrey;

  return (
    <StyledHeaderCell
      onClick={handleClick}
      cellAlign={cellAlign}
      color={color}
      width={width}
      key={label}
      colSpan={colSpan}
      isSortable={isSortable}
      maxWidth={maxWidth}
    >
      <StyledHeaderCellContent>
        <StyledHeaderCellLabel>{label}</StyledHeaderCellLabel>
        {showInfoIcon && (
          <InfoWrapper title={labelInfo}>
            <Icon
              name="info"
              color={theme.colors.mediumGrey}
              width={14}
              height={"auto"}
            />
          </InfoWrapper>
        )}
        {isSortable && (
          <IconWrapper sortType={sortType}>
            <Icon name="sortArrows" color={theme.colors.mediumGrey} />
          </IconWrapper>
        )}
      </StyledHeaderCellContent>
    </StyledHeaderCell>
  );
};

interface TableHeadProps {
  config: DatatableHeaderConfig[];
}

type SortStateType = { key: string; type: "asc" | "desc" | null };

const TableHead: React.FC<TableHeadProps> = ({ config }) => {
  const [sortState, setSortState] = useState<SortStateType | null>(null);
  const handleSortClick = (newSortState: SortStateType) =>
    setSortState(newSortState);

  return (
    <THead>
      <THRow>
        {config.map((headCellConfig) => (
          <HeadCell
            key={headCellConfig.label}
            sortState={sortState}
            onSortClick={handleSortClick}
            config={headCellConfig}
          />
        ))}
      </THRow>
    </THead>
  );
};

interface TableRowProps<ObjectType> {
  id?: number;
  object: ObjectType;
  datatableRowConfig: DatatableRowConfig<ObjectType>[];
  hoverEffect: boolean;
  customHoverHeight?: string;
  onRowClick?: (object: ObjectType) => void;
  isRowActive?: (object: ObjectType) => boolean;
  ref?: React.RefObject<HTMLTableRowElement> | null;
}
const TableRow = React.forwardRef<HTMLElement, TableRowProps<any>>(
  (
    {
      id,
      object,
      datatableRowConfig,
      hoverEffect,
      customHoverHeight,
      onRowClick,
      isRowActive,
    },
    ref,
  ) => {
    return (
      <TRow
        isClickable={!!onRowClick}
        onClick={() => onRowClick?.(object)}
        id={id as any}
        ref={ref ? (ref as any) : undefined}
      >
        {datatableRowConfig.map((property, index) => {
          return (
            <StyledCell
              key={String(property.key) + index}
              cellAlign={property.cellAlign}
              color={property.color}
              width={property.width}
              padding={property.padding}
            >
              {index === 0 && hoverEffect && (
                <HoverLayer
                  customHoverHeight={customHoverHeight}
                  active={isRowActive?.(object)}
                />
              )}
              {/*TODO maybe remove this if it is of no use to the btns*/}
              <div
                style={{
                  position: property.position ? property.position : "relative",
                  top: property.position === "absolute" ? "30px" : "auto",
                }}
              >
                {property.customCell
                  ? property.customCell(object)
                  : object[property.key]}
              </div>
            </StyledCell>
          );
        })}
      </TRow>
    );
  },
);
TableRow.displayName = "TableRow";

interface DatatableProps<ObjectType> {
  objects: ObjectType[];
  datatableRowConfig: DatatableRowConfig<ObjectType>[];
  datatableHeadConfig?: DatatableHeaderConfig[];
  propertyForKey: keyof ObjectType;
  maxHeight?: string;
  height?: string;
  customHoverHeight?: string;
  hoverEffect?: boolean;
  onRowClick?: (object: ObjectType) => void;
  isRowActive?: (object: ObjectType) => boolean;
  fixedLayout?: boolean;
  rowRefs?: any;
  isScroll?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Datatable<ObjectType extends { [key: string]: any }>({
  objects,
  datatableRowConfig,
  datatableHeadConfig,
  propertyForKey,
  maxHeight,
  customHoverHeight,
  height,
  hoverEffect = true,
  onRowClick,
  isRowActive,
  fixedLayout,
  rowRefs,
  isScroll,
}: React.PropsWithChildren<DatatableProps<ObjectType>>) {
  const showBackground = objects.length > 0;

  function renderTableRow(object: ObjectType): JSX.Element {
    const rowRef = rowRefs ? rowRefs.get(object[propertyForKey]) : null;
    return (
      <TableRow
        key={object[propertyForKey]}
        object={object}
        datatableRowConfig={datatableRowConfig}
        hoverEffect={hoverEffect}
        customHoverHeight={customHoverHeight}
        ref={rowRef}
        id={object?.id}
        onRowClick={onRowClick}
        isRowActive={isRowActive}
      />
    );
  }

  return (
    <TableContainer height={height} maxHeight={maxHeight}>
      {isScroll ? (
        <ScrollTableWrapper>
          <Table fixedLayout={fixedLayout}>
            {datatableHeadConfig && <TableHead config={datatableHeadConfig} />}
            <TBody showBackground={showBackground}>
              {objects.map(renderTableRow)}
            </TBody>
          </Table>
        </ScrollTableWrapper>
      ) : (
        <Table fixedLayout={fixedLayout}>
          {datatableHeadConfig && <TableHead config={datatableHeadConfig} />}
          <TBody showBackground={showBackground}>
            {objects.map(renderTableRow)}
          </TBody>
        </Table>
      )}
    </TableContainer>
  );
}
