import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { Form } from '../Form/Form';
import { FormContainer, PageContainer } from './SignUpIn.styled';
import logo from 'assets/images/logo.png';
import { Button } from 'components';
import { AnimatedMap } from '../components/AnimatedMap/AnimatedMap';
import { useActivateAccountQuery } from 'services/auth/useActivateAccount';
import { ERROR_MESSAGE } from "utils/constants";

const SignUpIn: React.FC = () => {
  const { token } = useParams<{ token: string | undefined }>();
  const history = useHistory();
  const { pathname } = useLocation();

  const isChangePassword = pathname.includes('password-change');

  const { isSuccess, isError } = useActivateAccountQuery(
    token,
    pathname.includes('account-activation')
  );

  const feedbackMsg = isSuccess
    ? 'You have activated account successfully!'
    : isError
    ? ERROR_MESSAGE
    : 'Loading...';

  return (
    <PageContainer>
      <FormContainer>
        <div>
          <img src={logo} alt="VAAAM" width="120" style={{ borderRadius: 0 }} />
          {!token || isChangePassword ? (
            <Form />
          ) : (
            <>
              <h2
                style={{
                  color: 'white',
                  margin: '30px 0 ',
                  fontSize: '2.2rem',
                  width: 400,
                }}
              >
                {feedbackMsg}
              </h2>
              <Button variant="LEMON" onClick={() => history.push('/')}>
                Go to Login panel
              </Button>
            </>
          )}
        </div>
      </FormContainer>
      <AnimatedMap />
    </PageContainer>
  );
};

export default SignUpIn;
