import React from 'react';
import { useDropdown } from './useDropdown';
import { Ripple } from 'components/Ripple/Ripple';
import { Icon } from 'components';
import { DropdownButton, DropdownContainer, Label, OptionsContainer, Option } from "./Dropdown.styled";

const getDropdownOptionsPosition = (
  node: React.MutableRefObject<HTMLDivElement | null>
) => {
  if (window.innerHeight / 2 < (node.current?.getBoundingClientRect().y || 0)) {
    return 'top';
  }

  return 'bottom';
};

interface DropdownOption {
  label: React.ReactNode;
  value: string | number;
  disabled?: boolean;
}

export type DropDownConfig = Array<DropdownOption>;

export interface DropdownProps {
  value: string | number | undefined;
  placeholder?: string;
  options: DropDownConfig;
  header?: string;
  footer?: React.ReactNode;
  disabled?: boolean;
  onChange: (value: number | string) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  footer,
  header,
  value,
  options,
  placeholder = 'Select option',
  disabled = false,
  onChange,
}) => {
  const { node, isOpen, toggle, handleChange } = useDropdown(onChange);

  const label = !!value
    ? options.find((option) => option.value === value)?.label
    : placeholder;

  const position = getDropdownOptionsPosition(node);

  return (
    <DropdownContainer ref={node}>
      <DropdownButton onClick={toggle} isOpen={isOpen} disabled={disabled}>
        {!disabled && <Ripple opacity={50} />}
        <Label> {label}</Label>
        <Icon name="arrowDown" color="white" />
      </DropdownButton>
      <OptionsContainer isOpen={isOpen} optionsPosition={position}>
        {!!header && <header>{header}</header>}
        {options.map((option) => (
          <Option
            title={typeof option.label === 'string' ? option.label : undefined}
            isDisabled={!!option.disabled}
            onClick={() => {
              !option.disabled && handleChange(option.value);
            }}
            key={option.value}
          >
            {option.label}
          </Option>
        ))}
        {!!footer && <footer>{footer}</footer>}
      </OptionsContainer>
    </DropdownContainer>
  );
};
