/**
 * @description Component displays button that triggers available overall actions in the app like logout etc.
 */
import React, { useMemo } from 'react';
import { ActionDropdown } from 'components/ActionDropdown/ActionDropdown';
import { useAuth } from 'services/contexts/AuthContext';
// import { useHistory } from "react-router-dom";

export const AppActions: React.FC = () => {
  const { logout } = useAuth();

  // const history = useHistory();
  // const billingURL = 'https://buy.stripe.com/bIY3d0fB6akY86Y144';
  // const onboardURL = 'https://forms.gle/YHJBuENAhmLGCky37';

  const actions = useMemo(
      () => [
        { action: logout, label: 'Logout', disabled: false },
        // {
        //   action: () => history.push('/user-settings'),
        //   label: 'User settings',
        //   disabled: false,
        // },
        // { action: () => {
        //     window.open(billingURL, "_blank")
        //   }, label: `Billing`, disabled: false },
        // { action: () => {
        //     window.open(onboardURL, "_blank")
        //   }, label: 'Onboarding', disabled: false },
        // {
        //   action: () => history.push('/template'),
        //   label: 'Custom Template',
        //   disabled: false,
        // },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [logout]
  );

  return <ActionDropdown hideOnOutsideClick iconName="user" actions={actions} />;
};
