import React from 'react';

import AppShell from 'AppShell';
import { Button } from 'components';
import { Container } from "./AuthErrorPage.styled";


interface AuthErrorPageProps {
  fallbackProps: {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
  };
}

export const AuthErrorPage: React.FC<AuthErrorPageProps> = ({
  fallbackProps: { resetError },
}) => {
  return (
    <div onClick={() => resetError()}>
      <AppShell>
        <Container>
          <h2>We apologize - we've run into an issue.</h2>
          <Button variant="WHITE" onClick={() => resetError()}>
            Try again
          </Button>
          <p>
            If the issue persists, contact us at{' '}
            <a href="mailto:support@vaaam.io">support@vaaam.io</a>
          </p>
        </Container>
      </AppShell>
    </div>
  );
};
