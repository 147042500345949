import React, { useEffect } from 'react';
import { theme } from 'assets/styles/theme';
import { Loader } from 'components';
import { CircleCheckbox } from '../CircleCheckbox/CircleCheckbox';
import {Img, ImgContainer, ImgFooter, ImgHeader} from "./Image.styled";
import {isEmpty} from "lodash";



interface IImage {
  src: string;
  blurImage?: boolean;
  height?: string;
  children?: React.ReactNode;
  alternateText?: string;
  withScaleEffect?: boolean;
  selected?: boolean;
  onChangeSelect?: () => void;
  onImageClick?: () => void;
  metaDataBtn?: React.ReactElement | null;
}

const Image: React.FC<IImage> = ({
  src,
  height = '300px',
  children,
  blurImage = false,
  withScaleEffect = false,
  selected = false,
  onChangeSelect,
  onImageClick,
  metaDataBtn,
}) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showHeader, setIsHovering] = React.useState(false);
  let showFooter = Boolean(children);
  const isHeightSet = height?.endsWith('px');

  if (Array.isArray(children)) {
    showFooter = children.some((el) => !!el);
  }

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (!!src && isLoaded) {
      setIsLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <ImgContainer
      style={{
        cursor: onImageClick ? 'pointer' : '',
      }}
      onClick={onImageClick}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
    >
      {!!onChangeSelect && (
        <CircleCheckbox
          color={theme.colors.lemon}
          size={25}
          onChange={onChangeSelect}
          checked={selected}
          customStyles={{
            position: 'absolute',
            top: 20,
            left: 20,
          }}
        />
      )}
      <Loader
        style={{
          left: '50%',
          position: 'absolute',
          top: '43%',
          transform: 'translate(-50%, -50%)',
        }}
        visible={!isLoaded}
      />
      <Img
        isBlured={blurImage}
        onLoad={() => {
          setIsLoaded(true);
        }}
        onError={() => setIsLoaded(false)}
        withScaleEffect={withScaleEffect}
        loading={isHeightSet ? "lazy": "eager"}
        isLoaded={isLoaded}
        height={height}
        src={src}
        alt="CLIENT PICTURE"
      />
      {showHeader && !isEmpty(metaDataBtn) && <ImgHeader>{metaDataBtn}</ImgHeader>}
      {showFooter && <ImgFooter
          onMouseEnter={handleMouseOut}
          onMouseLeave={handleMouseOver}
      >{children}</ImgFooter>}
    </ImgContainer>
  );
};

export default React.memo(Image);
