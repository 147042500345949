import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

export const LabelText = styled.p<{ margin?: string }>`
  color: ${theme.colors.mediumGrey};
  font-size: 1.4rem;
  margin: ${({ margin }) => margin || ''};
  display: flex;
  flex-direction: column;
  cursor:pointer;
`;

export const Label = styled.label<{ color: string, size: number }>`
   width: ${({ size }) => `${size}px`};
   height: ${({ size }) => `${size}px`};
   border: ${({ color }) => `2px solid ${color}` || ''};
   border-radius: 50%;
   background: ${theme.colors.black};
   z-index: 5;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: end;
  width: max-content;
`;