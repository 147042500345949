import React from 'react';
import { Ripple } from 'components/Ripple/Ripple';
import { styles } from "./const";
import { Label, StyledStateButton } from "./StateButton.styled";

export type StateButtonVariants = 'DEFAULT' | 'COMMERCIAL' | 'REPUTATIONAL' | 'EDITORIAL';

interface StateButtonProps {
  variant?: StateButtonVariants;
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const StateButton: React.FC<StateButtonProps> = ({
  variant = 'DEFAULT',
  disabled,
  isActive,
  children,
  onClick,
}) => {
  const rippleColor = styles[variant].color + '99';
  const opacity = variant === 'DEFAULT' ? 50 : 20;
  return (
    <StyledStateButton
      variant={variant}
      disabled={disabled}
      isActive={isActive}
      onClick={onClick}
    >
      <Label isActive={isActive} variant={variant}>
        {children}
      </Label>
      {!disabled && <Ripple color={rippleColor} opacity={opacity} />}
    </StyledStateButton>
  );
};
