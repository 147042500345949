export const commonConfig = {
    bodyStyle: {
        fontSize: 15,
        padding: 10,
    },
    closeOnClick: true,
}

export const infoConfig = {
    autoClose: 7000,
    ...commonConfig
}

export const errorConfig = {
    autoClose: 5000,
    ...commonConfig
}
