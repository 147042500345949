import styled from "styled-components";

export const InnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
`;

export const OuterContainer = styled.div<{ height: string }>`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: ${({ height }) => height};

`;