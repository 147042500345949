import React, { useEffect } from 'react';
import { theme } from 'assets/styles/theme';

import { getNavigationConfig } from '../navigation-config';
import { UserIcon } from 'assets/icons/NavIcons';
import { useAuth } from 'services/contexts/AuthContext';
import {
  Backdrop,
  MobileNavigationContainer,
  MobileNavigationContainerProps,
  StyledNavLink
} from "./MobileNavigation.styled";
import {useCelebrity} from "services/contexts/CelebrityContext";
import {useDashboard} from "../../../pages/Dashboard/hooks/useDashboard";

interface NavItemProps {
  config: { label: string; path: string; icon: React.ReactNode };
  onLinkClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({
  config: { path, icon, label },
  onLinkClick,
}) => {
  return (
    <StyledNavLink to={path} onClick={onLinkClick}>
      {icon}
      <span> {label}</span>
    </StyledNavLink>
  );
};

interface MobileNavigationProps extends MobileNavigationContainerProps {
  onLinkClick: () => void;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  isOpen,
  onLinkClick,
}) => {
  const { logout } = useAuth();

  useEffect(() => {
    window.document.body.style.overflowY = isOpen ? 'hidden' : 'auto';

    return () => {
      window.document.body.style.overflowY = 'auto';
    };
  }, [isOpen]);


  const { currentCelebrity } = useCelebrity();
  const { user: {role = null, is_monitoring = false} = {} } = useAuth();
  const { slug } = role || {};
  const celebrityId = Number(currentCelebrity?.id) || 0;
  const { isLoading } = useDashboard();
  if (isLoading) {
    return null;
  }

  return (
    <>
      <MobileNavigationContainer isOpen={isOpen}>
        {getNavigationConfig(celebrityId, slug, is_monitoring).map((config) => (
          <NavItem key={config.path} config={config} onLinkClick={onLinkClick} />
        ))}

        <StyledNavLink
          isActive={() => false}
          to="#"
          onClick={() => {
            onLinkClick();
            logout();
          }}
        >
          <UserIcon style={{ stroke: theme.colors.darkenWhite }} />
          <span>LOG OUT</span>
        </StyledNavLink>
      </MobileNavigationContainer>
      <Backdrop isOpen={isOpen} />
    </>
  );
};
