import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import './Carousel.css';

SwiperCore.use([Navigation]);

interface CarouselProps {
  columnsAmount?: number;
}

export const Carousel: React.FC<CarouselProps> = ({ children, columnsAmount = 5 }) => {
  return (
    <div className='custom-carousel-swiper-container-wrapper'>
      <Swiper navigation spaceBetween={20} slidesPerView={columnsAmount}>
        {React.Children.map(children, (child, index) => (
          <SwiperSlide key={`slide-${index}`}>{child}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
