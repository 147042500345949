import { Button, TextField } from 'components';
import { Heading, WhiteBtn } from '../../Form/Form.styled';
import { useChangePasswordMutation } from 'services/auth/useChangePasswordMutation';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCheckTokenQuery } from 'services/auth/useCheckTokenMutation';
import { BackContainer, CreatePasswordText, FormContainer, StyledForm } from "./ChangePassword.styled";


export const ChangePassword: React.FC<{ onGoBackClick: () => void }> = ({
  onGoBackClick,
}) => {
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState<string | undefined>();
  const { token } = useParams<{ token: string }>();
  const { changePassword } = useChangePasswordMutation();

  const { email } = useCheckTokenQuery(token);

  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password.trim().length > 4) {
      setPasswordErr(undefined);
      await changePassword({ password, email, token });
      setPassword('');
      history.push('/');
      onGoBackClick();
    } else {
      setPasswordErr('Please use longer password');
    }
  };

  return (
    <div>
      <Heading>Password Reset</Heading>
      <CreatePasswordText>
        Create new password
      </CreatePasswordText>

      <FormContainer>
        <StyledForm onSubmit={handleSubmit} autoComplete="off" noValidate>
          <TextField
            mt="30px"
            value={password}
            type="password"
            onChange={(e) => {
              if (passwordErr) {
                setPasswordErr(undefined);
              }
              setPassword(e.target.value);
            }}
            id="password"
            name="password"
            placeholder="Password"
            icon="password"
            error={passwordErr}
          />
          <Button mt="20px" block variant="LEMON" type="submit">
            SUBMIT
          </Button>
        </StyledForm>
      </FormContainer>

      <BackContainer>
        <WhiteBtn
          onClick={() => {
            history.push('/');
            onGoBackClick();
          }}
        >
          &lsaquo; Back
        </WhiteBtn>
      </BackContainer>
    </div>
  );
};
