import { theme } from 'assets/styles/theme';
import styled from "styled-components";

/**
 * it's based on enum Classification
 * see Classification in types/index.ts
 */

export const CLASSIFICATION_CONFIG = [
  { color: theme.colors.commercial, label: 'COMMERCIAL' },
  { color: theme.colors.editorial, label: 'EDITORIAL' },
  { color: theme.colors.reputational, label: 'REPUTATIONAL' },
];

interface IallFileTypes {
  PNG: string;
  JPEG: string;
  CSV: string;
  XLSX: string;
  PDF: string;
  DOCX: string;
}

export const allFileTypes: IallFileTypes = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  CSV: '.csv',
  XLSX: '.xlsx',
  PDF: '.pdf',
  DOCX: '.docx',
};

export enum Variant {
  BLACK = "black",
  WHITE = "white",
}

export enum MONITORING_VIEWS {
  CURATED_FINDINGS = "curated_findings",
  PRE_CASES = "pre_cases",
}

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const ERROR_MESSAGE = 'The application encountered an issue. If it persists, get in touch with us.'

export enum View {
  DEFAULT = 'default',
  SOCIAL = 'social',
}

export enum DocsView {
  GENERAL = 'general',
  CASE = 'case',
}

export const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

export const DFlex = styled.div`
  display: flex;
`;

export const getPaginatedPageSize = (screenWidth: number, hasUploadCard: boolean) => {
  /* NOTE: Following is the display:
  <= 767px : 1 image
  >767px && <= 991px : 2 image
  >991px && <= 1199px : 3 image
  >1199px && <= 1539px : 4 image
  >1539px : 6 images image
  All are divisible by 24, so we do not need to change it based on screenWidth atm.
  We will subtract 1 for the upload card to display 24 cards at one time. */
  return hasUploadCard ? 23 : 24;
};

export const documentAllowedTypes = [
  allFileTypes.PNG,
  allFileTypes.JPEG,
  allFileTypes.CSV,
  allFileTypes.XLSX,
  allFileTypes.PDF,
  allFileTypes.DOCX,
];


