import React from 'react';
import { ReactComponent as MapSVG } from 'assets/svgs/InteractiveMap.svg';
import { MapContainer, Quote, RightColumn } from "./AnimatedMap.styled";


export const AnimatedMap: React.FC = () => {
  return (
    <>
      {' '}
      <RightColumn>
        <div style={{ width: '70%' }}>
          <Quote>
            Safeguarding Rights
            in the Digital World
          </Quote>
          <MapContainer>
            <MapSVG />
          </MapContainer>
        </div>
      </RightColumn>
    </>
  );
};
