import React from 'react';
import { Icon } from 'components';
import { ModalIconWrapper } from 'pages/shared/styled/ModalIcon';
import { InnerContainer, OuterContainer } from "./EmptyStateIndicator.styled";
import { ICON_STYLES, ICON_WRAPPER_STYLES } from "./const";
import { useMedia } from "hooks/useMedia";

interface EmptyStateIndicatorProps {
  size?: 'sm' | 'md';
  height?: string;
}

export const EmptyStateIndicator: React.FC<EmptyStateIndicatorProps> = (props) => {
  const isLargeScreen = !!useMedia(['(min-width: 3001px)'], [1], 0);
  const {
    size = 'md',
    height = isLargeScreen ? '500px' : '300px',
  } = props;

  return (
    <OuterContainer height={height}>
      <InnerContainer>
        <ModalIconWrapper style={ICON_WRAPPER_STYLES[size] as React.CSSProperties}>
          <Icon
            name="empty"
            color="#121212"
            style={ICON_STYLES[size] as React.CSSProperties}
          />
        </ModalIconWrapper>
        <p style={{ color: 'rgba(255,255,255,0.6)', textAlign: 'center' }}>
          Currently, no content available
        </p>
      </InnerContainer>
    </OuterContainer>
  );
};
