/**
 * @description list of available api endpoints; they are joined with
 * base url and sometimes with specific parts like query params etc
 */

export const PUBLIC_API = {
  AUTH_CREATE_TOKEN: 'auth/jwt/create',
  AUTH_REFRESH_TOKEN: 'auth/jwt/refresh',
  AUTH_SIGN_UP: 'account/register/',
  ACTIVATE_ACCOUNT: 'account/activate/',
  FORGOT_PASSWORD: 'account/password/reset/',
  CHANGE_PASSWORD: 'account/password/change/',
};

export const PRIVATE_API = {
  CASE: 'case/',
  FREEMIUM_CASE: 'monitoring-cases/',
  CASE_ARCHIVED: 'case/archived/',
  CASE_CONFIRMED: 'case/confirmed/',
  CASE_FINALIZED: 'case/finalized/',
  CASE_ON_HOLD: 'case/on_hold/',
  CASE_TAKE_ACTION: 'case/take_action/',
  DASHBOARD: 'dashboard/',
  FINDINGS: 'findings/',
  MONITORING_FINDINGS: 'monitoring-findings/',
  UNCURATED_FINDINGS: 'uncurated-findings/',
  HIT: 'hit/',
  MAP: 'map/',
  PORTFOLIO: 'portfolio/',
  DOCUMENTS: 'documents/',
  USER_INFO: 'user_info/',
  UPLOAD: 'upload/',
  MONITORING: 'monitoring/',
  SEARCH_TAGS: 'search-tags/',
  PORTFOLIO_SEARCH_TAGS: 'portfolio/search-tags/',
};
