import React from 'react';

import { Icon } from 'components';
import { LabelText, SelectContainer, Label } from './CircleCheckbox.styled';

interface CircleCheckboxProps {
  label?: string;
  color: string;
  size: number;
  checked: boolean | undefined;
  onChange: () => void;
  customStyles?: React.CSSProperties;
}
export const CircleCheckbox: React.FC<CircleCheckboxProps> =
  ({
     label = '', color = '', size = 25,
     checked = false, onChange, customStyles = {}
   }) => {

    return (
      <SelectContainer onClick={(e) => {
        onChange();
        e.stopPropagation();
      }}>
        <Label
          size={size}
          color={color}
          style={customStyles}
          htmlFor=""
        >
          {checked && <Icon name="check" color={color}/>}
          <input defaultChecked={checked} type="checkbox" name="" id="" hidden/>
        </Label>
        {label && <LabelText margin="0 0 15px 0">{label}</LabelText>}
      </SelectContainer>
    );
  };
