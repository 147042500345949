import React from 'react';
import { theme } from 'assets/styles/theme';
import { Ripple } from 'components/Ripple/Ripple';
import { HamburgerButton } from 'components/AppHeader/HamburgerButton2';
import { Backdrop, ButtonWrapper, Toolbar } from "./MobileToolbox.styled";


interface MobileToolboxProps {
  isOpen: boolean;
  onCircleBtnClick: () => void;
}

export const MobileToolbox: React.FC<MobileToolboxProps> = ({
  children,
  isOpen,
  onCircleBtnClick,
}) => {
  return (
    <>
      <Backdrop isOpen={isOpen} />
      <Toolbar isOpen={isOpen}>{children}</Toolbar>
      <ButtonWrapper>
        <Ripple color={theme.colors.darkBoxes} opacity={50} />
        <HamburgerButton handleClick={onCircleBtnClick} isOpen={isOpen} />
      </ButtonWrapper>
    </>
  );
};
