import React from 'react';
import {EditBtn, StyledTag, UnselectBtn} from "./Tag.styled";
import { theme } from "assets/styles/theme";
import {Icon} from "../Icon/Icon";
import {isEmpty} from "lodash";
import {ITag} from "../../types";

export enum TagAction {
    DEFAULT = 'cross',
    EDIT = 'edit',
    DELETE = 'Delete'
}

interface TagProps {
  actions?: TagAction[];
  onClickActions? : ((tag?: ITag) => void)[];
}

export const Tag: React.FC<TagProps> = ({ children, actions = [TagAction.DEFAULT], onClickActions }) => {

  const renderActionIcons = () => {
        if (isEmpty(actions)) {
            return null
        }
        return actions.map((action, index) => {
            let element = null;
            if (!onClickActions) {
                return null;
            }
            switch (action) {
                case TagAction.EDIT:
                    element = <EditBtn key={index} onClick={() => onClickActions[index]()}>
                        <Icon name="edit" color={theme.colors.darkenWhite}/>
                    </EditBtn>;
                    break;
                case TagAction.DELETE:
                    element = <EditBtn  key={index} onClick={() => onClickActions[index]()}>
                        <Icon name="trash" color={theme.colors.darkenWhite}/>
                    </EditBtn>;
                    break;
                case TagAction.DEFAULT:
                    element = <UnselectBtn data-testid="remove-tag-btn" color={theme.colors.lightgrey}
                                           key={index}
                                           onClick={() => onClickActions[index]()}>
                        &times;
                    </UnselectBtn>
                    break;
                default:
                    element = null;
                    break;
            }
            return element
        })
    }

  return (
    <StyledTag
      data-testid="tag"
      style={{
        background: theme.colors.grey,
        color: theme.colors.lightgrey,
      }}
    >
      <span className="tag">{children}</span>
        {!isEmpty(actions) && renderActionIcons()}
    </StyledTag>
  );
};
