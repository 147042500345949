import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const Aside = styled.aside`
  display: none;
  position: fixed;
  top: 73px;
  bottom: 0;
  left: 0;
  height: 100vh;
  height: 100%;
  height: auto;
  overflow: auto;
  width: 100px;
  background-color: ${theme.colors.darkBoxes};

  @media screen and (min-width: 1200px) {
    display: block;
  }

  @media screen and (min-width: 3000px) {
    width: 200px;
    top: 100px;
  }
`;
