import React from 'react';
import { DesktopNavigation } from 'components/AppNavigation/DesktopNavigation/DesktopNavigation';
import { Aside } from "./AppSidebar.styled";

const Sidebar: React.FC = () => {
  return (
    <Aside>
      <DesktopNavigation />
    </Aside>
  );
};

export default Sidebar;
