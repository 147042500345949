import { useState, useEffect, useRef } from 'react';

export const useDropdown = (onChange: (value: number | string) => void) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef<HTMLDivElement | null>(null);

  const toggle = () => setIsOpen((prev) => !prev);

  const handleChange = (value: number | string) => {
    toggle();
    onChange(value);
  };

  const handleClick = (e: MouseEvent) => {
    if (!node?.current?.contains(e?.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return {
    handleChange,
    toggle,
    isOpen,
    node,
  };
};
