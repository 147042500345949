import styled from "styled-components";
import {theme} from "assets/styles/theme";

export const ImgContainer = styled.div`
  background-color: ${theme.colors.backgroundBlack};
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`;

export const Img = styled.img<{
    isLoaded: boolean;
    height?: string;
    withScaleEffect?: boolean;
    isBlured?: boolean;
}>`
  object-fit: cover;
  object-position: top;
  width: 100%;
  transition: all 0.15s, transform 0.5s;
  height: ${({ height = '100%' }) => height};

  visibility: ${({ isLoaded }) => (isLoaded ? 'unset' : 'hidden')};
  filter: ${({ isBlured }) => (isBlured ? 'blur(13px)' : 'blur(0)')};
  display: block;

  @media screen and (min-width: 3000px) {
    min-height: 500px;
  }

  &:hover {
    transform: ${({ withScaleEffect }) => (withScaleEffect ? 'scale(1.2)' : '')};
  }
`;

export const ImgFooter = styled.div`
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: ${theme.colors.darkBoxes + 'cc'};
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px;
  transform: none !important;
`;

export const ImgHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0 !important;
  background-color: ${theme.colors.darkBoxes + 'cc'};
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px;
  transform: none !important;
`;