import styled from "styled-components";
import {space, Space} from "style/spacing";
import {theme} from "assets/styles/theme";

export const SwitchContainer = styled.span<Space>`
  display: flex;
  align-items: center;
  ${space}
`;

export const Label = styled.label<{ isDisabled: boolean; isChecked: boolean }>`
  margin-right: 25px;
  font-weight: 600;
  font-size: 1.4rem;
  color: ${theme.colors.mediumGrey};
  transition: all 0.15s;
  cursor: ${({isDisabled}) => (isDisabled ? 'default' : 'pointer')};
  color: ${({isChecked}) =>
          isChecked ? theme.colors.darkenWhite : theme.colors.mediumGrey};

  span:hover > & {
    color: ${({isDisabled}) => (isDisabled ? theme.colors.mediumGrey : theme.colors.white)};
  }
`;

export const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 16px;
  transform: translateY(3px);

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.black};

    -webkit-transition: transform 0.4s, background-color 0.15s;
    transition: transform 0.4s, background-color 0.15s;
    border-radius: 60px;
  }

  input:enabled + .slider {
    cursor: pointer;
  }

  .slider:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 27px;
    width: 27px;
    left: 4px;
    top: -40%;
    background-color: #888;
    -webkit-transition: transform 0.4s, background-color 0.2s;
    transition: transform 0.4s, background-color 0.2s;
    -webkit-transform: translateX(-12px);
    -ms-transform: translateX(-12px);
    transform: translateX(-12px);
  }

  input:checked + .slider:before {
    background-color: ${theme.colors.darkenWhite};
  }

  input:enabled:focus:checked + .slider:before {
    background-color: ${theme.colors.darkenWhite};
  }

  span:hover > & > input:enabled + .slider:before {
    background-color: ${theme.colors.white};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .label {
    color: white;
    position: absolute;
    transform: translate(-45px, -30%);
  }
`;